import React, { useRef } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useSearchParams } from "react-router-dom";
import { Input } from "semantic-ui-react";

import "./Map.css";
import "@asseinfo/react-kanban/dist/styles.css";

import { AuthenticationContext } from "./AuthenticationContext";
import Edge from "./Edge";
import { GameContext } from "./GameContext";
import Location from "./Location";
import Locations from "./Locations";
import Marker from "./Marker";
import { api, handleData, handleResponse, invalidate, request } from "./api";
import { colors } from "./colors";
import { q } from "./Query";


export default function GeoMap({ locations, setLocations, connections, setConnections, allowDrag, colorizeBy }) {

  const game = React.useContext(GameContext);
  const [search, setSearch] = useSearchParams();
  const defaultLocation = search.has("location") ? { location_id: search.get("location"), name: null } :
    null;

  const handleError = useErrorHandler();
  const [dragging, setDragging] = React.useState(false)
  const [init, setMapInitialized] = React.useState(false);
  const [location, setLocation] = React.useState(defaultLocation);
  const [selectedLocation, setSelectedLocation] = React.useState(null);

  const map = useRef();
  const markerLayer = useRef();
  const connectionLayer = useRef();
  const mapElement = useRef();
  const searchElement = React.createRef();
  const [auth] = React.useContext(AuthenticationContext);

  React.useEffect(() => {
    if (location) search.set("location", location.location_id);
    else search.delete("location");
    setSearch(search);
  }, [location, search, setSearch]);

  const setCoordinates = (location, coords) => {
    if (!auth.token) return
    api(auth.token, 'puzzles')
      .from("locations")
      .update({ longitude: coords.x, latitude: coords.y })
      .match({ location_id: location.location_id })
      .single()
      .then(handleResponse(handleError, () => {
        invalidate(setLocations);
        invalidate(setConnections)
      }), handleError);
  }

  const onDestroyLocation = (location) => {
    if (!auth.token) return
    api(auth.token, 'puzzles')
      .from("locations")
      .delete()
      .match({ location_id: location.location_id })
      .single()
      .then(handleResponse(handleError, () => {
        invalidate(setLocations);
        invalidate(setConnections)
      }), handleError);
  }

  const createMap = function () {
    const smap = (window).SMap;
    const defaultCenter = smap.Coords.fromWGS84(14.4179, 50.12655);

    let defaultZoom = 7;

    const m = new smap(mapElement.current, defaultCenter, defaultZoom);
    // m.setZoomRange(7, 10)
    map.current = m;

    // Map controls
    {
      m.addDefaultLayer(smap.DEF_OPHOTO);
      m.addDefaultLayer(smap.DEF_TURIST);
      m.addDefaultLayer(smap.DEF_BASE)

      let layerSwitch = new smap.Control.Layer();
      layerSwitch.addDefaultLayer(smap.DEF_BASE);
      layerSwitch.addDefaultLayer(smap.DEF_OPHOTO);
      layerSwitch.addDefaultLayer(smap.DEF_TURIST);
      m.addControl(layerSwitch);

      var sync = new smap.Control.Sync({ bottomSpace: 0 });
      m.addControl(sync);
    }

    // Pretty arrows for connections
    {
      const arrowDef = () => {
        const NAMESPACE = "http://www.w3.org/2000/svg"
        let arrowhead = document.createElementNS(NAMESPACE, "marker")
        arrowhead.setAttribute('id', 'arrowhead')
        arrowhead.setAttribute('viewBox', "0 0 4 4")
        arrowhead.setAttribute('markerWidth', 4)
        arrowhead.setAttribute('markerHeight', 4)
        arrowhead.setAttribute('orient', 'auto')
        arrowhead.setAttribute('refY', 1)
        arrowhead.setAttribute('refX', 3)
        arrowhead.setAttribute('markerUnits', "strokeWidth")

        let arrowPath = document.createElementNS(NAMESPACE, "path")
        arrowPath.setAttribute('d', 'M0,0 L2,1 0,2')
        arrowhead.appendChild(arrowPath)

        let arrowmid = document.createElementNS(NAMESPACE, "marker")
        arrowmid.setAttribute('id', 'arrowmid')
        arrowmid.setAttribute('viewBox', "0 0 4 4")
        arrowmid.setAttribute('markerWidth', 4)
        arrowmid.setAttribute('markerHeight', 4)
        arrowmid.setAttribute('orient', 'auto')
        arrowmid.setAttribute('refY', 1)
        arrowmid.setAttribute('refX', 3)
        arrowmid.setAttribute('markerUnits', "strokeWidth")

        let arrowmidPath = document.createElementNS(NAMESPACE, "path")
        arrowmidPath.setAttribute('d', 'M0,0 L2,1 0,2')
        arrowmid.appendChild(arrowmidPath)

        let defs = document.createElementNS(NAMESPACE, "defs")
        defs.appendChild(arrowhead)
        defs.appendChild(arrowmid)
        return defs
      }

      const svg = m.getGeometryCanvas().canvas
      svg.insertBefore(arrowDef(), svg.firstChild)
    }


    markerLayer.current = new smap.Layer.Marker();
    m.addLayer(markerLayer.current).enable()

    connectionLayer.current = new smap.Layer.Geometry();
    m.addLayer(connectionLayer.current)

    m.getSignals().addListener(this, "map-click", (e) => {
      var coords = smap.Coords.fromEvent(e.data.event, m);
      if (m.location && (!m.location.latitude || !m.location.longitude))
        setCoordinates(m.location, coords)
    })
    m.getSignals().addListener(this, "marker-click", (event) => {
      setSelectedLocation(event.target.location);
      setLocation(event.target.location)
    })

    // Dragging marker on map
    m.getContainer().addEventListener('mouseup', (event) => {
      m.setCursor(null)
      if (!m.dragging) return;
      const coords = smap.Coords.fromEvent(event, m);
      const dragged = m.dragging
      if (dragged && (!dragged.latitude || !dragged.longitude)) {
        setCoordinates(dragged, coords)
        if (m.location) {
          setLocation({ ...m.location, longitude: coords.x, latitude: coords.y })
        }
      }
      setDragging(null);
    })
    m.getContainer().addEventListener('mouseenter', () => {
      if (!m.dragging) return;
      m.setCursor("help")
    })

    // Marker dragging 
    {
      const start = (e) => {
        var node = e.target.getContainer();
        node[smap.LAYER_MARKER].style.cursor = "grab";
      }
      const stop = (e) => {
        var node = e.target.getContainer();
        node[smap.LAYER_MARKER].style.cursor = "";
        setCoordinates(e.target.location, e.target.getCoords());
      }
      m.getSignals().addListener(window, "marker-drag-start", start)
      m.getSignals().addListener(window, "marker-drag-stop", stop)
    }

    m.addDefaultControls();
    setMapInitialized(true)
  };

  React.useEffect(() => {
    if (!mapElement) return;
    if (!auth) return;
    (window).Loader.async = true;
    (window).Loader.load(null, { suggest: true }, createMap);
  }, [mapElement, auth]);

  // Load locations
  React.useEffect(() => {
    if (!game) return;
    if (!locations.didInvalidate) return;
    if (locations.isFetching) return;
    api(auth?.token, 'puzzles')
      .from("locations")
      .select(
        q(["location_id", "name", "latitude", "longitude",
          {
            "photos": ['photo_id'],
            "interpretations": ['interpretation_id', 'from', 'to',
              {
                "interpretation_solutions": {
                  "solutions": ['solution_id', 'code',
                    {
                      "puzzle_solutions": {
                        "puzzles": ['puzzle_id', 'position', 'tag', 'name', 'slug', {
                          "...puzzle_checksums": ['problem_checksum']
                        }]
                      }
                    }
                  ]
                }
              }],
            "location_papers!location_papers_location_id_fkey": ['location_paper_id',
              {
                "connections": ['connection_id',
                  {
                    "interpretations": ['interpretation_id', 'from', 'to',
                      {
                        "interpretation_solutions": {
                          "solutions": ['solution_id', 'code', 'solution',
                            {
                              "puzzle_solutions": {
                                "puzzles": ['puzzle_id', 'position', 'tag', 'name', 'slug',
                                  { "...puzzle_checksums": ['problem_checksum'] }]
                              }
                            }]
                        }
                      }]
                  }],
                "papers": ["paper_id", "code", "final", "game_id",
                  {
                    "paper_puzzles": ["paper_puzzle_id",
                      {
                        "puzzles": ['puzzle_id', 'position', 'tag', 'name', 'slug',
                          {
                            "...puzzle_checksums": ['problem_checksum'],
                            "puzzle_stages": {
                              "stages": ['position']
                            }
                          }]
                      }]
                  }]
              }]

          }
        ])
      )
      .eq("game_id", game.game_id)
      .then(
        handleData(handleError, setLocations, (locations) => locations
          .sort((a, b) => {
            const section = (l) => Math.min(...l.location_papers
              .flatMap(lp => lp.papers.paper_puzzles.map(pp => pp.puzzles.puzzle_stages.map(ps => ps.stages.position)))
              .filter(p => !!p))
            const puzzle = (l) => Math.min(...l.location_papers
              .flatMap(lp => lp.papers.paper_puzzles
                .filter(pp => {
                  const sec = section(l)
                  return !sec || sec === Math.min(...pp.puzzles.puzzle_stages.map(ps => ps.stages.position))
                })
                .map(pp => pp.puzzles.position))
              .filter(p => !!p))
            return Math.sign(section(a) - section(b)) || Math.sign(puzzle(a) - puzzle(b))
          })),
        handleError
      );
  }, [auth, game, locations]);

  // Load locations
  React.useEffect(() => {
    invalidate(setLocations)
    invalidate(setConnections)
  }, [game]);

  // Load connections
  React.useEffect(() => {
    if (!game) return;
    if (!connections.didInvalidate) return;
    if (connections.isFetching) return;
    request(setConnections)
    api(auth?.token, 'puzzles')
      .from("connections")
      .select(
        q(["connection_id",
          {
            "location_papers!inner": {
              "papers!inner": ["game_id"],
              "locations!location_papers_location_id_fkey!inner": ["location_id", "name", "latitude", "longitude"]
            },
            "interpretations!inner": {
              "locations": ["location_id", "name", "latitude", "longitude"],
              "interpretation_solutions!inner": {
                "solutions!inner": {
                  "puzzle_solutions!inner": {
                    "puzzles!inner": ["puzzle_id", {
                      "paper_puzzles!inner": ["paper_puzzle_id", "part", {
                        "papers!inner": {
                          "location_papers!inner": {
                            "locations!location_papers_location_id_fkey!inner": ["location_id", "name", "latitude", "longitude"]
                          }
                        }
                      }]
                    }]
                  }
                }
              }
            }
          }])
      )
      .filter('location_papers.locations.latitude', 'not.is', 'null')
      .filter('location_papers.locations.longitude', 'not.is', 'null')
      .filter('interpretations.locations.latitude', 'not.is', 'null')
      .filter('interpretations.locations.longitude', 'not.is', 'null')
      .filter('interpretations.interpretation_solutions.solutions.puzzle_solutions.puzzles.paper_puzzles.papers.location_papers.locations.latitude', 'not.is', 'null')
      .eq("location_papers.papers.game_id", game.game_id)
      .then(handleData(handleError, setConnections), handleError);
  }, [game, auth, connections]);

  // Center map on initial load
  React.useEffect(() => {
    const smap = (window).SMap;
    const m = map.current;

    if (!init) return;
    if (!m) return;
    if (locations.didInvalidate || locations.isFetching || !locations.data) return;
    if (m.getZoom() > 8) return;

    const coords = locations.data.filter(l => l.latitude && l.longitude).map(location => {
      return smap.Coords.fromWGS84(location.longitude, location.latitude);
    });
    let cz = map.current.computeCenterZoom(coords);
    m.setCenterZoom(...cz);

    m.getLayer(smap.DEF_BASE).enable()
    connectionLayer.current.enable()
  }, [init, locations, connections]);

  // Search bar
  React.useEffect(() => {
    const smap = (window).SMap;
    const m = map.current;

    if (!init) return;
    if (!m) return;
    if (locations.didInvalidate || locations.isFetching || !locations.data) return;
    if (!searchElement.current) return;

    const suggest = new smap.Suggest(searchElement.current.inputRef.current);
    const coords = locations.data.filter(l => l.latitude && l.longitude).map(location => {
      return smap.Coords.fromWGS84(location.longitude, location.latitude);

    });
    const [center] = map.current.computeCenterZoom(coords);
    const bounds = center ? [
      [center.y - 0.05, center.x - 0.02],
      [center.y + 0.05, center.x + 0.02]
    ] : [
      [48.5370786, 12.0921668],
      [51.0746358, 18.8927040]
    ]
    suggest.urlParams({
      bounds: bounds.map(bs => bs.join(",")).join("|")
    });
    suggest.addListener("suggest", (suggestData) => {
      const coords = smap.Coords.fromWGS84(suggestData.data.longitude, suggestData.data.latitude)
      map.current.setCenterZoom(coords, 17)
    });
  }, [init, locations, searchElement]);


  // Set locations to the map, so that it can be used in map events
  React.useEffect(() => {
    const m = map.current;
    if (!m) return;
    m.location = location
  }, [location]);

  // Center map on location on click
  React.useEffect(() => {
    const smap = (window).SMap;
    const m = map.current;
    if (!m) return;
    if (location?.latitude && location.longitude) {
      const coords = smap.Coords.fromWGS84(location.longitude, location.latitude)
      m.setCenter(coords, true)
    }
  }, [location]);

  // Center map on location on click
  React.useEffect(() => {
    const smap = (window).SMap;
    const m = map.current;
    if (!m) return;
    if (selectedLocation?.latitude && selectedLocation.longitude) {
      const coords = smap.Coords.fromWGS84(selectedLocation.longitude, selectedLocation.latitude)
      m.setCenter(coords, true)
    }
  }, [selectedLocation]);

  React.useEffect(() => {
    const m = map.current;
    if (!m) return;
    m.dragging = dragging
  }, [dragging]);


  const colorize = (location) => {
    if (!(location.latitude && location.longitude)) {
      return 'red'
    }

    switch (colorizeBy) {
      case 'photo':
        const hasPhoto = location.location_papers
          .flatMap(lp => lp.location_paper_photos)
          .length > 0
        return hasPhoto ? 'green' : 'red'
      default:
        const section = Math.min(...location.location_papers
          .flatMap(lp => lp.papers.paper_puzzles.map(pp => pp.puzzles.puzzle_stages.map(ps => ps.stages.position)))
          .filter(p => !!p)) || Number.MAX_SAFE_INTEGER
        return colors[section % colors.length]
    }
  }


  return (
    <div className="container">
      <div id="map" ref={mapElement} >
        <Input ref={searchElement} className="suggest" placeholder='Search...' />
        {locations.data?.filter(l => l.latitude && l.longitude).map((l) => {
          return <Marker
            key={l.location_id}
            layer={markerLayer}
            init={init}
            location={l}
            draggable={allowDrag}
            colorize={colorize}
            active={selectedLocation && l.location_id === selectedLocation.location_id} />;
        })}
        {connections.data?.map((c) => {
          return <Edge
            connection={c}
            init={init}
            key={c.connection_id}
            layer={connectionLayer}
          />;
        })}
      </div>
      {location
        ? <Location
          location={location}
          setLocation={setLocation}
          setCoordinates={setCoordinates}
          setDragging={setDragging}
          onDestroy={onDestroyLocation}
        />
        : <Locations
          locations={locations}
          setLocations={setLocations}
          setLocation={setLocation}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          colorize={colorize}
          setDragging={setDragging}
        />}
    </div>
  )
}
