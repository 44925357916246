import React from "react";
import { Card, Image, Icon, Label } from "semantic-ui-react";

import TagGroup from "./TagGroup";
import { GameContext } from "./GameContext";

import "./PuzzleCard.css";
import { PuzzleTag } from "./interface";
export default function PuzzleCard({
  puzzle,
  color,
  onClick,
  showPreview,
  showTags,
  setShowTags,
}) {
  const game = React.useContext(GameContext);
  const user =
    puzzle.puzzle_users &&
    puzzle.puzzle_users.length > 0 &&
    puzzle.puzzle_users[0].users;
  return (
    <Card puzzle={puzzle} onClick={onClick} color={color}>
      {showPreview && (
        <Image
          src={`https://statek.seslost.cz/${game.name}/sifry/${puzzle.slug}.png?style=preview`}
          wrapped
          ui={false}
        />
      )}

      <Card.Content>
        {user && (
          <Label size="mini" ribbon={"right"}>
            <Icon name="user" /> {user.alias || user.first_name}
          </Label>
        )}
        <Card.Header>
          {puzzle.position}
          {puzzle.tag} {puzzle.position || puzzle.tag ? " – " : ""}{" "}
          {puzzle.name}
        </Card.Header>
      </Card.Content>
      <Card.Content extra>
        <div className="icons">
          {puzzle.description && <Icon name="align justify" />}
          {puzzle.problem_checksum && <Icon name="attach" />}
        </div>
        <TagGroup
          taggings={
            puzzle.puzzle_tags &&
            puzzle.puzzle_tags.map((t: PuzzleTag) => {
              return { id: t.puzzle_tag_id, ...t };
            })
          }
          expanded={showTags}
          setExpanded={setShowTags}
        />
      </Card.Content>
    </Card>
  );
}
