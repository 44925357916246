import DataGridCollectionColumn from "../..//DataGridCollectionColumn";
import { Solution, User } from "../../interface";

export const UserColumn = (
  <DataGridCollectionColumn
    name="solution_users"
    title="Autor"
    type="collection"
    frozen={true}
    width={1}
    select={{
      puzzle_solutions: {
        puzzles: {
          puzzle_users: [
            "user_id",
            {
              users: ["user_id", "alias", "first_name"]
            }
          ]
        }
      }
    }}
    compare={(a: User, b: User) => a.first_name.localeCompare(b.first_name)}
    join={arr => arr.sort().join(", ")}
    renderItem={([, user]) => user.alias || user.first_name}
    getItems={(solution: Solution) =>
      (solution.puzzle_solutions || [])
        .map(({ puzzles }) => puzzles)
        .map(({ puzzle_users }) => puzzle_users)
        .flat()
        .map(({ users }) => [users.user_id, users])
    }
  />
);
