import { memo } from "react";

import { Handle, Position } from "reactflow";

const LocationPaperNode = ({
  data,
  isConnectable,
  targetPosition = Position.Top,
  sourcePosition = Position.Bottom,
}) => {
  return (
    <>
      <Handle
        type="target"
        position={targetPosition}
        isConnectable={isConnectable}
      />
      {data?.label}
      {Array.from(
        new Set(
          data.locationPaper?.papers?.paper_puzzles
            .flatMap(({ puzzles }) => puzzles)
            .flatMap(({ puzzle_solutions }) => puzzle_solutions)
            .flatMap(({ solutions }) => solutions.interpretation_solutions)
            .map(({ interpretation_id }) => interpretation_id)
        )
      ).map((interpretation_id: string, i) => (
        <Handle
          type="source"
          position={sourcePosition}
          id={interpretation_id}
          key={interpretation_id}
          style={sourcePosition == Position.Bottom ? { left: `${50 - 5 * i}%` } : { top: `${50 - 5 * i}%` }}
        />
      ))}

      {data.locationPaper?.papers?.paper_puzzles
        .flatMap(({ puzzles }) => puzzles)
        .flatMap(({ puzzle_solutions }) => puzzle_solutions)
        .flatMap(({ solutions }) => solutions.absolute_hint_solutions)
        .map(({ absolute_hint_id }, i) => (
          <Handle
            type="source"
            position={sourcePosition}
            id={absolute_hint_id}
            key={absolute_hint_id}
            className="absolute-hint-handle"
            style={sourcePosition == Position.Bottom ? { left: `${60 - 5 * i}%` } : { top: `${60 - 5 * i}%` }}
          />
        ))}
    </>
  );
};

// DefaultNode.displayName = "DefaultNode";

export default memo(LocationPaperNode);
