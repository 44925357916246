import DataGridCollectionColumn from "../..//DataGridCollectionColumn";
import { Solution, Connection } from "../../interface";

export const SolutionColumn = (
  <DataGridCollectionColumn
    name="solutions"
    title="Řešení"
    type="collection"
    frozen={true}
    width={2}
    select={{
      interpretations: {
        interpretation_solutions: {
          solutions: ["solution_id", "code", "solution"]
        }
      }
    }}
    compare={(a: Solution, b: Solution) =>
      a.code === null ? -1 : a.code.localeCompare(b.code)
    }
    renderItem={([, solution]) => solution.solution}
    getItems={(connection: Connection) =>
      connection.interpretations &&
      connection.interpretations.interpretation_solutions.map(
        ({ solutions }) => [solutions.solution_id, solutions]
      )
    }
  />
);
