import React from "react";
import { useErrorHandler } from "react-error-boundary";

import { api, handleData, initialState } from "./api";
import { AuthenticationContext } from "./AuthenticationContext";
import { GameContext } from "./GameContext";
import PuzzleCardPanel from "./PuzzleCardPanel";
import { q } from "./Query";

export default function PuzzleTagBoard() {
  const [puzzles, setPuzzles] = React.useState(initialState());
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState(null);

  const handleError = useErrorHandler();
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);

  const onPuzzleChange = () => {
    setLastUpdatedAt(new Date());
  };

  // Load puzzles
  React.useEffect(() => {
    if (!game) return;
    api(auth.token, "puzzles")
      .from("puzzles")
      .select(
        q([
          "puzzle_id",
          "code",
          "position",
          "tag",
          "name",
          "slug",
          { "...puzzle_checksums": ["problem_checksum"] },
          "description",
          {
            puzzle_tags: ["puzzle_tag_id", { tags: ["tag_id", "name"] }],
          },
        ])
      )
      .order("position")
      .order("tag")
      .eq("game_id", game.game_id)
      .eq("internal", false)
      .then(handleData(handleError, setPuzzles), handleError);
  }, [game, auth, lastUpdatedAt, handleError]);

  return <PuzzleCardPanel puzzles={puzzles} setPuzzles={setPuzzles} onPuzzleChange={onPuzzleChange} />;
}
