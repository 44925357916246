import { Table } from "semantic-ui-react";

export default function MyTable({
  id,
  game,
  columns,
  rows,
  sortColumn,
  handleSort,
  onRowClick = null,
}) {
  return (
    <Table striped sortable selectable compact="very">
      <Table.Header>
        <Table.Row>
          {columns.map(({ name, title }) => (
            <Table.HeaderCell
              key={[id, name].join("-")}
              sorted={
                name === sortColumn.columnKey ? sortColumn.direction : null
              }
              onClick={() => handleSort(name)}
            >
              {title}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rows.map((row : any, i: number) => (
          <Table.Row key={["row", i].join("-")} onClick={() => onRowClick(row)}>

          {columns.map(({ name, width, render }) => (
              <Table.Cell key={[row.id, name].join("-")} width={width || 1}>
                {render(row, game)}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
