import DataGridCollectionColumn from "../..//DataGridCollectionColumn";

interface Puzzle {
  position: number;
  tag: string;
  puzzle_stages: any[];
}
interface Stage {
  position: number;
  tag: string;
}

export const PuzzleStageColumn = (
  <DataGridCollectionColumn
    name="puzzle_stages"
    title="Část hry"
    type="collection"
    frozen={true}
    width={2}
    select={{
      puzzle_stages: [
        "stage_id",
        {
          stages: ["stage_id", "position", "name", "title"]
        }
      ]
    }}
    compare={(a: Stage, b: Stage) => a.position - b.position}
    renderItem={([, stage]) => stage.title}
    getItems={(puzzle: Puzzle) =>
      puzzle.puzzle_stages
        ? puzzle.puzzle_stages.map(({ stages }) => [stages.stage_id, stages])
        : []
    }
  />
);
