import React from "react";
import { useErrorHandler } from "react-error-boundary";

import "@asseinfo/react-kanban/dist/styles.css";

import { api, handleResponse } from "./api";
import SolutionBoard from "./SolutionBoard";
import { AuthenticationContext } from "./AuthenticationContext";
import { GameContext } from "./GameContext";
import { q } from "./Query";

export default function SolutionTagBoard() {
  const [tags, setTags] = React.useState([]);
  const [solutions, setSolutions] = React.useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState(null);

  const handleError = useErrorHandler();
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);

  // Load tags
  React.useEffect(() => {
    if (!game) return;
    api(auth.token, "puzzles")
      .from("tags")
      .select(
        q([
          "tag_id",
          "name",
          {
            solution_tags: [
              "solution_tag_id",
              "solution_id",
              {
                "solutions!inner": [
                  "solution_id",
                  "code",
                  "solution",
                  "instructions",
                  {
                    puzzle_solutions: [
                      "puzzle_solution_id",
                      {
                        puzzles: [
                          "puzzle_id",
                          "position",
                          "tag",
                          "name",
                          "slug",
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ]),
      )
      .order("code", { foreignTable: "solution_tags.solutions" })
      .eq("solution_tags.solutions.game_id", game.game_id)
      .eq("type", "Solution")
      .then(
        handleResponse(handleError, (data) => {
          setTags(
            data
              .sort((a, b) => b.solution_tags.length - a.solution_tags.length)
              .map((tag) => {
                return {
                  id: tag.tag_id,
                  title: tag.name,
                  solutions: tag.solution_tags.map((st) => {
                    return {
                      id: st.solution_tag_id,
                      ...st.solutions,
                    };
                  }),
                };
              }),
          );
        }),
        handleError,
      );
  }, [game, lastUpdatedAt]);

  const onSolutionChange = () => {
    setLastUpdatedAt(new Date());
  };

  // Load unassigned solutions
  React.useEffect(() => {
    if (!game) return;
    // FIXME do the filtering no server side
    api(auth.token, "puzzles")
      .from("solutions")
      .select(
        `
          solution_id,code,solution,instructions,
              solution_tags(solution_tag_id,tags(tag_id,name)),
              puzzle_solutions(puzzle_solution_id,
                puzzles(puzzle_id,position,tag,name,slug))
              `,
      )
      .order("code")
      .eq("game_id", game.game_id)
      .then(
        handleResponse(handleError, (data) =>
          setSolutions(
            data
              .filter((p) => p.solution_tags.length === 0)
              .sort(
                (a, b) =>
                  Math.max(
                    ...a.puzzle_solutions.map((ps) => ps.puzzles.position),
                  ) -
                  Math.max(
                    ...b.puzzle_solutions.map((ps) => ps.puzzles.position),
                  ),
              )
              .map((s) => {
                return { id: s.solution_id, ...s };
              }),
          ),
        ),
        handleError,
      );
  }, [game, lastUpdatedAt]);

  const onAddAssociation = ({ id, solution_id, association_id }) => {
    api(auth.token, "puzzles")
      .from("solution_tags")
      .insert({
        game_id: game.game_id,
        solution_tag_id: id,
        tag_id: association_id,
        solution_id: solution_id,
      })
      .then(handleResponse(handleError), handleError);
  };

  const onChangeAssociation = ({ solution_id, from_id, to_id }) => {
    api(auth.token, "puzzles")
      .from("solution_tags")
      .update({ tag_id: to_id })
      .match({ solution_id: solution_id, tag_id: from_id })
      .then(handleResponse(handleError), handleError);
  };

  const onRemoveAssociation = ({ solution_id, association_id }) => {
    api(auth.token, "puzzles")
      .from("solution_tags")
      .delete()
      .match({
        solution_id: solution_id,
        tag_id: association_id,
      })
      .then(handleResponse(handleError), handleError);
  };

  return (
    <SolutionBoard
      solutions={tags}
      unassignedSolutions={solutions}
      setSolutions={setTags}
      setUnassignedSolutions={setSolutions}
      onSolutionChange={onSolutionChange}
      onAddAssociation={onAddAssociation}
      onChangeAssociation={onChangeAssociation}
      onRemoveAssociation={onRemoveAssociation}
    />
  );
}
