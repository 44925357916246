import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { v4 as uuidv4 } from "uuid";

import "@asseinfo/react-kanban/dist/styles.css";

import { api, handleResponse } from "./api";
import PuzzleBoard from "./PuzzleBoard";
import { AuthenticationContext } from "./AuthenticationContext";
import { GameContext } from "./GameContext";

export default function PuzzleTagBoard() {
  const [tags, setTags] = React.useState([]);
  const [puzzles, setPuzzles] = React.useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState(null);

  const handleError = useErrorHandler();
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);

  // Load tags
  React.useEffect(() => {
    if (!game) return;
    api(auth.token, "puzzles")
      .from("tags")
      .select(
        `tag_id,tag_id,name,
            puzzle_tags(puzzle_tag_id,puzzle_id,
              puzzles!inner(puzzle_id,position,slug,tag,name,description,
                ...puzzle_checksums(problem_checksum),
                puzzle_users(puzzle_user_id,
                  users(user_id,first_name,alias)),
                puzzle_tags(puzzle_tag_id,
                  tags(tag_id,name))))`
      )
      .order("position", { foreignTable: "puzzle_tags.puzzles" })
      .order("tag", { foreignTable: "puzzle_tags.puzzles" })
      .eq("puzzle_tags.puzzles.game_id", game.game_id)
      .eq("type", "Puzzle")
      .then(
        handleResponse(handleError, data => {
          setTags(
            data
              .sort((a, b) => b.puzzle_tags.length - a.puzzle_tags.length)
              .map(tag => {
                return {
                  id: tag.tag_id,
                  title: tag.name,
                  puzzles: tag.puzzle_tags.map(pu => {
                    return {
                      id: pu.puzzle_tag_id,
                      ...pu.puzzles
                    };
                  })
                };
              })
          );
        }),
        handleError
      );
  }, [game, lastUpdatedAt]);

  const onPuzzleChange = () => {
    setLastUpdatedAt(new Date());
  };

  // Load unassigned puzzles
  React.useEffect(() => {
    if (!game) return;
    api(auth.token, "puzzles")
      .from("puzzles")
      .select(
        `
          puzzle_id,position,slug,tag,name,
              puzzle_tags(puzzle_tag_id,tags(tag_id,name))))`
      )
      .order("position")
      .order("tag")
      .eq("game_id", game.game_id)
      .eq("internal", false)
      .then(handleResponse(handleError, setPuzzles), handleError);
  }, [game, lastUpdatedAt]);

  const onAddAssociation = ({ id, puzzle_id, association_id }) => {
    api(auth.token, "puzzles")
      .from("puzzle_tags")
      .insert({
        game_id: game.game_id,
        puzzle_tag_id: id,
        tag_id: association_id,
        puzzle_id: puzzle_id
      })
      .then(handleResponse(handleError), handleError);
  };

  const onChangeAssociation = ({ puzzle_id, from_id, to_id }) => {
    api(auth.token, "puzzles")
      .from("puzzle_tags")
      .update({ tag_id: to_id })
      .match({ puzzle_id: puzzle_id, tag_id: from_id })
      .then(handleResponse(handleError), handleError);
    return;
  };

  const onRemoveAssociation = ({ puzzle_id, association_id }) => {
    api(auth.token, "puzzles")
      .from("puzzle_tags")
      .delete()
      .match({
        puzzle_id: puzzle_id,
        tag_id: association_id
      })
      .then(handleResponse(handleError), handleError);
  };

  const onCreateTag = option => {
    if (!auth.token) return;
    const id = uuidv4();
    const tag = {
      game_id: game.game_id,
      tag_id: id,
      name: option.value,
      type: "Puzzle"
    };
    api(auth.token, "puzzles")
      .from("tags")
      .insert(tag)
      .then(handleResponse(handleError), handleError);
    return tag;
  };

  return (
    <PuzzleBoard
      puzzles={tags}
      unassignedPuzzles={puzzles}
      setPuzzles={setTags}
      setUnassignedPuzzles={setPuzzles}
      onPuzzleChange={onPuzzleChange}
      onAddAssociation={onAddAssociation}
      onChangeAssociation={onChangeAssociation}
      onRemoveAssociation={onRemoveAssociation}
      onCreateCategory={onCreateTag}
    />
  );
}
