export default function DataGridColumn({
  name,
  title,
  frozen = true,
  type = "string",
  width = 2,
  select = null,
  compare = null,
  render = null,
  groupValue = null,
}) {
  return null;
}
