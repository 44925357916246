import { AuthenticationContext } from "./AuthenticationContext";
import React from "react";
import { useErrorHandler } from "react-error-boundary";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import {
  Button,
  Checkbox,
  Container,
  Dimmer,
  Divider,
  Header,
  Icon,
  Loader,
  Menu,
  Segment,
  Statistic,
  StatisticLabel,
  StatisticValue,
} from "semantic-ui-react";

import { q } from "./Query";
import {
  api,
  handleData,
  handleMaybeSingle,
  handleResponse,
  initialState,
  invalidate,
} from "./api";
import { format } from "date-fns";
import { cs } from "date-fns/locale";
import GameLogo from "./GameLogo";

function GameBoard({ game }) {
  const [stats, setStats] = React.useState(initialState());
  const [gameDetails, setGameDetails] = React.useState(initialState());

  const [auth] = React.useContext(AuthenticationContext);
  const handleError = useErrorHandler();

  React.useEffect(() => {
    if (!game) return;
    if (!gameDetails.didInvalidate) return;
    if (gameDetails.isFetching) return;
    api(auth?.token, "games")
      .from("games")
      .select(
        q([
          "game_id",
          "game_series_id",
          "title",
          "name",
          "start",
          "registration_start",
          "registration_end",
          "end",
          "start_precision",
          "volume",
          "description",
          "web_pages",
          "city",
          "teaser",
          "show_statistics",
          "show_problems",
          "show_puzzles",
          "include_in_ladders",
          { "game_series": ["game_series_id", "name", "slug", { "...game_series_checksums": ["logo_checksum"] }] },
          { "...game_checksums": ["logo_checksum"] },
        ]),
      )
      .eq("game_id", game.game_id)
      .eq("cancelled", false)
      .single()
      .then(handleData(handleError, setGameDetails), handleError);
  }, [auth, game, gameDetails, handleError]);

  React.useEffect(() => {
    if (!game) return;
    invalidate(setGameDetails)
  }, [game]);


  React.useEffect(() => {
    if (!game) return;
    api(null, "stats")
      .from("game_statistics")
      .select(q(["game_id", "num_puzzles", "num_teams"]))
      .eq("game_id", game.game_id)
      .maybeSingle()
      .then(handleData(handleMaybeSingle(handleError), setStats), handleError);
  }, [game]);

  const updateAttribute = (game_id: string, attribute) => {
    api(auth?.token, "games")
      .from("games")
      .update(attribute)
      .eq("game_id", game_id)
      .single()
      .then(handleResponse(handleError), handleError)
      .then(() => invalidate(setGameDetails));
  };

  const updateShowStatistics = (showStatistics: boolean) =>
    updateAttribute(game.game_id, { show_statistics: showStatistics });
  const updateShowPuzzles = (showPuzzles: boolean) =>
    updateAttribute(game.game_id, { show_puzzles: showPuzzles });
  const updateShowProblems = (showProblems: boolean) =>
    updateAttribute(game.game_id, { show_problems: showProblems });
  const updateIncludeInLadders = (includeInLadders: boolean) =>
    updateAttribute(game.game_id, { include_in_ladders: includeInLadders });
  return (
    <>
      <Menu attached>
        {auth?.token && (
          <>
            <Menu.Item>
              <Checkbox
                toggle
                label="Zobrazit statistiky"
                onChange={(_e, data) => updateShowStatistics(data.checked)}
                checked={gameDetails?.data?.show_statistics}
              />
            </Menu.Item>
            <Menu.Item>
              <Checkbox
                toggle
                label="Zobrazit šifry"
                onChange={(_e, data) => updateShowPuzzles(data.checked)}
                checked={gameDetails?.data?.show_puzzles}
              />
            </Menu.Item>
            <Menu.Item>
              <Checkbox
                toggle
                label="Zobrazit zadání šifer"
                onChange={(_e, data) => updateShowProblems(data.checked)}
                checked={gameDetails?.data?.show_problems}
              />
            </Menu.Item>
            <Menu.Item>
              <Checkbox
                toggle
                label="Počítat do žebříčku"
                onChange={(_e, data) => updateIncludeInLadders(data.checked)}
                checked={gameDetails?.data?.include_in_ladders}
              />
            </Menu.Item>
          </>
        )}
        <Menu.Item position="right">
          <Button
            icon="refresh"
            loading={gameDetails.isFetching}
            onClick={() => invalidate(setGameDetails)}
          />
        </Menu.Item>
      </Menu>

      <Dimmer active={gameDetails.isFetching}>
        <Loader />
      </Dimmer>


      {game && gameDetails.data && (
        <Container >
          <Segment basic padded="very">
            <Container text style={{ padding: "1rem" }}>
              <GameLogo game={game}
                floated="right"
                wrapped
              />
              <Header as="h1">
                {game?.title}
                <Header.Subheader>
                  {gameDetails.data.volume}. ročník
                </Header.Subheader>
              </Header>
              <ReactMarkdown rehypePlugins={[rehypeRaw as any]}  children={gameDetails?.data.description || gameDetails?.data.teaser}></ReactMarkdown>
              <a href={gameDetails.data?.web_pages}>{gameDetails.data?.web_pages}</a>
            </Container>
            <Divider />
            {gameDetails.data?.start && Date.parse(gameDetails.data?.start) > Date.now() && (
              <Container text >
                <Statistic.Group widths="three">
                  <Statistic >
                    <StatisticValue>
                      <Icon name="map outline" size="small" />
                    </StatisticValue>
                    <StatisticLabel>
                      {gameDetails.data?.city}
                    </StatisticLabel>
                  </Statistic >
                  <Statistic >
                    <StatisticValue>
                      <Icon name="calendar outline" size="small" />
                    </StatisticValue>
                    <StatisticLabel>
                      {format(Date.parse(gameDetails.data?.start), "d. MMMM", { locale: cs, })}
                    </StatisticLabel>
                  </Statistic >
                  {gameDetails.data?.start_precision === "exact" && (
                    <Statistic >
                      <StatisticValue>
                        <Icon name="clock outline" size="small" />
                      </StatisticValue>
                      <StatisticLabel>
                        {format(Date.parse(gameDetails.data?.start), "HH:mm", {
                          locale: cs,
                        })}{" "}
                        —
                        {format(Date.parse(gameDetails.data?.end), "HH:mm", {
                          locale: cs,
                        })}
                      </StatisticLabel>
                    </Statistic >
                  )}
                </Statistic.Group>
              </Container>
            )}

            {gameDetails.data?.end && Date.parse(gameDetails.data?.end) < Date.now() && (
              <Container text >
                <Statistic.Group widths="three">
                  <Statistic label="týmů" value={stats.data?.num_teams} />
                  <Statistic
                    label="hodin"
                    value={Math.round(
                      (Date.parse(gameDetails.data.end) -
                        Date.parse(gameDetails.data.start)) /
                      3600 /
                      1000,
                    )}
                  />
                  <Statistic label="šifer" value={stats.data?.num_puzzles} />
                </Statistic.Group>
              </Container>
            )}
          </Segment>
        </Container>
      )}
    </>
  );
}

export default GameBoard;
