import { Popup, Image } from "semantic-ui-react";

import DataGridColumn from "../..//DataGridColumn";

export const NameColumn = (
  <DataGridColumn
    name="name"
    title="Název"
    width={2}
    frozen={true}
    select={["name", "slug"]}
    render={({ puzzle_id, name, slug }, game) => (
      <Popup key={puzzle_id} trigger={<a className="puzzle-link">{name}</a>}>
        <Popup.Header>{name}</Popup.Header>
        <Popup.Content>
          <Image
            src={`https://statek.seslost.cz/${
              game.name
            }/sifry/${slug}.png?style=thumb`}
          />
        </Popup.Content>
      </Popup>
    )}
  />
);
