import React from "react";
import { EditContext } from "../EditContext";
import ModalPuzzleCard from "./ModalPuzzleCard";
import ModalPuzzleForm from "./ModalPuzzleForm";

export default function ModalPuzzle({ puzzle, setPuzzle, onChange }) {
  const [allowEdit, setAllowEdit] = React.useContext(EditContext);
  return allowEdit ? (
    <ModalPuzzleForm
      open={!!puzzle}
      puzzle={puzzle}
      setPuzzle={setPuzzle}
      onChange={onChange}
    />
  ) : (
    <ModalPuzzleCard open={!!puzzle} puzzle={puzzle} setPuzzle={setPuzzle} />
  );
}
