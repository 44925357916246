import "semantic-ui-css/semantic.min.css";

import "./App.css";

import React from "react";
import { Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import ErrorFallback from "./ErrorFallback";

import { ThemeContext, themes } from "./theme-context";
import Authentication from "./Authentication";
import DashBoard from "./DashBoard";

function App() {
  const [theme, setTheme] = React.useState(themes.dark);

  const toggleTheme = () => {
    setTheme(theme === themes.dark ? themes.light : themes.dark);
  };

  return (
    <>
      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Routes>
            <Route path="/:gameName/*" element={<Authentication />} />
            <Route
              path="*"
              element={<DashBoard /> }
            />
          </Routes>
        </ErrorBoundary>
      </ThemeContext.Provider>
    </>
  );
}

export default App;
