import React from "react";
import { useErrorHandler } from "react-error-boundary";
import {
  Button,
  Container,
  List,
  Image,
  Label,
  Modal,
} from "semantic-ui-react";

import { api, handleResponse } from "../api";
import "./ModalPuzzle.css";
import { colorize } from "../colors";
import { AuthenticationContext } from "../AuthenticationContext";
import { GameContext } from "../GameContext";
import { EditContext } from "../EditContext";

export default function ModalPuzzleCard({ puzzle, open, setPuzzle }) {
  const { position, tag, name, description, slug } = puzzle || {};
  const handleError = useErrorHandler();
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);
  const [allowEdit, setAllowEdit] = React.useContext(EditContext);

  React.useEffect(() => {
    if (!game) return;
    if (!puzzle) return;

    api(auth.token, "puzzles")
      .from("puzzles")
      .select(
        `
              puzzle_id,position,tag,slug,name,description,
                ...puzzle_checksums(problem_checksum),
                paper_puzzles(
                    papers(code,
                        location_papers(
                            locations!location_papers_location_id_fkey(name)))),
                puzzle_tags(puzzle_tag_id,
                    tags(tag_id,name)),
                puzzle_solutions(
                    solutions(code,solution,solvable_without_additional_information,
                        interpretation_solutions(interpretation_id,
                            interpretations(interpretation_id,
                                connections(connection_id,
                                    location_papers(location_paper_id,
                                        locations!location_papers_location_id_fkey(location_id,name)))))))
       `
      )
      .eq("puzzle_id", puzzle.puzzle_id)
      .single()
      .then(handleResponse(handleError, setPuzzle), handleError);
  }, [game, open]);

  return (
    <Modal
      open={open}
      onClose={() => setPuzzle(null)}
      size="large"
      className="puzzle"
    >
      <Modal.Header>
        Šifra {position}
        {tag} – {name}
        <Button
          floated="right"
          icon="edit"
          active={allowEdit}
          disabled={!auth?.token}
          onClick={() => setAllowEdit(true)}
        />
      </Modal.Header>
      <Modal.Content scrolling image>
        {game && puzzle && puzzle.problem_checksum && (
          <Image
            as="a"
            href={`https://statek.seslost.cz/${game.name}/sifry/${slug}.pdf?checksum=${puzzle.problem_checksum}`}
            className="puzzle"
            size="medium"
            wrapped
            src={`https://statek.seslost.cz/${game.name}/sifry/${slug}.png?style=preview&checksum=${puzzle.problem_checksum}`}
          />
        )}
        <Modal.Description>
          <List>
            <List.Item>
              <List.Icon name="marker" />
              <List.Content>
                <List.Header>Poloha</List.Header>
                <List.Description>
                  {puzzle &&
                    puzzle.paper_puzzles &&
                    puzzle.paper_puzzles
                      .map((pp) =>
                        (pp.papers.location_papers || [])
                          .filter((lp) => lp.locations)
                          .map((lp) => lp.locations.name)
                      )
                      .flat()
                      .join(", ")}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
          {puzzle &&
            puzzle.puzzle_solutions &&
            puzzle.puzzle_solutions.map((ps) => {
              const { code, solvable_without_additional_information } = ps.solutions;

              return (
                <List key={`list-${ps.solutions.solution_id}`}>
                  {code && (
                    <List.Item>
                      <List.Icon name="triangle right" />
                      <List.Content>
                        <List.Header>Kód</List.Header>
                        <List.Description>{code}</List.Description>
                      </List.Content>
                    </List.Item>
                  )}
                  {solvable_without_additional_information === false && (
                    <List.Item>
                      <List.Icon name="warning" color="red"/>
                      <List.Content>
                        <List.Header>Šifru nelze vyluštit jen z informací, které jsou tu.</List.Header>
                      </List.Content>
                    </List.Item>
                  )}
                </List>
              );
            })}
          <Container text>{description}</Container>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Label.Group size="tiny">
          {puzzle &&
            puzzle.puzzle_tags &&
            puzzle.puzzle_tags.map((pt) => (
              <Label key={pt.puzzle_tag_id} color={colorize(pt.tags.tag_id)}>
                {pt.tags.name}
              </Label>
            ))}
        </Label.Group>
      </Modal.Actions>
      )
    </Modal>
  );
}
