import React, { useRef } from "react";

import "@asseinfo/react-kanban/dist/styles.css";
import "./Edge.css";

export default function Edge({ layer, init, connection }) {

    const polylines = useRef([])
    const colorize = () => '#666666'


    React.useEffect(() => {
        if (!init) return
        if (!layer.current) return

        const smap = (window).SMap;
        if (!smap) return

        const sources = connection.interpretations.interpretation_solutions
            .map(is => is.solutions.puzzle_solutions
                .map(ps => ps.puzzles.paper_puzzles
                    .map(pp => pp.papers.location_papers
                        .map(lp => { return { ...lp.locations, part: pp.part, middle: connection.interpretations.locations } }).flat()).flat()).flat()).flat()

        const target = connection.location_papers.locations

        polylines.current.forEach((polyline) => {
            if (layer.current.getGeometries()[polyline.getId()])
                layer.current.removeGeometry(polyline)
        })
        polylines.current = []
        polylines.current = sources.map((source) => {

            const middle = source.middle
            const coords = []
            if (middle) {
                coords.push([
                    smap.Coords.fromWGS84(source.longitude, source.latitude),
                    smap.Coords.fromWGS84((source.longitude + middle.longitude) / 2, (source.latitude + middle.latitude) / 2),
                    smap.Coords.fromWGS84(middle.longitude, middle.latitude)
                ])
                coords.push([
                    smap.Coords.fromWGS84(middle.longitude, middle.latitude),
                    smap.Coords.fromWGS84((target.longitude + middle.longitude) / 2, (target.latitude + middle.latitude) / 2),
                    smap.Coords.fromWGS84(target.longitude, target.latitude),
                ])
            } else {
                coords.push([
                    smap.Coords.fromWGS84(source.longitude, source.latitude),
                    smap.Coords.fromWGS84((source.longitude + target.longitude) / 2, (source.latitude + target.latitude) / 2),
                    smap.Coords.fromWGS84(target.longitude, target.latitude)
                ])
            }

            const polylines = coords.map(coord =>
                new smap.Geometry(smap.GEOMETRY_POLYLINE, null, coord, {
                    color: colorize(connection),
                    opacity: source.part ? 0.1 : 0.4,
                    outlineWidth: 0
                }))
            polylines.forEach(polyline => layer.current.addGeometry(polyline))
            return polylines
        }).flat()

        return () => {
            polylines.current.forEach((polyline) => {
                polyline.clear()
            })
        }
    }, [init, connection])

    return null;
}
