import DataGridCollectionColumn from "../..//DataGridCollectionColumn";
import { Label } from "semantic-ui-react";
import { Puzzle, Tag } from "../../interface";
import { colorize } from "../../colors";

export const SolutionTagColumn = (
  <DataGridCollectionColumn
    name="solution_tags"
    title="Principy"
    type="collection"
    frozen={true}
    width={2}
    select={{
      puzzle_solutions: {
        solutions: {
          solution_tags: [
            "solution_tag_id",
            {
              tags: ["tag_id", "name"]
            }
          ]
        }
      }
    }}
    compare={(a: Tag, b: Tag) => a.name.localeCompare(b.name)}
    renderItem={([id, tag]) => (
      <Label key={id} color={colorize(tag.tag_id)} size="mini">
        {tag.name}
      </Label>
    )}
    getItems={(puzzle: Puzzle) =>
      puzzle.puzzle_solutions
        ? puzzle.puzzle_solutions
            .filter(ps => ps.solutions)
            .filter(ps => ps.solutions.solution_tags)
            .map(ps =>
              ps.solutions.solution_tags.map(st => {
                return [st.tags.tag_id, st.tags];
              })
            )
            .flat()
        : []
    }
  />
);
