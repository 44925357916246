import { Popup, Image } from "semantic-ui-react";

import DataGridCollectionColumn from "../..//DataGridCollectionColumn";
import { Puzzle, Paper } from "../../interface";

export const PaperPuzzleColumn = (
  <DataGridCollectionColumn
    name="paper_puzzles"
    title="Šifry"
    type="collection"
    frozen={true}
    width={2}
    select={{
      paper_puzzles: [
        "paper_puzzle_id",
        { puzzles: ["puzzle_id", "position", "tag", "name", "slug"] }
      ]
    }}
    compare={(a: Puzzle, b: Puzzle) => a.position - b.position}
    renderItem={([, puzzle]) => puzzle.name}
    join={arr => arr.sort().join(", ")}
    getItems={(paper: Paper) =>
      paper.paper_puzzles
        ? paper.paper_puzzles.map(pp => [pp.puzzles.puzzle_id, pp.puzzles])
        : []
    }
    render={(paper, game) => {
      if (!paper.paper_puzzles) return [];
      return paper.paper_puzzles
        .filter(pp => pp.puzzles)
        .map(pp => (
          <Popup
            key={pp.paper_puzzle_id}
            trigger={<a className="puzzle-link">{pp.puzzles.name}</a>}
          >
            <Popup.Header>{pp.puzzles.name}</Popup.Header>
            <Popup.Content>
              <Image
                src={`https://statek.seslost.cz/${game.name}/sifry/${
                  pp.puzzles.slug
                }.png?style=thumb`}
              />
            </Popup.Content>
          </Popup>
        ));
    }}
  />
);
