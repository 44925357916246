import React from "react";
import { EditContext } from "../EditContext";
import ModalPaperCard from "./ModalPaperCard";
import ModalPaperForm from "./ModalPaperForm";

export default function ModalPaper({ paper, setPaper, onChange }) {
  const [allowEdit] = React.useContext(EditContext);
  return allowEdit ? (
    <ModalPaperForm
      open={!!paper}
      paper={paper}
      setPaper={setPaper}
      onChange={onChange}
    />
  ) : (
    <ModalPaperCard open={!!paper} paper={paper} setPaper={setPaper} />
  );
}
