import React from "react";
import { useSearchParams } from "react-router-dom";

import DataGridPanel from "../DataGridPanel";
import DataGridColumn from "../DataGridColumn";
import ModalPaper from "../components/ModalPaper";

import { PaperPuzzleColumn } from "./papers/PaperPuzzleColumn";
import { LocationPaperColumn } from "./papers/LocationPaperColumn";

export default function PaperDataGrid({ viewSelect }) {
  const [data, setData] = React.useState({
    didInvalidate: true,
    isFetching: false,
    items: null,
    lastUpdated: null
  });

  const [search, setSearch] = useSearchParams();
  const defaultPaper = search.has("paper")
    ? // ? { puzzle_id: search.get("puzzle"), name: null, problem_file_name: null }
      null
    : null;

  const [paper, setPaper] = React.useState(defaultPaper);

  React.useEffect(() => {
    if (paper) search.set("paper", paper.paper_id);
    else search.delete("paper");
    setSearch(search);
  }, [paper]);

  const onPaperChange = () => {
    setData({ ...data, didInvalidate: true });
  };

  return (
    <>
      <ModalPaper
        paper={paper}
        setPaper={setPaper}
        onChange={onPaperChange}
      />
      <DataGridPanel
        table={"papers"}
        primaryKey={"paper_id"}
        sort="code"
        viewSelect={viewSelect}
        data={data}
        setData={setData}
        onRowClick={setPaper}
      >
        <DataGridColumn name="label" title="Označení" frozen={false} width={1} />
        <DataGridColumn name="code" title="Kód" frozen={true} width={2} />
        <DataGridColumn
          name="final"
          title="Cíl?"
          width={1}
          render={({ final }) => (final ? "ano" : "ne")}
        />
        {PaperPuzzleColumn}
        {LocationPaperColumn}
      </DataGridPanel>
    </>
  );
}
