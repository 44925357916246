import React from "react";
import { Button, Image, Modal } from "semantic-ui-react";

import "./ModalPuzzle.css";
import { GameContext } from "../GameContext";
import { EditContext } from "../EditContext";
import { AuthenticationContext } from "../AuthenticationContext";

export default function Gallery({ title, open, photos, onClose }) {
  const game = React.useContext(GameContext);
  const [allowEdit, setAllowEdit] = React.useContext(EditContext);
  const [auth] = React.useContext(AuthenticationContext);
  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      size="large"
      className="puzzle"
    >
      <Modal.Header>
        {title}
        <Button
          floated="right"
          icon="edit"
          disabled={!auth?.token}
          active={allowEdit}
          onClick={() => setAllowEdit(true)}
        />
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Image.Group size="medium">
            {photos.map((photo) => (
              <Image
                key={["modal", photo.photo_id].join("-")}
                inline
                wrapped
                bordered
                src={
                  "https://trakar.seslost.cz/" +
                  game.name +
                  "/fotky/" +
                  photo.photo_id +
                  ".png?style=medium"
                }
              />
            ))}
          </Image.Group>
        </Modal.Description>
      </Modal.Content>
      )
    </Modal>
  );
}
