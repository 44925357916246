import DataGridCollectionColumn from "../..//DataGridCollectionColumn";
import { Stage, Solution } from "../../interface";

export const StageColumn = (
  <DataGridCollectionColumn
    name="stages"
    title="Část hry"
    type="collection"
    frozen={true}
    width={1}
    select={{
      puzzle_solutions: {
        puzzles: {
          puzzle_stages: {
            stages: ["stage_id", "position", "name", "title"]
          }
        }
      }
    }}
    compare={(a: Stage, b: Stage) => a.position - b.position}
    renderItem={([, stage]) => stage.title}
    getItems={(solution: Solution) =>
      solution.puzzle_solutions
        ? solution.puzzle_solutions
            .filter(ps => ps.puzzles)
            .filter(ps => ps.puzzles.puzzle_stages)
            .map(ps =>
              ps.puzzles.puzzle_stages.map(({ stages }) => [
                stages.stage_id,
                stages
              ])
            )
            .flat()
        : []
    }
  />
);
