import DataGridCollectionColumn from "../..//DataGridCollectionColumn";
import { Puzzle, User } from "../../interface";

export const PuzzleUserColumn = (
  <DataGridCollectionColumn
    name="puzzle_users"
    title="Autor"
    type="collection"
    frozen={false}
    width={1}
    select={{
      puzzle_users: [
        "user_id",
        {
          users: ["user_id", "alias", "first_name"]
        }
      ]
    }}
    compare={(a: User, b: User) => a.first_name.localeCompare(b.first_name)}
    renderItem={([, user]) => user.alias || user.first_name}
    getItems={(puzzle: Puzzle) =>
      puzzle.puzzle_users
        ? puzzle.puzzle_users.map(({ users }) => [users.user_id, users])
        : []
    }
  />
);
