import { Label } from "semantic-ui-react";

export default function ColmunHeader({
  id,
  title,
  cards,
  onClick = null,
  color = null
}) {
  return (
    <div className="react-kanban-column-header">
      <div onClick={() => onClick ? onClick(id) : null}>{title}</div>
      <Label attached="top right" size="tiny" circular color={color}>
        {cards && cards.length}
      </Label>
    </div>
  );
}
