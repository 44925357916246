import React from "react";
import { EditContext } from "../EditContext";
import ModalConnectionCard from "./ModalConnectionCard";
import ModalConnectionForm from "./ModalConnectionForm";

export default function ModalConnection({
  connection,
  setConnection,
  onChange,
}) {
  const [allowEdit] = React.useContext(EditContext);

  return allowEdit ? (
    <ModalConnectionForm
      connection={connection}
      setConnection={setConnection}
      onChange={onChange}
    />
  ) : (
    <ModalConnectionCard
      connection={connection}
      setConnection={setConnection}
    />
  );
}
