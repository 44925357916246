import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { Stage } from "./interface";

import "@asseinfo/react-kanban/dist/styles.css";

import { api, handleResponse } from "./api";
import PuzzleBoard from "./PuzzleBoard";
import ModalStageForm from "./components/ModalStageForm";
import { AuthenticationContext } from "./AuthenticationContext";
import { GameContext } from "./GameContext";

export default function PuzzleStageBoard() {
  const [stage, setStage] = React.useState(null);
  const [stages, setStages] = React.useState([]);
  const [puzzles, setPuzzles] = React.useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState(null);

  const handleError = useErrorHandler();
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);

  // Load stages
  React.useEffect(() => {
    if (!game) return;
    api(auth.token, "puzzles")
      .from("stages")
      .select(
        `stage_id,game_id,position,name,title,
            puzzle_stages(puzzle_stage_id,puzzle_id,
              puzzles(puzzle_id,position,slug,tag,name,
                puzzle_users(puzzle_user_id,
                  users(user_id,first_name,alias)),
                puzzle_tags(puzzle_tag_id,
                  tags(tag_id,name))))`,
      )
      .order("position")
      .order("position", { foreignTable: "puzzle_stages.puzzles" })
      .order("tag", { foreignTable: "puzzle_stages.puzzles" })
      .eq("game_id", game.game_id)
      .then(
        handleResponse(handleError, (data) => {
          setStages(
            data.map((stage: Stage) => ({
                ...stage,
                id: stage.stage_id,
                puzzles: stage.puzzle_stages.map((pu) => {
                    return {
                        id: pu.puzzle_stage_id,
                        title: [stage.position, stage.title].join(" – "),
                        ...pu.puzzles,
                    };
                }),
            })),
          );
        }),
        handleError,
      );
  }, [game, lastUpdatedAt]);

  const onPuzzleChange = () => {
    setLastUpdatedAt(new Date());
  };

  // Load unassigned puzzles
  React.useEffect(() => {
    if (!game) return;
    api(auth.token, "puzzles")
      .from("puzzles")
      .select(
        `
          puzzle_id,position,slug,tag,name,
              puzzle_stages(puzzle_stage_id)))`,
      )
      .order("position")
      .order("tag")
      .eq("game_id", game.game_id)
      .eq("internal", false)
      .then(
        handleResponse(handleError, (data) =>
          setPuzzles(data.filter((p) => p.puzzle_stages.length === 0)),
        ),
        handleError,
      );
  }, [game, lastUpdatedAt]);

  const onAddAssociation = ({ id, puzzle_id, association_id }) => {
    api(auth.token, "puzzles")
      .from("puzzle_stages")
      .insert({
        game_id: game.game_id,
        puzzle_stage_id: id,
        stage_id: association_id,
        puzzle_id: puzzle_id,
      })
      .then(handleResponse(handleError), handleError);
  };

  const onChangeAssociation = ({ puzzle_id, from_id, to_id }) => {
    api(auth.token, "puzzles")
      .from("puzzle_stages")
      .update({ stage_id: to_id })
      .match({ puzzle_id: puzzle_id, stage_id: from_id })
      .then(handleResponse(handleError), handleError);
  };

  const onRemoveAssociation = ({ puzzle_id, association_id }) => {
    api(auth.token, "puzzles")
      .from("puzzle_stages")
      .delete()
      .match({
        puzzle_id: puzzle_id,
        stage_id: association_id,
      })
      .then(handleResponse(handleError), handleError);
  };

  const onAddColumn = (data) => {
    setStage({
      name: null,
      title: null,
      game_id: game.game_id,
      position: (Math.max(...stages.map(({ position }) => position)) || 0) + 1,
    });
  };

  const onStageAdd = (stage: Stage) => {
    const record = { puzzles: [], id: stage.stage_id, frozen: true, ...stage };
    setStages([...stages, record]);
  };
  const onStageChange = () => {
    setLastUpdatedAt(new Date());
  };
  const onHeaderClick = (id: string) => {
    setStage(stages.find(({ stage_id }) => stage_id === id));
  };

  const onUpdatePosition = (
    { id, fromPosition, toPosition },
    visibleStages: string[],
  ) => {
    const from = stages.find(
      ({ stage_id }) => stage_id === visibleStages[fromPosition],
    );
    const to = stages.find(
      ({ stage_id }) => stage_id === visibleStages[toPosition],
    );

    const min = Math.min(from.position, to.position);
    const max = Math.max(from.position, to.position);

    const data = stages
      .filter(({ position }) => position >= min && position <= max)
      .map((stage) => {
        return {
          ...stage,
          position: stage.position + Math.sign(fromPosition - toPosition),
        };
      })
      .map((stage) => {
        return stage.stage_id === id
          ? {
              ...stage,
              position: to.position,
            }
          : stage;
      })
      .map(({ stage_id, game_id, name, title, position }) => {
        return { stage_id, game_id, position, name, title };
      });
    api(auth.token, "puzzles")
      .from("stages")
      .insert(data, { upsert: true })
      .then(handleResponse(handleError), handleError);
  };

  return (
    <>
      <ModalStageForm
        open={!!stage}
        stage={stage}
        stages={stages}
        setStage={setStage}
        onAdd={onStageAdd}
        onChange={onStageChange}
      />
      <PuzzleBoard
        puzzles={stages}
        unassignedPuzzles={puzzles}
        setPuzzles={setStages}
        setUnassignedPuzzles={setPuzzles}
        onPuzzleChange={onPuzzleChange}
        onAddAssociation={onAddAssociation}
        onChangeAssociation={onChangeAssociation}
        onRemoveAssociation={onRemoveAssociation}
        onAddColumn={onAddColumn}
        onHeaderClick={onHeaderClick}
        onUpdatePosition={onUpdatePosition}
      />
    </>
  );
}
