import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { Icon, Input, List, Segment } from "semantic-ui-react";

import "@asseinfo/react-kanban/dist/styles.css";

import { AuthenticationContext } from "./AuthenticationContext";
import { api, handleResponse, receive } from "./api";
import { EditContext } from "./EditContext";
import { Connection, Location } from "./interface";

export default function Locations({
  locations,
  setLocations,
  setLocation,
  selectedLocation,
  setSelectedLocation,
  colorize,
  setDragging,
}) {
  const [auth] = React.useContext(AuthenticationContext);
  const [allowEdit] = React.useContext(EditContext);
  const handleError = useErrorHandler();

  const onItemClick = (location: Location) => {
    setDragging(null);
    if (
      selectedLocation &&
      selectedLocation.location_id === location.location_id
    ) {
      setLocation(location);
    } else {
      setSelectedLocation(location);
    }
  };

  const onInputClick = (event) => {
    event.stopPropagation();
  };
  const onChange = (event, option) => {
    setSelectedLocation({
      ...selectedLocation,
      name: option.value,
      didInvalidate: true,
    });
  };

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.charCode !== 13) return;
    if (selectedLocation?.didInvalidate) {
      api(auth.token, "puzzles")
        .from("locations")
        .update({ name: selectedLocation.name })
        .match({ location_id: selectedLocation.location_id })
        .single()
        .then(
          handleResponse(handleError, (data) => {
            receive(
              locations.data.map((l: Location) =>
                l.location_id === data.location_id
                  ? { ...selectedLocation, ...data }
                  : l,
              ),
              setLocations,
            );
            setSelectedLocation(null);
          }),
          (error) => handleError(error),
        );
    }
  };

  return (
    <Segment basic attached>
      <List selection divided>
        {locations?.data?.map((location: Location) => {
          return (
            <List.Item
              key={location.location_id}
              onMouseUp={() => setDragging(null)}
              onClick={() => onItemClick(location)}
              active={
                selectedLocation &&
                location.location_id === selectedLocation.location_id
              }
            >
              <List.Icon
                name="marker"
                color={colorize(location)}
                onMouseDown={() => setDragging(location)}
                size={location.latitude && location.longitude ? null : "large"}
              />
              <List.Content>
                <List.Header>
                  {location.location_papers
                    .map(({ papers }) => papers)
                    .map(({ code, final }) => final ? <Icon name="flag checkered" /> : code)
                    ||
                    location.location_papers
                      .flatMap((lp) => lp.papers.paper_puzzles)
                      .map(({ puzzles }) => puzzles)
                      .map(({ name }) => name)
                      .join(", ")
                  }
                </List.Header>
                <List.Description>
                  {selectedLocation &&
                    allowEdit &&
                    location.location_id === selectedLocation.location_id ? (
                    <Input
                      fluid
                      value={selectedLocation.name}
                      onClick={onInputClick}
                      onKeyPress={onKeyPress}
                      onChange={onChange}
                    />
                  ) : location.name ? (
                    location.name
                  ) : (
                    <em>
                      →{" "}
                      {location.location_papers
                        .flatMap((lp) =>
                          lp.connections.map((c: Connection) =>
                            c.interpretations.interpretation_solutions.map(
                              (is) => is.solutions.solution,
                            ),
                          ),
                        )
                        .join(", ")}
                    </em>
                  )}
                </List.Description>
              </List.Content>
            </List.Item>
          );
        })}
      </List>
      {locations.data && (
        <p className="count">Zobrazeno {locations.data.length} stanovišť.</p>
      )}
    </Segment>
  );
}
