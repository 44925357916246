import React from "react";
import Cookies from "universal-cookie";

import { EditContext } from "./EditContext";
import GameBoard from "./GameBoard";

function EditMode() {
  const cookies = React.useMemo(() => new Cookies(), []);

  const defaultAllowEdit = cookies.get("edit") === "1";
  const [allowEdit, setAllowEdit] = React.useState(defaultAllowEdit);

  React.useEffect(() => {
    if (allowEdit) {
      cookies.set("edit", 1, { path: "/" });
    } else {
      cookies.set("edit", 0, { path: "/" });
    }
  }, [allowEdit, cookies]);

  return (
    <EditContext.Provider value={[allowEdit, setAllowEdit]}>
      {<GameBoard />}
    </EditContext.Provider>
  );
}

export default EditMode;
