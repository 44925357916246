import React from "react";
import { Header, Segment } from "semantic-ui-react";

import "./DataGrid.css";
import { GameContext } from "./GameContext";
import Table from "./Table";

export default function TableGroup({
  columns,
  groupBy,
  data,
  sortColumn,
  categories,
  onRowClick,
  handleSort,
}) {
  const columnsWithoutCategory = columns.filter((c) => c.name !== groupBy.name);
  const game = React.useContext(GameContext);

  return (
    <>
      {categories.map(([id, category]) => (
        <Segment basic key={"category-" + id}>
          <Header>
            {groupBy.type === "collection"
              ? groupBy.renderItem([id, category])
              : category}
          </Header>
          <Table
            id={category}
            game={game}
            columns={columnsWithoutCategory}
            sortColumn={sortColumn}
            handleSort={handleSort}
            onRowClick={onRowClick}
            rows={data.filter((row) => {
              return groupBy.type === "collection"
                ? groupBy
                    .getItems(row)
                    .map(([id, category]) => id)
                    .indexOf(id) !== -1
                : row[groupBy.name] === category;
            })}
          />
        </Segment>
      ))}
    </>
  );
}
// <Table celled striped selectable compact="very">
//   <Table.Header>
//     <Table.Row>
//       {columnsWithoutCategory.map(({ name, title }, i) => (
//         <Table.HeaderCell
//           key={["column", i, name].join("-")}
//           sorted={
//             name === sortColumn.columnKey
//               ? sortColumn.direction
//               : null
//           }
//           onClick={() => handleSort(name)}
//         >
//           {title}
//         </Table.HeaderCell>
//       ))}
//     </Table.Row>
//   </Table.Header>
//   <Table.Body>
//     {data
//       .filter((row) => {
//         return groupBy.type === "collection"
//           ? groupBy
//               .getItems(row)
//               .map(([id, category]) => id)
//               .indexOf(id) !== -1
//           : row[groupBy.name] === category;
//       })
//       .map((row, i) => (
//         <Table.Row
//           key={["row", i].join("-")}
//           onClick={() => onRowClick(row)}
//         >
//           {columnsWithoutCategory.map(({ key, width, render }, j) => (
//             <Table.Cell
//               key={["cell", i, j].join("-")}
//               width={width || 1}
//             >
//               {render(row, game)}
//             </Table.Cell>
//           ))}
//         </Table.Row>
//       ))}
//   </Table.Body>
// </Table>
