import React from "react";
import { EditContext } from "../EditContext";
import ModalSolutionCard from "./ModalSolutionCard";
import ModalSolutionForm from "./ModalSolutionForm";

export default function ModalSolution({
  solution,
  setSolution,
  onChange,
}) {
  const [allowEdit] = React.useContext(EditContext);
  return allowEdit ? (
    <ModalSolutionForm
      open={!!solution}
      solution={solution}
      setSolution={setSolution}
      onChange={onChange}
    />
  ) : (
    <ModalSolutionCard
      open={!!solution}
      solution={solution}
      setSolution={setSolution}
    />
  );
}
