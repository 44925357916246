import React from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Checkbox, Menu } from "semantic-ui-react";

import "@asseinfo/react-kanban/dist/styles.css";

import { EditContext } from "./EditContext";
import GeoMap from "./Map";
import { initialState, invalidate } from "./api";
import { AuthenticationContext } from "./AuthenticationContext";

export default function MapPanel() {
  const [search, setSearch] = useSearchParams();
  const defaultColorize = search.get("colorize") || "stage";

  const [locations, setLocations] = React.useState(initialState);
  const [connections, setConnections] = React.useState(initialState);
  const [allowEdit, setAllowEdit] = React.useContext(EditContext);
  const [allowDrag, setAllowDrag] = React.useState(false);
  const [colorize, setColorize] = React.useState(defaultColorize);

  const [auth] = React.useContext(AuthenticationContext);

  React.useEffect(() => {
    if (colorize !== "stage") search.set("colorize", colorize);
    else search.delete("colorize");
    setSearch(search);
  }, [colorize, search, setSearch]);

  return (
    <>
      <Menu attached>
        <Menu.Item>
          <Checkbox
            toggle
            label={colorize === "stage" ? "Část hry" : "Fotky"}
            onChange={(_e, data) =>
              setColorize(data.checked ? "stage" : "photo")
            }
            checked={colorize === "stage"}
          />
        </Menu.Item>

        {auth?.token && (
          <Menu.Item position="right">
            <Checkbox
              toggle
              label="Povolit posouvání markerů"
              onChange={(_e, data) => setAllowDrag(data.checked)}
              checked={allowDrag}
            />
          </Menu.Item>
        )}
        {auth?.token && (
          <Menu.Item >
            <Checkbox
              toggle
              label="Povolit úpravy"
              onChange={(_e, data) => setAllowEdit(data.checked)}
              checked={allowEdit}
            />
          </Menu.Item>
        )}
        <Menu.Item position={auth.token ? null : "right"}>
          <Button
            icon="refresh"
            loading={locations.isFetching || connections.isFetching}
            onClick={() => {
              invalidate(setLocations);
              invalidate(setConnections);
            }}
          />
        </Menu.Item>
      </Menu>
      <GeoMap
        locations={locations}
        colorizeBy={colorize}
        setLocations={setLocations}
        connections={connections}
        setConnections={setConnections}
        allowDrag={allowDrag}
      />
    </>
  );
}
