import DataGridColumn from "../..//DataGridColumn";
import { Connection } from "../../interface";

export const LocationColumn = (
  <DataGridColumn
    name="locations"
    title="Další stanoviště"
    type="string"
    frozen={true}
    width={2}
    select={{
      location_papers: {
        "locations!location_papers_location_id_fkey": ["location_id", "name"]
      }
    }}
    compare={(a: Connection, b: Connection) =>
      a.location_papers.locations.name.localeCompare(
        b.location_papers.locations.name
      )
    }
    render={(connection: Connection) =>
      connection.location_papers.locations.name
    }
  />
);
