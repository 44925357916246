import React from "react";
import { Card, Header, Image } from "semantic-ui-react";

import TagGroup from "./TagGroup";
import { GameContext } from "./GameContext";

import "./SolutionCard.css";

export default function SolutionCard({
  solution,
  color,
  onClick,
  showPreview,
}) {
  const game = React.useContext(GameContext);
  const puzzle =
    solution.puzzle_solutions &&
    solution.puzzle_solutions.map(({ puzzles }) => puzzles)[0];

  return (
    <Card
      solution={solution}
      onClick={onClick}
      color={color}
      className="solution"
    >
      {showPreview && puzzle && (
        <Image
          src={`https://statek.seslost.cz/${game.name}/sifry/${puzzle.slug}.png?style=preview`}
          wrapped
          ui={false}
        />
      )}

      <Card.Content>
        <Card.Header>
          {solution.puzzle_solutions &&
            solution.puzzle_solutions
              .map(
                (ps) =>
                  `${ps.puzzles.position}${ps.puzzles.tag || ""} — ${
                    ps.puzzles.name
                  }`
              )
              .join(", ")}
          <Header sub size="small" color="grey">
            {solution.code}
          </Header>
        </Card.Header>
        <Card.Description />
      </Card.Content>
      <Card.Content extra>
        <TagGroup taggings={solution.solution_tags} />
      </Card.Content>
    </Card>
  );
}
