import React, { useRef } from "react";

import "@asseinfo/react-kanban/dist/styles.css";
import "./Marker.css";
import { colors } from "./colors"

export default function Marker({ layer, init, location, colorize, active, draggable }) {
    const marker = useRef()


    const createLabel = (location) => {
        let div = document.createElement('div')
        div.className = 'marker ui circular basic pointing below mini label ' + (colorize(location) || '')


        const paperCodes = [...new Set(location.location_papers.map(lp => lp.papers.code))]
        const solutionCodes = location.interpretations
            .map(i => i.interpretation_solutions.map(is => is.solutions.code)
                .filter(c => c)
                .map(c => `→ ${c}`)
            ).flat()

        div.innerHTML = paperCodes.concat(solutionCodes).join(", ")

        return div
    }

    React.useEffect(() => {
        if (!marker.current) return

        const div = marker.current._options.url
        colors.forEach((color) => div.classList.remove(color))
        div.classList.add(colorize(location))
    }, [colorize])

    React.useEffect(() => {
        if (!init) return
        if (!layer.current) return

        const smap = (window).SMap;
        const coords = smap.Coords.fromWGS84(location.longitude, location.latitude);
        const label = createLabel(location)
        const currentLayer = layer.current

        const m = new smap.Marker(coords, null, {
            title: location.name,
            url: label,
            anchor: { left: 3.2 * label.innerText.length, top: 26 }
        })

        m.decorate(smap.Marker.Feature.Draggable);
        m.location = location
        marker.current = m
        layer.current.addMarker(marker.current);

        return () => {
            currentLayer.removeMarker(m)
        }
    }, [init])

    React.useEffect(() => {
        if (!init) return
        if (!marker.current) return
        marker.current.setDrag(draggable);
    }, [init, draggable])

    React.useEffect(() => {
        if (!init) return
        const containers = Object.values(marker.current.getContainer())
        if (active)
            containers.map(c => c.classList.add('active'))
        else
            containers.map(c => c.classList.remove('active'))
    }, [init, active])

    return null;
}
