import Board from "@asseinfo/react-kanban";
import React from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { Checkbox, Menu } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";

import "@asseinfo/react-kanban/dist/styles.css";

import ColumnHeader from "./ColumnHeader";
import SolutionCard from "./SolutionCard";
import ModalSolution from "./components/ModalSolution";

import { EditContext } from "./EditContext";
import "./SolutionBoard.css";
import { Solution } from "./interface";
import PuzzleBoardNavigation from "./PuzzleBoardNavigation";
import { AuthenticationContext } from "./AuthenticationContext";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function SolutionBoard({
  solutions,
  setSolutions,
  unassignedSolutions,
  setUnassignedSolutions,
  onSolutionChange,
  onAddAssociation,
  onChangeAssociation,
  onRemoveAssociation,
}): JSX.Element {
  const [showPreviews, setShowPreviews] = React.useState(false);
  const [board, setBoard] = React.useState({ columns: [] });
  const [solution, setSolution] = React.useState(null);
  const [selectedSolutions, setSelectedSolutions] = React.useState(null);
  const [allowEdit, setAllowEdit] = React.useContext(EditContext);

  const [auth] = React.useContext(AuthenticationContext);

  // Load all tags
  let params = useQuery();
  const defaultSolutions = params
    .getAll("columns")
    .flatMap((t) => t.split(","));

  const createBoard = ({ solutions, unassignedSolutions }) => {
    let columns = [];

    if (selectedSolutions !== null && selectedSolutions.length > 0) {
      columns = selectedSolutions.map((o) =>
        solutions.find(({ id }) => id === o.value),
      );
    } else if (defaultSolutions && defaultSolutions.length > 0) {
      columns = defaultSolutions
        .filter((o) => solutions.some(({ title }) => title === o))
        .map((o) => solutions.find(({ title }) => title === o));
    } else if (solutions) {
      columns = solutions.filter((solution) => solution.solutions.length > 0);
    }

    return {
      columns: [
        {
          id: null,
          title: "nepřiřazené",
          cards: !unassignedSolutions
            ? []
            : unassignedSolutions.map((p) => {
              return { id: p.id, ...p };
            }),
        },
      ].concat(
        columns.map((solution) => {
          return {
            id: solution.id,
            title: solution.title,
            cards: (solution.solutions || [])
              .sort((a, b) => a.tag && a.tag.localeCompare(b.tag))
              .sort((a, b) => a.position - b.position),
          };
        }),
      ),
    };
  };

  // Configure cancan board
  React.useEffect(() => {
    setBoard(createBoard({ solutions, unassignedSolutions }));
  }, [solutions, unassignedSolutions, selectedSolutions]);

  React.useEffect(() => {
    if (!auth.token)
      setAllowEdit(false)
  }, [auth, setAllowEdit]);

  const onCardDragEnd = (
    solution,
    { fromColumnId, fromPosition },
    { toColumnId, toPosition },
  ) => {
    const id = uuidv4();
    if (fromColumnId === toColumnId) return;
    const updatedSolution = { id: id, ...solution };

    const updatedSolutions = solutions.map((solution) => {
      let updatedSolutions = solution.solutions;

      if (solution.id === fromColumnId && solution.id === toColumnId) {
        if (fromPosition < toPosition)
          updatedSolutions = [
            ...solution.solutions.slice(0, fromPosition),
            ...solution.solutions.slice(fromPosition + 1, toPosition + 1),
            updatedSolution,
            ...solution.solutions.slice(toPosition + 1),
          ];
        else
          updatedSolutions = [
            ...solution.solutions.slice(0, toPosition),
            updatedSolution,
            ...solution.solutions.slice(toPosition, fromPosition),
            ...solution.solutions.slice(fromPosition + 1),
          ];
      } else if (solution.id === fromColumnId) {
        updatedSolutions = [
          ...solution.solutions.slice(0, fromPosition),
          ...solution.solutions.slice(fromPosition + 1),
        ];
      } else if (solution.id === toColumnId) {
        updatedSolutions = [
          ...solution.solutions.slice(0, toPosition),
          updatedSolution,
          ...solution.solutions.slice(toPosition),
        ];
      }
      return {
        ...solution,
        solutions: updatedSolutions,
      };
    });

    let updatedUnassignedSolutions = unassignedSolutions;

    if (null === fromColumnId && null === toColumnId) {
      if (fromPosition < toPosition)
        updatedUnassignedSolutions = [
          ...unassignedSolutions.slice(0, fromPosition),
          ...unassignedSolutions.slice(fromPosition + 1, toPosition + 1),
          solution,
          ...unassignedSolutions.slice(toPosition + 1),
        ];
      else
        updatedUnassignedSolutions = [
          ...unassignedSolutions.slice(0, toPosition),
          solution,
          ...unassignedSolutions.slice(toPosition, fromPosition),
          ...unassignedSolutions.slice(fromPosition + 1),
        ];
    } else if (null === fromColumnId) {
      updatedUnassignedSolutions = [
        ...unassignedSolutions.slice(0, fromPosition),
        ...unassignedSolutions.slice(fromPosition + 1),
      ];
    } else if (null === toColumnId) {
      updatedUnassignedSolutions = [
        ...unassignedSolutions.slice(0, toPosition),
        solution,
        ...unassignedSolutions.slice(toPosition),
      ];
    }

    setBoard(
      createBoard({
        unassignedSolutions: updatedUnassignedSolutions,
        solutions: updatedSolutions,
      }),
    );
    setSolutions(updatedSolutions);
    setUnassignedSolutions(updatedUnassignedSolutions);

    const fromSolution =
      solutions.find((t) => t.id === fromColumnId) || "nezařazeno";
    const toSolution =
      solutions.find((t) => t.id === toColumnId) || "nezařazeno";

    if (fromColumnId === null) {
      console.log(`Add "${toSolution.name}" to solution "${solution.code}"`);
      onAddAssociation({
        id: id,
        solution_id: solution.solution_id,
        association_id: toColumnId,
      });
    } else if (
      toColumnId === null ||
      solutions
        .find(({ id }) => id === toColumnId)
        .solutions.some(
          ({ solution_id }) => solution_id === solution.solution_id,
        )
    ) {
      console.log(
        `Remove "${fromSolution.name}" from solution "${solution.code}"`,
      );
      onRemoveAssociation({
        solution_id: solution.solution_id,
        association_id: fromColumnId,
      });
    } else {
      console.log(
        `Change from "${fromSolution.name}" to ${toSolution.name} for solution  "${solution.code}"`,
      );
      onChangeAssociation({
        solution_id: solution.solution_id,
        from_id: fromColumnId,
        to_id: toColumnId,
      });
    }
  };

  const onCardClick = (_, { solution }) => {
    setSolution(solution);
  };

  const colorize = (solution: Solution, dragging: boolean) => {
    if (dragging) return "grey";
    if (!solution) return "grey";
    if ((solution.code || solution.solution) && solution.instructions)
      return "green";
    if (solution.code || solution.solution || solution.instructions)
      return "yellow";
    return "red";
  };

  return (
    <>
      <ModalSolution
        solution={solution}
        setSolution={setSolution}
        onChange={onSolutionChange}
      />
      <Menu attached>
        <PuzzleBoardNavigation />

        <Menu.Item fitted="vertically">
          <Select
            className="select"
            closeMenuOnSelect={false}
            onChange={setSelectedSolutions}
            value={selectedSolutions}
            options={solutions.map(({ id, title }) => {
              return { value: id, label: title };
            })}
            isMulti
            isSearchable
          />
        </Menu.Item>

        {auth.token && (
          <Menu.Item position="right">
            <Checkbox
              toggle
              label="Povolit úpravy"
              onChange={(e, data) => setAllowEdit(data.checked)}
              checked={allowEdit}
            />
          </Menu.Item>
        )}
        <Menu.Item position={auth?.token ? null : "right"}>
          <Checkbox
            toggle
            label="Zobrazit náhledy"
            onChange={(e, data) => setShowPreviews(data.checked)}
            checked={showPreviews}
          />
        </Menu.Item>
      </Menu>
      <Board
        className="ui cards"
        onCardDragEnd={onCardDragEnd}
        renderCard={(solution, { dragging }) => (
          <SolutionCard
            key={solution.id}
            solution={solution}
            onClick={onCardClick}
            color={colorize(solution, dragging)}
            showPreview={showPreviews}
          />
        )}
        renderColumnHeader={(column) => <ColumnHeader {...column} />}
      >
        {board}
      </Board>
    </>
  );
}
