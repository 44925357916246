import { NavLink } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";

import "@asseinfo/react-kanban/dist/styles.css";

export default function PuzzleBoardNavigation() {
  return (
    <>
      <Menu.Item as={NavLink} icon name="problems" to="../zadani">
        <Icon name="image" color="black" />
      </Menu.Item>
      <Menu.Item as={NavLink} icon name="puzzles" to="../sifry">
        <Icon name="puzzle" color="black" />
      </Menu.Item>
      <Menu.Item as={NavLink} icon name="authors" to="../autori">
        <Icon name="user" color="black" />
      </Menu.Item>
      <Menu.Item as={NavLink} icon name="puzzle-tags" to="../tagy">
        <Icon.Group>
          <Icon name="tag" />
          <Icon corner="top right" name="puzzle" />
        </Icon.Group>
      </Menu.Item>
      <Menu.Item as={NavLink} icon name="solution-tags" to="../reseni">
        <Icon.Group>
          <Icon name="tag" />
          <Icon corner="top right" name="lightbulb" />
        </Icon.Group>
      </Menu.Item>
    </>
  );
}
