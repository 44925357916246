import { Popup, Image } from "semantic-ui-react";

import DataGridCollectionColumn from "../..//DataGridCollectionColumn";
import { Location, Solution } from "../../interface";
import PuzzleDecorator from "../../models/PuzzleDecorator";

export const PuzzleSolutionColumn = (
  <DataGridCollectionColumn
    name="puzzle_solutions"
    title="Šifra"
    type="collection"
    frozen={true}
    width={1}
    select={{
      puzzle_solutions: [
        "puzzle_solution_id",
        {
          puzzles: [
            "puzzle_id",
            "code",
            "position",
            "tag",
            "name",
            { "...puzzle_checksums": ["problem_checksum"] },
          ],
        },
      ],
    }}
    compare={(a: Location, b: Location) => a.name.localeCompare(b.name)}
    renderItem={([, puzzle]) => puzzle.name}
    join={(arr) => arr.sort().join(", ")}
    getItems={(solution: Solution) =>
      solution.puzzle_solutions
        ? solution.puzzle_solutions.map((pp) => [
            pp.puzzles.puzzle_id,
            pp.puzzles,
          ])
        : []
    }
    render={(solution: Solution, game) => {
      if (!solution.puzzle_solutions) return [];
      return solution.puzzle_solutions
        .filter((pp) => pp.puzzles)
        .map((pp) => {
          const puzzle = new PuzzleDecorator(pp.puzzles);
          return (
            <Popup
              key={pp.puzzle_solution_id}
              trigger={<a className="puzzle-link">{puzzle.name()}</a>}
            >
              <Popup.Header>{puzzle.name()}</Popup.Header>
              <Popup.Content>
                <Image
                  src={`https://statek.seslost.cz/${game.name}/sifry/${pp.puzzles.slug}.png?style=thumb`}
                />
              </Popup.Content>
            </Popup>
          );
        });
    }}
  />
);
