import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { Button, Image, List, Modal } from "semantic-ui-react";

import { AuthenticationContext } from "../AuthenticationContext";
import { EditContext } from "../EditContext";
import { GameContext } from "../GameContext";
import { q } from "../Query";
import { api, clear, handleData } from "../api";

export default function ModalConnectionCard({ connection, setConnection }) {
  const handleError = useErrorHandler();
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);
  const [allowEdit, setAllowEdit] = React.useContext(EditContext);
  const open = connection.data !== null;

  React.useEffect(() => {
    if (!game) return;
    if (!connection.didInvalidate || connection.isLoading) return;
    if (!connection.data) return;

    api(auth.token, "puzzles")
      .from("connections")
      .select(
        q([
          "connection_id",
          "tag",
          "specification",
          {
            interpretations: {
              interpretation_solutions: {
                solutions: [
                  ["solution_id", "code", "solution"],
                  {
                    puzzle_solutions: {
                      puzzles: ["puzzle_id", "position", "tag", "name"],
                    },
                  },
                ],
              },
            },
            location_papers: [
              "location_paper_id",
              {
                ["locations!location_papers_location_id_fkey"]: [
                  "location_id",
                  "name",
                ],
                location_paper_photos: {
                  photos: ["photo_id", "description"],
                },
              },
            ],
          },
        ])
      )
      .eq("connection_id", connection.data.connection_id)
      .single()
      .then(handleData(handleError, setConnection), handleError);
  }, [game, open, connection]);

  const photo = React.useMemo(
    () =>
      connection.data?.location_papers?.location_paper_photos?.map(
        ({ photos }) => photos
      )[0],
    [connection]
  );
  const handleClose = () => clear(setConnection);

  return (
    <Modal
      open={!!connection.data}
      onClose={handleClose}
      className="connection"
    >
      <Modal.Header>
        Upřesnítko
        <Button
          floated="right"
          icon="edit"
          active={allowEdit}
          onClick={() => setAllowEdit(true)}
        />
      </Modal.Header>
      <Modal.Content image>
        {photo ? (
          <Image
            inline
            wrapped
            bordered
            size="medium"
            src={
              `https://trakar.seslost.cz/${game.name}/fotky/${photo.photo_id}.png?style=medium`
            }
          />
        ) : (
          <Image wrapped inline size="medium" bordered />
        )}
        <Modal.Description>
          <List>
            <List.Item>
              <List.Icon name="puzzle" />
              <List.Content>
                <List.Header>Šifra</List.Header>
                <List.Description>
                  {connection.data?.interpretations?.interpretation_solutions
                    .map(({ solutions }) => solutions)
                    .flatMap(({ puzzle_solutions }) => puzzle_solutions)
                    .map(({ puzzles }) => puzzles.name)
                    .join(", ")}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="check" />
              <List.Content>
                <List.Header>Řešení</List.Header>
                <List.Description>
                  {connection.data?.interpretations?.interpretation_solutions
                    .map(({ solutions }) => solutions.solution)
                    .join(", ")}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="arrow right" />
              <List.Content>
                <List.Header>Upřesnítko</List.Header>
                <List.Description>
                  {connection.data?.specification}
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="marker" />
              <List.Content>
                <List.Header>Poloha</List.Header>
                <List.Description>
                  {connection.data?.location_papers?.locations?.name}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}
