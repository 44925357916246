import { Game, Solution } from "../interface";

export default class SolutionDecorator {
  solution: Solution;

  constructor(solution: Solution) {
    this.solution = solution;
  }

  imageUrl(game: Game) {
    if (this.hasImage())
      return `https://statek.seslost.cz/${game.name}/reseni/${this.solution.solution_id}.pdf`;
    else return null;
  }

  imagePreviewUrl(game: Game) {
    if (this.hasImage())
      return `https://statek.seslost.cz/${game.name}/reseni/${this.solution.solution_id}.png?style=preview`;
    else return null;
  }

  hasImage(): boolean {
    return !!this.solution?.image_checksum;
  }
}
