
import { useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import { Menu } from "semantic-ui-react";
import { api, handleData, handleResponse, invalidate } from "./api";
import { AuthenticationContext } from "./AuthenticationContext";
import { Game } from "./interface";
import { q } from "./Query";


const specialLabels: readonly any[] = [
  {
    type: "special",
    value: "registration",
    label: "Probíhá regitrace",
  },
  {
    type: "special",
    value: "future",
    label: "Budoucí",
  },
  {
    type: "special",
    value: "past",
    label: "Minulé",
  }
]

const yearLabels = Array(new Date().getFullYear() - 2000 + 3).fill(0)
  .map((_, i) => 2000 + i)
  .reverse()
  .map(year => ({
    type: "year",
    value: year,
    label: year,
  }))

const initialLabels: readonly any[] = yearLabels.filter(({ value }) => value === new Date().getFullYear())


function GamesSearchBox({ games, setGames }): JSX.Element {
  const [search, setSearch] = useSearchParams();

  const labelsFromParams = search
    .getAll("labels")
    .flatMap((t) => t.split(","));


  const [labels, setLabels] = useState(specialLabels.concat(yearLabels));

  const defaultLabels =
    labelsFromParams && labelsFromParams.length > 0
      ? labels.filter((c) => labelsFromParams.some((value) => value === c.value.toString()))
      : initialLabels
  const [selectedLabels, setSelectedLabels] = useState(defaultLabels);

  const handleError = useErrorHandler();
  const [auth] = useContext(AuthenticationContext);

  useEffect(() => {
    if (!games.didInvalidate) return;
    if (games.isFetching) return;
    let scope = api(auth.token, "games")
      .from("games")
      .select(
        q([
          "game_id",
          "game_series_id",
          "title",
          "name",
          "city",
          "start",
          "end",
          "start_precision",
          "volume",
          "web_pages",
          "registration_start",
          "registration_end",
          { "game_series": ["game_series_id", "name", "slug", { "...game_series_checksums": ["logo_checksum"] }] },
          { "...game_checksums": ["logo_checksum"] },
        ]),
      )
      .eq("show_in_calendar", true)
      .order('start', { ascending: false })

    const withOpenRegistration = selectedLabels.find(({ type, value }) => type === "special" && value === "registration")
    if (withOpenRegistration) {
      scope = scope
        .lt("registration_start", 'now')
        .gt("registration_end", 'now')
        .or('active.eq.false,enable_web.eq.true')
    }
    const future = selectedLabels.find(({ type, value }) => type === "special" && value === "future")
    if (future) {
      scope = scope.gt("start", 'now')
    }
    const past = selectedLabels.find(({ type, value }) => type === "special" && value === "past")
    if (past) {
      scope = scope.lt("start", 'now')
    }
    {
      const years = selectedLabels
        .filter(({ type }) => type === "year")
        .map(({ value }) => `and(start.gte.${value}-01-01,start.lte.${value}-12-31)`)
      if (years.length > 0) {
        scope = scope.or(years.join(','))
      }
    }

    {
      const cities = selectedLabels
        .filter(({ type }) => type === "city")
        .map(({ value }) => `city.eq.${value}`)
      if (cities.length > 0) {
        scope = scope.or(cities.join(','))
      }
    }

    {
      const gameSeries = selectedLabels
        .filter(({ type }) => type === "game_series")
        .map(({ value }) => `game_series_id.eq.${value}`)
      if (gameSeries.length > 0) {
        scope = scope.or(gameSeries.join(','))
      }
    }

    scope
      .order("end", { ascending: false })
      .then(handleData(handleError, setGames), handleError);
  }, [auth, games, selectedLabels, setGames, handleError]);

  // Cities
  useEffect(() => {
    api(auth.token, "games")
      .from("games")
      .select(
        q([
          "city",
          "count()",
        ]),
      )
      .eq("show_in_calendar", true)
      .neq("city", null)
      .order("count", { ascending: false })
      .then(handleResponse(handleError, (games: Game[]) => {
        const cityLabels = games.map(({ city }) => ({
          type: "city",
          value: city,
          label: city,
        }))
        setLabels((labels) => labels.concat(cityLabels))
      }), handleError);
  }, [auth, setLabels, handleError])

  // Game series
  useEffect(() => {
    api(auth.token, "games")
      .from("game_series")
      .select(
        q([
          "game_series_id",
          "name",
        ]),
      )
      .then(handleResponse(handleError, (gameSeries) => {
        const cityLabels = gameSeries.map(({ game_series_id, name }) => ({
          type: "game_series",
          value: game_series_id,
          label: name,
        }))
        setLabels((labels) => labels.concat(cityLabels))
      }), handleError);
  }, [auth, setLabels, handleError])


  useEffect(() => {
    invalidate(setGames);
  }, [setGames, selectedLabels])

  useEffect(() => {
    if (labels.length === 0) return;
    if (selectedLabels === null) return;
    if (selectedLabels && selectedLabels.length > 0)
      search.set("labels", selectedLabels.map(({ value }) => value).join(","));
    else search.delete("labels");
    setSearch(search);
  }, [games, labels, selectedLabels, search, setSearch]);

  return (
    <Menu fixed="top">
      <Select
        className="select"
        closeMenuOnSelect={false}
        onChange={setSelectedLabels}
        value={selectedLabels}
        options={labels}
        isMulti
        isSearchable
        placeholder="Hledat…"
      />
    </Menu>
  )
}

export default GamesSearchBox;
