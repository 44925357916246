import DataGridColumn from "../..//DataGridColumn";
import { Puzzle } from "../../interface";

export const LabelColumn = (
  <DataGridColumn
    name="position"
    title="Číslo"
    frozen={true}
    type="number"
    width={1}
    select={["position", "tag"]}
    compare={(a: Puzzle, b: Puzzle) =>
      a.position - b.position || (a.tag ? a.tag.localeCompare(b.tag) : -1)
    }
    render={({ position, tag }) => [position, tag || ""].join("")}
  />
);
