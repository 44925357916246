import React from "react";
import Select from "react-select";
import { useSearchParams } from "react-router-dom";
import { Loader, MenuItem, TabPane } from "semantic-ui-react";

import "./DataGrid.css";
import PaperDataGrid from "./data-grid/PaperDataGrid";
import PuzzleDataGrid from "./data-grid/PuzzleDataGrid";
import SolutionDataGrid from "./data-grid/SolutionDataGrid";
import ConnectionDataGrid from "./data-grid/ConnectionDataGrid";

export default function DataGridBoard() {
  const [search, setSearch] = useSearchParams();

  const panelOptions = [
    { label: "Šifry", value: "puzzles", icon: "puzzle" },
    { label: "Zadání", value: "papers", icon: "file outline" },
    { label: "Řešení", value: "solutions", icon: "lightbulb" },
    { label: "Upřesnítka", value: "connections", icon: "arrow right" }
  ];

  const viewFromParams = search.get("table");
  const defaultView = panelOptions.find(
    ({ value }) => value === (viewFromParams || "puzzles")
  );
  const [viewKey, setViewKey] = React.useState(defaultView);

  React.useEffect(() => {
    if (viewKey) search.set("table", viewKey.value);
    else search.delete("table");
    setSearch(search);

    return () => search.delete("table");
  }, [viewKey]);


  const viewSelect = panelOptions.map((opt) => (
    <MenuItem
      active={opt.value === viewKey.value}
      content={opt.label}
      icon={opt.icon}
      onClick={() => setViewKey(opt)}
    />
  ));

  ;

  const panels = new Map([
    [
      "papers",
      <PaperDataGrid
        viewSelect={viewSelect}
      />
    ],
    [
      "puzzles",
      <PuzzleDataGrid
        viewSelect={viewSelect}
      />
    ],
    [
      "solutions",
      <SolutionDataGrid
        viewSelect={viewSelect}
      />
    ],
    [
      "connections",
      <ConnectionDataGrid
        viewSelect={viewSelect}
      />
    ]
  ]);

  return panels.has(viewKey.value) ? panels.get(viewKey.value) : <Loader />;
}
