import { Label } from "semantic-ui-react";

import { colorize } from "./colors";

export default function TagGroup({
  taggings,
  expanded = true,
  setExpanded = null
}) {
  if (!taggings) return null;

  const onClick = e => {
    setExpanded(!expanded);
    e.stopPropagation();
  };
  return (
    <Label.Group size="mini">
      {taggings.map(({ id, tags }) => (
        <Label
          key={id}
          color={colorize(tags.tag_id)}
          title={tags.name}
          onClick={onClick}
        >
          {expanded && tags.name}
        </Label>
      ))}
    </Label.Group>
  );
}
