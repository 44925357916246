import DataGridCollectionColumn from "../..//DataGridCollectionColumn";
import { Puzzle } from "../../interface";

export const PaperPuzzleColumn = (
  <DataGridCollectionColumn
    name="paper_puzzles"
    title="Zadání"
    type="collection"
    frozen={true}
    width={1}
    select={{
      paper_puzzles: [
        "paper_puzzle_id",
        {
          papers: ["paper_id", "code", "game_id"]
        }
      ]
    }}
    compare={(a: Puzzle, b: Puzzle) => a.position - b.position}
    renderItem={([, paper]) => paper.code}
    join={arr => arr.sort().join(", ")}
    getItems={(puzzle: Puzzle) =>
      puzzle.paper_puzzles
        ? puzzle.paper_puzzles
            .filter(pp => pp.papers)
            .map(pp => [pp.papers.paper_id, pp.papers])
        : []
    }
  />
);
