import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { Button, TextArea, Form, Modal } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";

import { q } from "../Query";
import { parameterize } from "../StringUtils";
import { api, handleResponse } from "../api";
import { AuthenticationContext } from "../AuthenticationContext";
import { GameContext } from "../GameContext";
import { EditContext } from "../EditContext";

export default function ModalStageeForm({
  stage,
  stages,
  open,
  setStage,
  onAdd,
  onChange,
}): JSX.Element {
  const { register, unregister, handleSubmit, control } = useForm();

  const { position, title, description } = stage || {};
  const handleError = useErrorHandler();
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);
  const [allowEdit, setAllowEdit] = React.useContext(EditContext);

  React.useEffect(() => {
    if (!game) return;
    if (!stage) return;
    if (!stage.stage_id) return;

    api(auth.token, "puzzles")
      .from("stages")
      .select(
        q(["stage_id", "game_id", "name", "title", "position", "description"])
      )
      .eq("stage_id", stage.stage_id)
      .single()
      .then(handleResponse(handleError, setStage), handleError);
  }, [game, open, allowEdit]);

  React.useEffect(() => {
    if (!open) unregister(["title", "description"]);
  }, [open]);

  const onSubmit = data => {
    if (!stage) return;

    const record = { ...stage, ...data };

    if (record.stage_id !== undefined) {
      api(auth.token, "puzzles")
        .from("stages")
        .update(data)
        .match({ stage_id: stage.stage_id })
        .then(
          handleResponse(handleError, () => {
            onChange();
            setStage(null);
          }),
          handleError
        );
    } else {
      record.name = parameterize(record.title);
      record.position =
        stages.length > 0
          ? Math.max(...stages.map(({ position }) => position)) + 1
          : 1;
      api(auth.token, "puzzles")
        .from("stages")
        .insert(record)
        .single()
        .then(
          handleResponse(handleError, data => {
            onAdd(data);
            setStage(null);
          }),
          error => handleError(error)
        );
    }
  };

  const onDestroy = event => {
    event.preventDefault();

    if (!stage.stage_id) return;
    api(auth.token, "puzzles")
      .from("stages")
      .delete()
      .match({ stage_id: stage.stage_id })
      .then(
        handleResponse(handleError, () => {
          onChange();
          setStage(null);
        }),
        error => handleError(error)
      );
    return null;
  };

  return (
    <Modal
      open={open}
      onClose={() => setStage(null)}
      as={Form}
      size="large"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Modal.Header>
        Část hry {[position, title].filter(o => o).join(" – ")}
        {setAllowEdit && (
          <Button
            floated="right"
            icon="edit"
            active={allowEdit}
            disabled={!auth?.token}
            onClick={() => setAllowEdit(false)}
          />
        )}
        <Button floated="right" icon="trash" onClick={onDestroy} />
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <Form.Group inline>
            <Form.Field />
          </Form.Group>
          <Form.Field>
            <label htmlFor="title">Název</label>
            <input {...register("title")} defaultValue={title} />
          </Form.Field>
          {description !== undefined && (
            <Form.Field>
              <label htmlFor="description">Popis</label>
              <Controller
                name="description"
                control={control}
                defaultValue={description}
                render={({ field }) => <TextArea {...field} />}
              />
            </Form.Field>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button type="submit" primary>
          Uložit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
