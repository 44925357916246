import dagre from "dagre";
import { useCallback, useRef, useState } from "react";
import Select from "react-select";
import ReactFlow, {
  Background,
  Controls,
  MiniMap,
  NodeToolbar,
  addEdge,
  useEdgesState,
  useNodesState,
  Node,
  Edge,
  Position,
} from "reactflow";
import "reactflow/dist/style.css";
import { Button, Checkbox, Menu } from "semantic-ui-react";

import { AuthenticationContext } from "./AuthenticationContext";
import { GameContext } from "./GameContext";
import { q } from "./Query";
import {
  api,
  handleData,
  handleResponse,
  initialState,
  invalidate,
  request,
} from "./api";

import { EditContext } from "./EditContext";
import {
  AbsoluteHint,
  AbsoluteHintLocationPaper,
  Connection,
  Interpretation,
  Location,
  LocationPaper,
  Paper,
  Puzzle,
  Solution,
} from "./interface";
import React from "react";
import { useErrorHandler } from "react-error-boundary";

import "./GraphBoard.css";
import NodePanel from "./NodePanel";
import LocationPaperPanel from "./LocationPaper";
import ConnectionPanel from "./ConnectionPanel";
import AbsoluteHintLocationPaperPanel from "./AbsoluteHintLocationPaperPanel";
import LocationPaperNode from "./LocationPaperNode";
import { clear } from "console";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

const getLayoutedElements = (nodes, edges, direction = "TB") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node: Node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge: Edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node: Node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? Position.Left : Position.Top;
    node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

const initialLayout = "TB";

const labelerTypes = [
  {
    label: "Šifry",
    value: "puzzle",
    icon: "puzzle",
  },
  {
    label: "Zadání",
    value: "paper",
    icon: "file outline",
  },
  {
    label: "Umístnění",
    value: "location",
    icon: "marker",
  },
];

const labelers = {
  paper: ({ papers }) => papers.code,
  location: ({ locations }) => locations?.name,
  puzzle: (locationPaper) =>
    locationPaper.papers?.paper_puzzles
      .map(({ puzzles }) => puzzles)
      .map(({ position, label, name }) => `${position}${label || ""} — ${name}`)
      .join(", "),
};

function Flow() {
  const game = React.useContext(GameContext);
  const [auth] = React.useContext(AuthenticationContext);
  const handleError = useErrorHandler();

  const [layout, setLayout] = React.useState(initialLayout);
  const [stage, setStage] = React.useState(null);
  const [stages, setStages] = React.useState(initialState);
  const [locationPaper, setLocationPaper] = React.useState(null);
  const [locationPapers, setLocationPapers] = React.useState(initialState);
  const [connection, setConnection] = React.useState(null);
  const [absoluteHintLocationPaper, setAbsoluteHintLocationPaper] = React.useState(null);
  const [connections, setConnections] = React.useState(initialState);
  const [absoluteHintLocationPapers, setAbsoluteHintLocationPapers] = React.useState(initialState);
  const [allowEdit, setAllowEdit] = React.useContext(EditContext);
  const [labeler, setLabeler] = React.useState("puzzle");
  const [openPanel, setOpenPanel] = React.useState(false);

  const nodeTypes = React.useMemo(() => ({ default: LocationPaperNode }), []);

  // Load stages
  React.useEffect(() => {
    if (!game) return;
    if (!stages.didInvalidate) return;
    if (stages.isFetching) return;
    api(auth?.token, "puzzles")
      .from("stages")
      .select(q(["stage_id", "game_id", "position", "name", "title"]))
      .order("position")
      .eq("game_id", game.game_id)
      .then(handleData(handleError, setStages), handleError);
  }, [auth, game, stages]);

  // Load locations
  React.useEffect(() => {
    if (!game) return;
    if (!locationPapers.didInvalidate) return;
    if (locationPapers.isFetching) return;
    if (auth.isFetching) return;

    request(setLocationPapers);
    api(auth?.token, "puzzles")
      .from("location_papers")
      .select(
        q([
          "location_paper_id",
          {
            "locations!location_papers_location_id_fkey": [
              "location_id",
              "name",
              {
                photos: ["photo_id", "description"],
              },
            ],
            "papers!location_papers_paper_id_fkey!inner": [
              "paper_id",
              "code",
              "final",
              {
                paper_puzzles: [
                  "paper_puzzle_id",
                  {
                    puzzles: [
                      "puzzle_id",
                      "position",
                      "tag",
                      "description",
                      "name",
                      "slug",
                      { "...puzzle_checksums": ["problem_checksum"] },
                      {
                        puzzle_stages: [
                          "stage_id",
                          {
                            stages: "position",
                          },
                        ],
                        puzzle_solutions: [
                          {
                            solutions: [
                              "solution_id",
                              {
                                interpretation_solutions: [
                                  "interpretation_id",
                                  {
                                    interpretations: [
                                      "interpretation_id",
                                      "created_at",
                                    ],
                                  },
                                ],
                                absolute_hint_solutions: [
                                  "absolute_hint_id",
                                  {
                                    absolute_hints: [
                                      "absolute_hint_id",
                                      "created_at",
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ]),
      )
      .eq("game_id", game.game_id)
      .then(handleData(handleError, setLocationPapers), handleError);
  }, [auth, game, locationPapers]);

  // Load connections
  React.useEffect(() => {
    if (!game) return;
    if (!connections.didInvalidate) return;
    if (connections.isFetching) return;
    request(setConnections);
    api(auth?.token, "puzzles")
      .from("connections")
      .select(
        q([
          "connection_id",
          "interpretation_id",
          {
            "location_papers!inner": [
              "location_paper_id",
              {
                "locations!location_papers_location_id_fkey!inner": [
                  "location_id",
                  "name",
                ],
              },
              {
                "papers!inner": [
                  "paper_id",
                  "code",
                  "final",
                  { "paper_puzzles": { "puzzles": ["puzzle_id", "name", "tag", "position"] } }
                ],
              },
            ],
            "interpretations!inner": ["interpretation_id", {
              "interpretation_solutions!inner": {
                "solutions!inner": ["solution_id", "code", "solution", {
                  "puzzle_solutions!inner": [{
                    "puzzles!inner": [
                      "puzzle_id", "name", "tag", "position",
                      {
                        "paper_puzzles!inner": {
                          "papers!inner": {
                            "location_papers!inner": [
                              "location_paper_id",
                              {
                                "locations!location_papers_location_id_fkey":
                                  ["location_id"],
                              },
                            ],
                          },
                        },
                      }],
                  }],
                }],
              },
            }],
          },
        ]),
      )
      .eq("game_id", game.game_id)
      .then(handleData(handleError, setConnections), handleError)
      .then(() => setConnection(connection));
  }, [game, auth, connections]);

  // Load absolute hints
  React.useEffect(() => {
    if (!game) return;
    if (!absoluteHintLocationPapers.didInvalidate) return;
    if (absoluteHintLocationPapers.isFetching) return;
    request(setAbsoluteHintLocationPapers);
    api(auth?.token, "puzzles")
      .from("absolute_hint_location_papers")
      .select(
        q([
          "absolute_hint_location_paper_id",
          "location_paper_id",
          "absolute_hint_id",
          {
            "location_papers!inner": [
              "location_paper_id",
              {
                "locations!location_papers_location_id_fkey": [
                  "location_id",
                  "name",
                ],
              },
              {
                "papers!inner": [
                  "paper_id",
                  "code",
                  "final",
                  { "paper_puzzles": { "puzzles": ["puzzle_id", "name", "tag", "position"] } }
                ],
              },
            ],
            "absolute_hints!inner": {
              "absolute_hint_solutions!inner": {
                "solutions!inner": ["solution_id", "code", "solution", {
                  "puzzle_solutions!inner": {
                    "puzzles!inner": [
                      "puzzle_id", "name", "tag", "position",
                      {
                        "paper_puzzles!inner": {
                          "papers!inner": {
                            "location_papers!inner": [
                              "location_paper_id",
                              {
                                "locations!location_papers_location_id_fkey":
                                  ["location_id"],
                              },
                            ],
                          },
                        },
                      }],

                  },
                }],
              },
            },
          },
        ]),
      )
      .eq("game_id", game.game_id)
      .then(
        handleData(handleError, setAbsoluteHintLocationPapers),
        handleError,
      );
  }, [game, auth, absoluteHintLocationPapers]);

  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const createLocationPaperNode = React.useCallback(
    (locationPaper, attributes = {}) => {
      return {
        id: locationPaper.location_paper_id,
        type: "default",
        data: {
          // color: "#1A192B",
          label: labelers[labeler](locationPaper),
          locationPaper: locationPaper,
        },
        // style: { border: "1px solid red", padding: 10 },
        position: { x: locationPaper.x, y: locationPaper.y },
        ...attributes,
      };
    },
    [labelers, labeler],
  );

  React.useEffect(() => {
    if (
      locationPapers.didInvalidate ||
      locationPapers.isFetching ||
      !locationPapers.data
    )
      return null;
    if (
      connections.didInvalidate ||
      connections.isFetching ||
      !connections.data
    )
      return null;
    if (
      absoluteHintLocationPapers.didInvalidate ||
      absoluteHintLocationPapers.isFetching ||
      !absoluteHintLocationPapers.data
    )
      return null;

    const filteredLocationPapers = stage
      ? locationPapers.data.filter(({ papers }) =>
        papers.paper_puzzles.some(({ puzzles }) =>
          puzzles.puzzle_stages.some(
            ({ stage_id }) => stage_id === stage.stage_id,
          ),
        ),
      )
      : locationPapers.data;

    const nodes = filteredLocationPapers.map(createLocationPaperNode);
    const selectedConnection = connection

    const connectionEdges = connections.data.flatMap(
      (connection: Connection) => {
        const sources: LocationPaper[] = Object.values(
          connection.interpretations.interpretation_solutions.flatMap((is) =>
            is.solutions.puzzle_solutions.flatMap((ps) =>
              ps.puzzles.paper_puzzles.flatMap(
                (pp) => pp.papers.location_papers,
              ),
            ),
          ).reduce((acc, location_paper) => {
            acc[location_paper.location_paper_id] = location_paper
            return acc;
          }, {}))

        const target = connection.location_papers;
        return sources
          .map(({ location_paper_id }) => {
            return {
              id: `${location_paper_id}--${connection.connection_id}`,
              kind: 'connection',
              connection_id: connection.connection_id,
              source: location_paper_id,
              target: target.location_paper_id,
              selected: selectedConnection?.connection_id === connection.connection_id,
              sourceHandle: connection.interpretation_id,
            };
          });

      },
    );

    const absoluteHintLocationPaperEdges =
      absoluteHintLocationPapers.data.flatMap(
        (absoluteHintLocationPaper: AbsoluteHintLocationPaper) => {
          const sources =
            absoluteHintLocationPaper.absolute_hints.absolute_hint_solutions.flatMap(
              (is) =>
                is.solutions.puzzle_solutions.flatMap((ps) =>
                  ps.puzzles.paper_puzzles.flatMap(
                    (pp) => pp.papers.location_papers,
                  ),
                ),
            );
          const target = absoluteHintLocationPaper.location_paper_id;
          console.log('target', target);
          
          return sources
            .filter((o) => o)
            .map((source) => {
              return {
                id: `${source.location_paper_id}--${absoluteHintLocationPaper.absolute_hint_location_paper_id}`,
                kind: 'absolute_hint_location_paper',
                absolute_hint_location_paper_id: absoluteHintLocationPaper.absolute_hint_location_paper_id,
                source: source.location_paper_id,
                target: target,
                sourceHandle: absoluteHintLocationPaper.absolute_hint_id,
                style: { stroke: "#f00" },
              };
            });
        },
      );

    const edges = connectionEdges.concat(absoluteHintLocationPaperEdges);

    // .filter((e) => nodes.some(({ data: { id } }) => id === e.data.source))
    // .filter((e) => nodes.some(({ data: { id } }) => id === e.data.target));

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      nodes,
      edges,
      layout,
    );

    setNodes([...layoutedNodes]);
    setEdges([...layoutedEdges]);
  }, [locationPapers, connections, absoluteHintLocationPapers, stage, layout, labeler]);

  const onConnect = useCallback(
    (params) => {

      const interpretation = locationPapers.data
        .map(({ papers }) => papers)
        .flatMap(({ paper_puzzles }) => paper_puzzles)
        .flatMap(({ puzzles }) => puzzles)
        .flatMap(({ puzzle_solutions }) => puzzle_solutions)
        .map(({ solutions }) => solutions)
        .flatMap(({ interpretation_solutions }) => interpretation_solutions)
        .map(({ interpretations }) => interpretations)
        .find(({ interpretation_id }) => interpretation_id == params.sourceHandle)

      if (interpretation) {
        setEdges((eds) => addEdge({
          ...params,
          kind: 'connection',
          animated: true
        }, eds));
        api(auth?.token, "puzzles")
          .from("connections")
          .insert({
            game_id: game.game_id,
            interpretation_id: params.sourceHandle,
            location_paper_id: params.target,
          })
          .single()
          .then(handleResponse(handleError, (connection: Connection) => {
            setConnection(connection)
            invalidate(setConnections)
          }),
            handleError,
          )
      }

      const absoluteHint = locationPapers.data
        .map(({ papers }) => papers)
        .flatMap(({ paper_puzzles }) => paper_puzzles)
        .flatMap(({ puzzles }) => puzzles)
        .flatMap(({ puzzle_solutions }) => puzzle_solutions)
        .map(({ solutions }) => solutions)
        .flatMap(({ absolute_hint_solutions }) => absolute_hint_solutions)
        .map(({ absolute_hints }) => absolute_hints)
        .find(({ absolute_hint_id }) => absolute_hint_id == params.sourceHandle)

      if (absoluteHint) {
        setEdges((eds) => addEdge({
          ...params,
          kind: 'absolute_hint_location_paper',
          animated: true
        }, eds));
        api(auth?.token, "puzzles")
          .from("absolute_hint_location_papers")
          .insert({
            game_id: game.game_id,
            absolute_hint_id: params.sourceHandle,
            location_paper_id: params.target,
          })
          .single()
          .then(handleResponse(handleError, (absolute_hint_location_papers: AbsoluteHintLocationPaper) => {
            setAbsoluteHintLocationPaper(absolute_hint_location_papers)
            invalidate(setAbsoluteHintLocationPapers)
          }),
            handleError,
          )
      }

    }, [game, auth, locationPapers]);
  const onSelectionChange = useCallback(
    ({ nodes, edges }) => {
      if (locationPapers.isFetching || locationPapers.didInvalidate) return;
      if (connections.isFetching || connections.didInvalidate) return;
      if (absoluteHintLocationPapers.isFetching || absoluteHintLocationPapers.didInvalidate) return;
      if (nodes.length === 1) {
        const node = nodes[0];
        const locationPaper = locationPapers?.data?.find(
          ({ location_paper_id }) => location_paper_id === node.id,
        );
        setLocationPaper(locationPaper);
        setConnection(null);
        setAbsoluteHintLocationPaper(null);
      } else if (edges.length === 1) {
        const edge = edges[0];

        switch (edge.kind) {
          case 'connection':
            const connection = connections?.data?.find(({ connection_id }) => connection_id === edge.connection_id);
            setConnection(connection);
            setLocationPaper(null);
            setAbsoluteHintLocationPaper(null);
            break;
          case 'absolute_hint_location_paper':
            const absoluteHintLocationPaper = absoluteHintLocationPapers?.data?.find(({ absolute_hint_location_paper_id }) => absolute_hint_location_paper_id === edge.absolute_hint_location_paper_id);
            setConnection(null);
            setLocationPaper(null);
            setAbsoluteHintLocationPaper(absoluteHintLocationPaper);
            break;
          default:
            console.log('Unknown edge type', edge)
        }

      } else {
        setConnection(null);
        setLocationPaper(null);
        setAbsoluteHintLocationPaper(null);
      }
    },
    [locationPapers, connections],
  );

  const onLayout = useCallback(
    (direction) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, direction);
      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges],
  );

  React.useEffect(() => onLayout(layout), [layout]);
  const onRefresh = () => {
    invalidate(setLocationPapers);
    invalidate(setConnections);
    invalidate(setStages);
  };

  const onSelectStage = (stage) => {
    if (stage)
      setStage(stages?.data?.find(({ stage_id }) => stage_id === stage.value));
    else setStage(null);
  };
  const stageOptions = stages?.data?.map(({ stage_id, title }) => {
    return { label: title, value: stage_id };
  });

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      onLocationPaperCreate({
        location_paper: {
          ...position,
        },
      });
    },
    [reactFlowInstance, game, auth, labeler],
  );

  const onPaneContextMenu = () => {
    console.log("onPaneContextMenu");
  };
  const onPaneClick = () => {
    console.log("onPaneClick");
  };


  const onConnectionDestroy = (connection: Connection) => {
    if (!connection.connection_id) return;
    api(auth?.token, "puzzles")
      .from("connections")
      .delete()
      .match({ connection_id: connection.connection_id })
      .then(
        handleResponse(handleError, () => {
          setConnection(null);
          invalidate(setConnections);
        }),
        handleError,
      );
  };

  const onAbsoluteHintLocationPaperDestroy = (absolutehintlocationpaper: AbsoluteHintLocationPaper) => {
    if (!absolutehintlocationpaper.absolute_hint_location_paper_id) return;
    api(auth?.token, "puzzles")
      .from("absolute_hint_location_papers")
      .delete()
      .match({ absolute_hint_location_paper_id: absoluteHintLocationPaper.absolute_hint_location_paper_id })
      .then(
        handleResponse(handleError, () => {
          setAbsoluteHintLocationPaper(null);
          invalidate(setAbsoluteHintLocationPapers);
        }),
        handleError,
      );
  };

  const onPuzzleDestroy = (puzzle: Puzzle) => {
    if (!puzzle.puzzle_id) return;
    api(auth?.token, "puzzles")
      .from("puzzles")
      .delete()
      .match({ puzzle_id: puzzle.puzzle_id })
      .then(
        handleResponse(handleError, invalidate(setLocationPapers)),
        handleError,
      );
  };

  const onPaperDestroy = (paper: Paper) => {
    if (!paper.paper_id) return;
    const promises = [];
    const deletePaper = api(auth?.token, "puzzles")
      .from("papers")
      .delete()
      .match({ paper_id: paper.paper_id })
      .then(handleResponse(handleError), handleError);
    promises.push(deletePaper);

    const puzzles = paper.paper_puzzles.map(({ puzzles }) => puzzles);
    if (puzzles.length > 0) {
      const deletePuzzles = api(auth?.token, "puzzles")
        .from("puzzles")
        .delete()
        .in(
          "puzzle_id",
          puzzles.map(({ puzzle_id }) => puzzle_id),
        )
        .limit(puzzles.length)
        .then(handleResponse(handleError, console.log), handleError);
      promises.push(deletePuzzles);
    }

    const solutions = puzzles.flatMap(({ puzzle_solutions }) =>
      puzzle_solutions.map(({ solutions }) => solutions),
    );
    if (solutions.length > 0) {
      const deleteSolutions = api(auth?.token, "puzzles")
        .from("solutions")
        .delete()
        .in(
          "solution_id",
          solutions.map(({ solution_id }) => solution_id),
        )
        .limit(solutions.length)
        .then(handleResponse(handleError, console.log), handleError);
      promises.push(deleteSolutions);
    }

    const interpretations = solutions.flatMap(({ interpretation_solutions }) =>
      interpretation_solutions.map(({ interpretations }) => interpretations),
    );
    if (interpretations.length > 0) {
      const deleteInterpretations = api(auth?.token, "puzzles")
        .from("interpretations")
        .delete()
        .in(
          "interpretation_id",
          interpretations.map(({ interpretation_id }) => interpretation_id),
        )
        .limit(interpretations.length)
        .then(handleResponse(handleError, console.log), handleError);
      promises.push(deleteInterpretations);
    }

    Promise.all(promises).then(() => {
      setLocationPaper(null);
      setConnection(null);
      invalidate(setConnections);
      invalidate(setLocationPapers);
    });
  };

  const onLocationPaperCreate = ({
    puzzle = {},
    paper = {},
    location_paper = {},
    solution = {},
    location = {},
    interpretation = {},
  }) => {
    if (!auth.token) return;
    console.log("location", location);
    const insertPaper = () =>
      api(auth.token, "puzzles")
        .from("papers")
        .insert({
          game_id: game.game_id,
          code: "placeholder",
          ...paper,
        })
        .single();

    const insertLocation = () =>
      api(auth.token, "puzzles")
        .from("locations")
        .insert({
          game_id: game.game_id,
          ...location,
        })
        .single();

    const insertLocationPaper = (paper: Paper, location: Location) =>
      api(auth.token, "puzzles")
        .from("location_papers")
        .insert({
          game_id: game.game_id,
          location_id: location.location_id,
          paper_id: paper.paper_id,
          ...location_paper,
        })
        .single();

    const insertPuzzle = () =>
      api(auth.token, "puzzles")
        .from("puzzles")
        .insert({
          game_id: game.game_id,
          position: 0,
          name: "šifra",
          ...puzzle,
        })
        .single();

    const insertPaperPuzzle = (paper: Paper, puzzle: Puzzle) =>
      api(auth.token, "puzzles")
        .from("paper_puzzles")
        .insert({
          game_id: game.game_id,
          paper_id: paper.paper_id,
          puzzle_id: puzzle.puzzle_id,
        })
        .single();

    const insertSolution = () =>
      api(auth.token, "puzzles")
        .from("solutions")
        .insert({
          game_id: game.game_id,
          ...solution,
        })
        .single();

    const insertPuzzleSolution = (puzzle: Puzzle, solution: Solution) =>
      api(auth.token, "puzzles")
        .from("puzzle_solutions")
        .insert({
          game_id: game.game_id,
          solution_id: solution.solution_id,
          puzzle_id: puzzle.puzzle_id,
        })
        .single();

    const insertInterpretation = () =>
      api(auth.token, "puzzles")
        .from("interpretations")
        .insert({
          game_id: game.game_id,
          ...interpretation,
        })
        .single();

    const insertInterpretationSolution = (
      interpretation: Interpretation,
      solution: Solution,
    ) =>
      api(auth.token, "puzzles")
        .from("interpretation_solutions")
        .insert({
          game_id: game.game_id,
          interpretation_id: interpretation.interpretation_id,
          solution_id: solution.solution_id,
        })
        .single();

    const insertConnection = (
      location_paper: LocationPaper,
      interpretation: Interpretation,
    ) =>
      api(auth.token, "puzzles")
        .from("connections")
        .insert({
          game_id: game.game_id,
          interpretation_id: interpretation.interpretation_id,
          location_paper_id: location_paper.location_paper_id,
        })
        .single();

    // const insertAbsoluteHint = () =>
    //   api(auth.token, "puzzles")
    //     .from("absolute_hints")
    //     .insert({
    //       game_id: game.game_id,
    //       ...absolute_hint,
    //     })
    //     .single();
    //
    // const insertAbsoluteHintSolution = (
    //   absolute_hint: AbsoluteHint,
    //   solution: Solution,
    // ) =>
    //   api(auth.token, "puzzles")
    //     .from("absolute_hint_solutions")
    //     .insert({
    //       game_id: game.game_id,
    //       absolute_hint_id: absolute_hint.absolute_hint_id,
    //       solution_id: solution.solution_id,
    //     })
    //     .single();
    //
    // const insertAbsoluteHintLocationPaper = (
    //   location_paper: LocationPaper,
    //   absolute_hint: AbsoluteHint,
    // ) =>
    //   api(auth.token, "puzzles")
    //     .from("absolute_hint_location_papers")
    //     .insert({
    //       game_id: game.game_id,
    //       absolute_hint_id: absolute_hint.absolute_hint_id,
    //       location_paper_id: location_paper.location_paper_id,
    //     })
    //     .single();

    const lastInterpretation = locationPapers.data
      .map(({ papers }) => papers)
      .flatMap(({ paper_puzzles }) => paper_puzzles)
      .flatMap(({ puzzles }) => puzzles)
      .flatMap(({ puzzle_solutions }) => puzzle_solutions)
      .map(({ solutions }) => solutions)
      .flatMap(({ interpretation_solutions }) => interpretation_solutions)
      .map(({ interpretations }) => interpretations)
      .sort((a: Interpretation, b: Interpretation) =>
        b.created_at.localeCompare(a.created_at),
      )[0];

    insertPaper().then(
      handleResponse(handleError, (paper: Paper) => {
        insertPuzzle().then(
          handleResponse(handleError, (puzzle: Puzzle) => {
            insertPaperPuzzle(paper, puzzle).then(
              handleResponse(handleError),
              handleError,
            );
            insertSolution().then(
              handleResponse(handleError, (solution: Solution) => {
                insertPuzzleSolution(puzzle, solution).then(
                  handleResponse(handleError),
                  handleError,
                );
                insertInterpretation().then(
                  handleResponse(
                    handleError,
                    (interpretation: Interpretation) => {
                      insertInterpretationSolution(
                        interpretation,
                        solution,
                      ).then(
                        handleResponse(handleError, () =>
                          invalidate(setLocationPapers),
                        ),
                        handleError,
                      );
                    },
                  ),
                  handleError,
                );
              }),
              handleError,
            );
          }),
          handleError,
        );

        insertLocation().then(
          handleResponse(handleError, (location: Location) => {
            insertLocationPaper(paper, location).then(
              handleResponse(handleError, (locationPaper: LocationPaper) => {
                if (lastInterpretation) {
                  insertConnection(locationPaper, lastInterpretation).then(
                    handleResponse(handleError, () => {
                      invalidate(setLocationPapers);
                      invalidate(setConnections);
                    }),
                    handleError,
                  );
                } else {
                  invalidate(setLocationPapers);
                }
              }),
              handleError,
            );
          }),
        );
      }),
      handleError,
    );
  };

  return (
    <>
      <Menu attached pointing secondary>
        <Menu.Item>
          <Checkbox
            toggle
            label={layout === "TB" ? "↕" : "↔"}
            onChange={(_e, data) => setLayout(data.checked ? "TB" : "LR")}
            checked={layout === "TB"}
          />
        </Menu.Item>
        {labelerTypes.map(({ label, value, icon }) => (
          <Menu.Item
            active={value === labeler}
            content={label}
            icon={icon}
            onClick={() => {
              setLocationPaper(null);
              setLabeler(value);
            }}
          />
        ))}
        {stages?.data?.length > 0 && (
          <Menu.Item>
            <Select
              onChange={onSelectStage}
              options={stageOptions}
              isClearable
            />
          </Menu.Item>
        )}

        {auth?.token && (
          <Menu.Item position="right">
            <Checkbox
              toggle
              label="Povolit úpravy"
              onChange={(_e, data) => setAllowEdit(data.checked)}
              checked={allowEdit}
            />
          </Menu.Item>
        )}
        <Menu.Item position={auth.token ? null : "right"}>
          <Button
            icon="refresh"
            loading={locationPapers.isFetching}
            onClick={onRefresh}
          />
        </Menu.Item>
      </Menu>

      <div className="container">
        <div id="graph" ref={reactFlowWrapper}>
          <ReactFlow
            onInit={setReactFlowInstance}
            nodes={nodes}
            onNodesChange={onNodesChange}
            edges={edges}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onSelectionChange={onSelectionChange}
            onPaneContextMenu={onPaneContextMenu}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onPaneClick={onPaneClick}
            nodeTypes={nodeTypes}
            fitView
            snapToGrid
          >
            <Background />
            <NodeToolbar />
            <MiniMap />
            <Controls />
          </ReactFlow>
        </div>
        {!locationPaper && !connection && auth.token && !openPanel && (
          <Button icon="triangle left" onClick={() => setOpenPanel(true)} />
        )}
        {!locationPaper && !connection && openPanel && (
          <NodePanel onCreate={onLocationPaperCreate} onClose={() => setOpenPanel(false)} />
        )}

        {locationPaper && (
          <LocationPaperPanel
            locationPaper={locationPaper}
            setLocationPaper={setLocationPaper}
            onPuzzleDestroy={onPuzzleDestroy}
            onPaperDestroy={onPaperDestroy}
          />
        )}

        {connection && (
          <ConnectionPanel
            connection={connection}
            setConnection={setConnection}
            onDestroy={onConnectionDestroy}
          />
        )}

        {absoluteHintLocationPaper && (
          <AbsoluteHintLocationPaperPanel
            absoluteHintLocationPaper={absoluteHintLocationPaper}
            setAbsoluteHintLocationPaper={setAbsoluteHintLocationPaper}
            onDestroy={onAbsoluteHintLocationPaperDestroy}
          />
        )}
      </div>
    </>
  );
}

export default Flow;
