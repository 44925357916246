import { AuthenticationContext } from "./AuthenticationContext";
import { GameContext } from "./GameContext";
import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { Link, NavLink, Route, Routes } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import {
  Icon,
  Menu,
  Popup,
} from "semantic-ui-react";

import DataGridBoard from "./DataGridBoard";
import MapPanel from "./MapPanel";
import PuzzleTagBoard from "./PuzzleTagBoard";
import PuzzleUserBoard from "./PuzzleUserBoard";
import SolutionTagBoard from "./SolutionTagBoard";
import UserIcon from "./UserIcon";

import PuzzleStageBoard from "./PuzzleStageBoard";
import { q } from "./Query";
import { api, handleMaybeSingle, handleResponse } from "./api";
import GraphBoard from "./GraphBoard";
import PuzzleCardBoard from "./PuzzleCardBoard";
import GamePanel from "./GamePanel";

function GameBoard() {
  const { gameName } = useParams();
  const { pathname } = useLocation();

  const [game, setGame] = React.useState(null);
  const [previousGame, setPreviousGame] = React.useState(null);
  const [nextGame, setNextGame] = React.useState(null);

  const [auth, setShowLoginForm] = React.useContext(AuthenticationContext);
  const handleError = useErrorHandler();

  React.useEffect(() => {
    if (auth.didInvalidate) return;
    if (auth.isFetching) return;
    api(auth?.token, "games")
      .from("games")
      .select(
        q([
          "game_id",
          "game_series_id",
          "title",
          "name",
          "start",
          "end",
          "volume",
          { "game_series": ["game_series_id", "name", "slug", { "...game_series_checksums": ["logo_checksum"] }] },
          { "...game_checksums": ["logo_checksum"] },
        ])
      )
      .eq("name", gameName)
      .single()
      .then(handleResponse(handleError, setGame), handleError);
  }, [auth, gameName]);


  React.useEffect(() => {
    if (!game) return;
    if (!game.volume) return;
    if (!game.game_series_id) return;
    if (auth.didInvalidate) return;
    if (auth.isFetching) return;

    api(auth?.token, "games")
      .from("games")
      .select(q(["game_id", "title", "name", "start", "end", "volume"]))
      .lt("volume", game.volume)
      .eq("game_series_id", game.game_series_id)
      .order("volume", { ascending: false })
      .limit(1)
      .maybeSingle()
      .then(handleMaybeSingle(handleError, setPreviousGame), handleError);

    api(auth?.token, "games")
      .from("games")
      .select(q(["game_id", "title", "name", "start", "end", "volume"]))
      .gt("volume", game.volume)
      .eq("game_series_id", game.game_series_id)
      .order("volume")
      .limit(1)
      .maybeSingle()
      .then(handleMaybeSingle(handleError, setNextGame), handleError);

  }, [game, auth]);

  return (
    <>
      <Menu attached={"top"} inverted={true}>
        <Menu.Item as={NavLink} icon name="house" to="../">
          <Icon name="home" />
        </Menu.Item>
        <Popup
          content={previousGame?.title}
          trigger={
            game &&
            previousGame && (
              <Menu.Item
                as={Link}
                icon
                to={pathname.replace(game.name, previousGame.name)}
              >
                «
              </Menu.Item>
            )
          }
        />
        <Menu.Item>
          <Link to={""}>{game?.title}</Link>{" "}
        </Menu.Item>
        <Popup
          content={nextGame?.title}
          trigger={
            game &&
            nextGame && (
              <Menu.Item
                as={Link}
                icon
                to={pathname.replace(game.name, nextGame.name)}
              >
                »
              </Menu.Item>
            )
          }
        />
        {(auth.token || (game?.start && new Date(Date.parse(game.start)) < new Date())) && (
          <>
            <Menu.Item as={NavLink} icon name="table" to="board/zadani">
              <Icon name="table" />
            </Menu.Item>
            <Menu.Item as={NavLink} icon name="table" to="table">
              <Icon name="list" link fitted />
            </Menu.Item>
            <Menu.Item as={NavLink} icon name="schema" to="schema">
              <Icon name="sitemap" link fitted />
            </Menu.Item>
            <Menu.Item as={NavLink} icon name="map" to="map">
              <Icon name="world" />
            </Menu.Item>
          </>
        )}
        <Menu.Item position="right" icon link onClick={() =>  setShowLoginForm(true) }>
        
          <UserIcon />
        </Menu.Item>
      </Menu>
      <GameContext.Provider value={game}>
        <Routes>
          <Route path="board">
            <Route path="zadani" element={<PuzzleCardBoard />} />
            <Route path="sifry" element={<PuzzleStageBoard />} />
            <Route path="autori" element={<PuzzleUserBoard />} />
            <Route path="tagy" element={<PuzzleTagBoard />} />
            <Route path="reseni" element={<SolutionTagBoard />} />
          </Route>
          <Route path="table" element={<DataGridBoard />} />
          <Route path="schema" element={<GraphBoard />} />
          <Route path="map" element={<MapPanel />} />
          <Route
            path="*"
            element={<GamePanel game={game} />}
          />
        </Routes>
      </GameContext.Provider>
    </>
  );
}

export default GameBoard;
