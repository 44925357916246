import React from "react";
import { useSearchParams } from "react-router-dom";

import DataGridPanel from "../DataGridPanel";
import DataGridColumn from "../DataGridColumn";
import ModalSolution from "../components/ModalSolution";

import { StageColumn } from "./solutions/StageColumn";
import { UserColumn } from "./solutions/UserColumn";
import { PuzzleSolutionColumn } from "./solutions/PuzzleSolutionColumn";
import { SolutionTagColumn } from "./solutions/SolutionTagColumn";

export default function SolutionDataGrid({ viewSelect }) {
  const [search, setSearch] = useSearchParams();
  const defaultSolution = search.has("solution")
    ? // ? { solution_id: search.get("solution"), name: null, problem_file_name: null }
      null
    : null;

  const [solution, setSolution] = React.useState(defaultSolution);

  React.useEffect(() => {
    if (solution) search.set("solution", solution.solution_id);
    else search.delete("solution");
    setSearch(search);
  }, [solution]);

  const [data, setData] = React.useState({
    didInvalidate: true,
    isFetching: false,
    items: null,
    lastUpdated: null,
  });
  const onSolutionChange = () => {
    setData({ ...data, didInvalidate: true });
  };

  return (
    <>
      <ModalSolution
        solution={solution}
        setSolution={setSolution}
        onChange={onSolutionChange}
      />
      <DataGridPanel
        table={"solutions"}
        primaryKey={"solution_id"}
        data={data}
        setData={setData}
        sort="code"
        viewSelect={viewSelect}
        onRowClick={setSolution}
      >
        {StageColumn}
        {UserColumn}
        {PuzzleSolutionColumn}
        <DataGridColumn name="code" title="Kód" frozen={true} width={1} />
        {SolutionTagColumn}
        <DataGridColumn
          name="solution"
          title="Řešení"
          frozen={true}
          width={2}
        />
        <DataGridColumn name="instructions" title="Postup" width={3} />
      </DataGridPanel>
    </>
  );
}
