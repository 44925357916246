import React from "react";
import { useErrorHandler } from "react-error-boundary";

import "@asseinfo/react-kanban/dist/styles.css";

import { api, handleResponse } from "./api";
import PuzzleBoard from "./PuzzleBoard";
import { AuthenticationContext } from "./AuthenticationContext";
import { GameContext } from "./GameContext";
import { q } from "./Query";
import { User } from "./interface";

export default function PuzzleUserBoard() {
  const [users, setUsers] = React.useState([]);
  const [puzzles, setPuzzles] = React.useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState(null);

  const handleError = useErrorHandler();
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);

  // Load users
  React.useEffect(() => {
    if (!game) return;
    api(auth.token, "puzzles")
      .from("users")
      .select(
        q([
          "user_id",
          "first_name",
          "alias",
          { "organizations!inner": ["organization_id"] },
          {
            puzzle_users: [
              "puzzle_user_id",
              {
                "puzzles!inner": [
                  "puzzle_id",
                  "position",
                  "slug",
                  "tag",
                  "name",
                  "description",
                  { "...puzzle_checksums": ["problem_checksum"] },
                  {
                    puzzle_tags: [
                      "puzzle_tag_id",
                      { tags: ["tag_id", "name"] },
                    ],
                  },
                ],
              },
            ],
          },
        ]),
      )
      .order("position", { foreignTable: "puzzle_users.puzzles" })
      .order("tag", { foreignTable: "puzzle_users.puzzles" })
      .eq("organizations.game_id", game.game_id)
      .eq("puzzle_users.puzzles.game_id", game.game_id)
      .then(
        handleResponse(handleError, (data) => {
          setUsers(
            data
              .sort((a, b) => b.puzzle_users.length - a.puzzle_users.length)
              .map((user: User) => ({
                  id: user.user_id,
                  title: user.alias || user.first_name,
                  puzzles: user.puzzle_users.map((pu) => {
                      return {
                          id: pu.puzzle_user_id,
                          ...pu.puzzles,
                      };
                  }),
              })),
          );
        }),
        handleError,
      );
  }, [game, lastUpdatedAt]);

  const onPuzzleChange = () => {
    setLastUpdatedAt(new Date());
  };

  // Load unassigned puzzles
  React.useEffect(() => {
    if (!game) return;
    api(auth.token, "puzzles")
      .from("puzzles")
      .select(
        `
          puzzle_id,position,slug,tag,name,
              puzzle_users(puzzle_user_id)))`,
      )
      .order("position")
      .order("tag")
      .eq("game_id", game.game_id)
      .eq("internal", false)
      .then(handleResponse(handleError, setPuzzles), handleError);
  }, [game, lastUpdatedAt]);

  const onAddAssociation = ({ id, puzzle_id, association_id }) => {
    api(auth.token, "puzzles")
      .from("puzzle_users")
      .insert({
        game_id: game.game_id,
        puzzle_user_id: id,
        user_id: association_id,
        puzzle_id: puzzle_id,
      })
      .then(handleResponse(handleError), handleError);
  };

  const onChangeAssociation = ({ puzzle_id, from_id, to_id }) => {
    api(auth.token, "puzzles")
      .from("puzzle_users")
      .update({ user_id: to_id })
      .match({ puzzle_id: puzzle_id, user_id: from_id })
      .then(handleResponse(handleError), handleError);
  };

  const onRemoveAssociation = ({ puzzle_id, association_id }) => {
    api(auth.token, "puzzles")
      .from("puzzle_users")
      .delete()
      .match({
        puzzle_id: puzzle_id,
        user_id: association_id,
      })
      .then(handleResponse(handleError), handleError);
  };

  return (
    <PuzzleBoard
      puzzles={users}
      setPuzzles={setUsers}
      unassignedPuzzles={puzzles}
      setUnassignedPuzzles={setPuzzles}
      onPuzzleChange={onPuzzleChange}
      onAddAssociation={onAddAssociation}
      onChangeAssociation={onChangeAssociation}
      onRemoveAssociation={onRemoveAssociation}
    />
  );
}
