import { Link } from "react-router-dom";

import { Card, Image } from "semantic-ui-react";
import GameAttributes from "./GameAttributes";
import GameLogo from "./GameLogo";

function GameCard({ game }): JSX.Element {


  const color = ((game) => {
    const now = new Date()
    const [start, registration_start, registration_end] = ["start", "registration_start", "registration_end"].map(attr => {
      if (game[attr])
        return new Date(Date.parse(game[attr]));
      return null
    })
    if (start < now)
      return "red"
    if (registration_end < now)
      return "orange"
    if (registration_start < now)
      return "green"
    return "blue"

  })(game)


  return (
    <Card color={color}>
      <Card.Content>
        <Card.Header>
          <GameLogo game={game}
            floated="right"
            height="30"
            wrapped
            ui={false}
          />
          <Link to={`/${game.name}`}>{game.title}</Link>
        </Card.Header>
        <Card.Meta>{game.volume}. ročník</Card.Meta>
        <Card.Description>
          <GameAttributes game={game} />
          {game.description}
        </Card.Description>
      </Card.Content>
    </Card>
  );
}

export default GameCard;
