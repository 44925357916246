import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { Button, Image, Form, Modal } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import { api, handleResponse } from "../api";
import "./ModalPuzzle.css";
import TagInput from "./TagInput";
import { AuthenticationContext } from "../AuthenticationContext";
import { GameContext } from "../GameContext";
import { EditContext } from "../EditContext";

export default function ModalPuzzleForm({
  puzzle,
  open,
  setPuzzle,
  onChange,
}) {
  const { register, unregister, handleSubmit } = useForm();

  const { position, tag, name, code, slug } = puzzle || {};
  const handleError = useErrorHandler();
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);
  const [allowEdit, setAllowEdit] = React.useContext(EditContext);
  const [allTags, setAllTags] = React.useState([]);

  React.useEffect(() => {
    if (!game) return;
    if (!puzzle) return;

    api(auth.token, "puzzles")
      .from("puzzles")
      .select(
        `
        puzzle_id,position,tag,slug,name,
          paper_puzzles(
              papers(code,
                  location_papers(
                      locations!location_papers_location_id_fkey(name)))),
          puzzle_tags(puzzle_tag_id,
              tags(tag_id,name)),
          puzzle_solutions(
              solutions(code,solution,instructions,
                  interpretation_solutions(interpretation_id,
                      interpretations(interpretation_id,
                          connections(connection_id,
                              location_papers(location_paper_id,
                                  locations!location_papers_location_id_fkey(location_id,name)))))))
       `
      )
      .eq("puzzle_id", puzzle.puzzle_id)
      .single()
      .then(
        handleResponse(handleError, setPuzzle),
        handleError
      );
  }, [game, open, allowEdit]);

  React.useEffect(() => {
    if (!open) unregister(["position", "tag", "name"]);
  }, [open]);

  React.useEffect(() => {
    if (!game) return;
    api(auth.token, "puzzles")
      .from("tags")
      .select("tag_id,name")
      .order("name")
      .eq("type", "Puzzle")
      .then(
        handleResponse(handleError, setAllTags),
        handleError
      );
  }, [game]);

  const onSubmit = data => {
    if (!puzzle) return;

    api(auth.token, "puzzles")
      .from("puzzles")
      .update(data)
      .match({ puzzle_id: puzzle.puzzle_id })
      .then(
        response => {
          if (response.error) {
            handleError(response.error.message);
          } else {
            onChange();
            setPuzzle(null);
          }
        },
        error => handleError(error)
      );
  };

  return (
    <Modal
      open={open}
      onClose={() => setPuzzle(null)}
      as={Form}
      size="large"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Modal.Header>
        Šifra {position}
        {tag} – {name}
        <Button
          floated="right"
          icon="edit"
          active={allowEdit}
          disabled={!auth?.token}
          onClick={() => setAllowEdit(false)}
        />
      </Modal.Header>
      <Modal.Content scrolling image>
        {game && puzzle && (
          <Image
            as="a"
            className="puzzle"
            size="medium"
            wrapped
            href={`https://statek.seslost.cz/${game.name}/sifry/${
              puzzle.slug
            }.pdf`}
            src={`https://statek.seslost.cz/${
              game.name
            }/sifry/${slug}.png?style=preview`}
          />
        )}
        <Modal.Description>
          <Form.Group inline>
            <Form.Field>
              <label htmlFor="position">Pořadí</label>
              <input
                type="number"
                width={2}
                {...register("position")}
                defaultValue={position}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="tag">Tag</label>
              <input {...register("tag")} defaultValue={tag} />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label htmlFor="name">Název</label>
            <input {...register("name")} defaultValue={name} />
          </Form.Field>
          <Form.Field>
            <label htmlFor="code">Kód</label>
            <input {...register("code")} defaultValue={code} />
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      {puzzle && puzzle.puzzle_id && (
        <Modal.Content>
          <TagInput
            id={puzzle.puzzle_id}
            tags={allTags}
            primaryKey="puzzle_tag_id"
            foreignKey="puzzle_id"
            table="puzzle_tags"
            onChange={onChange}
            setTags={setAllTags}
            tagType="Puzzle"
          />
        </Modal.Content>
      )}
      <Modal.Actions>
        <Button type="submit" primary>
          Uložit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
