import React from "react";
import { useSearchParams } from "react-router-dom";

import DataGrid from "./DataGrid";
import "./DataGrid.css";

export default function DataGridPanel({
  table,
  primaryKey,
  viewSelect,
  data,
  setData,
  sort,
  sortDirection = "ascending",
  group = null,
  onRowClick = null,
  children
}) {
  const [search, setSearch] = useSearchParams();

  // Sort column
  const sortFromParams = search.get("order");
  const sortDirectionFromParams = search.get("direction");
  const defaultSortColumn = sortFromParams
    ? {
        columnKey: sortFromParams,
        direction:
          sortDirectionFromParams === "ascending" ? "ascending" : "descending"
      }
    : {
        columnKey: sort,
        direction: sortDirection
      };
  const [sortColumn, setSortColumn] = React.useState(defaultSortColumn);

  React.useEffect(() => {
    if (sortColumn) {
      search.set("order", sortColumn.columnKey);
      search.set("direction", sortColumn.direction);
    } else {
      search.delete("order");
      search.delete("direction");
    }
    setSearch(search);
    return () => {
      search.delete("order");
      search.delete("direction");
    };
  }, [sortColumn]);

  // Visible columns
  const columns = (Array.isArray(children) ? children : [children]).map(
    ({ props }) => props
  );
  const columnsFromParams = search
    .getAll("columns")
    .flatMap(t => t.split(","));

  const defaultVisibleColumns =
    columnsFromParams && columnsFromParams.length > 0
      ? columnsFromParams.filter(c => columns.some(({ name }) => name === c))
      : columns.filter(({ frozen }) => frozen).map(({ name }) => name);

  const [visibleColumns, setVisibleColumns] = React.useState(
    defaultVisibleColumns
  );

  React.useEffect(() => {
    if (visibleColumns && visibleColumns.length > 0) {
      search.set("columns", visibleColumns.join(","));
    } else {
      search.delete("columns");
    }
    setSearch(search);
    return () => search.delete("columns");
  }, [visibleColumns]);

  // Visible columns
  const categoriesFromParams: { label: string; value: string }[] = search
    .getAll("categories")
    .map(t => t.split(","))
    .flat()
    .map(s => {
      return { value: s, label: "" };
    });

  const [selectedCategories, setSelectedCategories] = React.useState(
    categoriesFromParams
  );

  React.useEffect(() => {
    if (selectedCategories === null) return;
    if (selectedCategories.length > 0)
      search.set(
        "categories",
        selectedCategories.map(({ value }) => value).join(",")
      );
    else search.delete("categories");
    setSearch(search);
  }, [selectedCategories]);

  // Group column
  const groupFromParams = search.get("group");
  const defaultGroupColumn = groupFromParams
    ? columns.find(({ name }) => name === groupFromParams)
    : group
    ? columns.find(({ name }) => name === group)
    : null;
  const defaultGroupColumnOption = defaultGroupColumn
    ? {
        label: defaultGroupColumn.title,
        value: defaultGroupColumn.name
      }
    : null;
  const [groupColumn, setGroupColumn] = React.useState(
    defaultGroupColumnOption
  );

  React.useEffect(() => {
    if (groupColumn) search.set("group", groupColumn.value);
    else search.delete("group");
    setSearch(search);
  }, [groupColumn]);

  return (
    <DataGrid
      table={table}
      data={data}
      setData={setData}
      primaryKey={primaryKey}
      viewSelect={viewSelect}
      groupColumn={groupColumn}
      setGroupColumn={setGroupColumn}
      sortColumn={sortColumn}
      setSortColumn={setSortColumn}
      visibleColumns={visibleColumns}
      setVisibleColumns={setVisibleColumns}
      selectedCategories={selectedCategories}
      setSelectedCategories={setSelectedCategories}
      onRowClick={onRowClick}
    >
      {children}
    </DataGrid>
  );
}
