import { Popup } from "semantic-ui-react";

import DataGridCollectionColumn from "../..//DataGridCollectionColumn";
import { Location, Puzzle } from "../../interface";

export const LocationPaperColumn = (
  <DataGridCollectionColumn
    name="location_papers"
    title="Umístění"
    type="collection"
    frozen={true}
    width={3}
    select={{
      paper_puzzles: [
        "paper_puzzle_id",
        {
          papers: [
            "paper_id",
            "code",
            "game_id",
            {
              location_papers: {
                "locations!location_papers_location_id_fkey": [
                  "location_id",
                  "name",
                ],
              },
            },
          ],
        },
      ],
    }}
    compare={(a: Location, b: Location) =>
      a.name && a.name.localeCompare(b.name)
    }
    renderItem={([, location]) => location.name}
    join={(arr) => arr.sort().join(", ")}
    getItems={(puzzle: Puzzle) =>
      puzzle.paper_puzzles
        ? puzzle.paper_puzzles
            .filter((pp) => pp.papers)
            .map((pp) =>
              pp.papers.location_papers
                .filter((lp) => lp.locations)
                .map((lp) => [lp.locations.location_id, lp.locations])
                .flat()
            )
        : []
    }
    render={(puzzle: Puzzle) =>
      puzzle.paper_puzzles
        ? puzzle.paper_puzzles
            .filter((pp) => pp.papers)
            .filter((pp) => pp.papers.location_papers)
            .map((pp) =>
              pp.papers.location_papers
                .filter((lp) => lp.locations)
                .map((lp) => (
                  <Popup
                    key={[pp.paper_puzzle_id, lp.location_paper_id].join("-")}
                    trigger={
                      <a className="puzzle-link">
                        {lp.locations.name || pp.papers.code}
                      </a>
                    }
                  >
                    <Popup.Header>Kód stanoviště</Popup.Header>
                    <Popup.Content>{pp.papers.code}</Popup.Content>
                  </Popup>
                ))
            )
            .flat()
        : []
    }
  />
);
