import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { Button, Card, Header, Icon, Image, Segment } from "semantic-ui-react";

import { api, handleResponse } from "./api";
import { q } from "./Query";
import "./Location.css";
import ModalGallery from "./components/ModalGallery";
import { AuthenticationContext } from "./AuthenticationContext";
import { GameContext } from "./GameContext";

import { Interpretation, Location } from "./interface";
import { EditContext } from "./EditContext";

export default function MyLocation({
  location,
  setLocation,
  setDragging,
  setCoordinates,
  onDestroy,
}: {
  location: Location;
  setLocation: (location: Location) => void;
  setDragging: (dragging: Location) => void;
  onDestroy: (event: any) => void;
  setCoordinates: any;
}) {
  const handleError = useErrorHandler();
  const [photos, setPhotos] = React.useState([]);
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);
  const [allowEdit] = React.useContext(EditContext);

  React.useEffect(() => {
    if (!game) return;
    if (
      location.location_papers &&
      location.interpretations &&
      location.terrain_hints
    )
      return;
    api(auth.token, "puzzles")
      .from("locations")
      .select(
        q([
          "location_id",
          "name",
          "latitude",
          "longitude",
          {
            photos: [["photo_id", "description"]],
            interpretations: [
              "interpretation_id",
              "from",
              "to",
              {
                interpretation_solutions: {
                  solutions: [
                    [
                      "solution_id",
                      "code",
                      {
                        puzzle_solutions: {
                          puzzles: [
                            "puzzle_id",
                            "position",
                            "tag",
                            "name",
                            "slug",
                            { "...puzzle_checksums": ["problem_checksum"] },
                          ],
                        },
                      },
                    ],
                  ],
                },
              },
            ],
            "location_papers!location_papers_location_id_fkey": [
              "location_paper_id",
              {
                papers: [
                  "paper_id",
                  "code",
                  "final",
                  "game_id",
                  {
                    paper_puzzles: [
                      "paper_puzzle_id",
                      {
                        puzzles: [
                          "puzzle_id",
                          "position",
                          "tag",
                          "description",
                          "name",
                          "slug",
                          { "...puzzle_checksums": ["problem_checksum"] },
                          {
                            puzzle_stages: {
                              stages: "position",
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            terrain_hints: {
              hints: ["text"],
            },
          },
        ])
      )
      .eq("location_id", location.location_id)
      .single()
      .then(
        handleResponse(handleError, (data) => setLocation(data)),
        handleError
      );
  }, [game, location]);

  const handleClearLocationClick = () => setLocation(null);
  const handleDestroyLocationClick = () => {
    onDestroy(location);
    setLocation(null);
  };

  const clearCoordinates = () => {
    setCoordinates(location, { x: null, y: null });
    setLocation({ ...location, latitude: null, longitude: null });
  };

  const availablePhotos = location?.photos || [];

  return (
    <div>
      <ModalGallery
        open={photos.length > 0}
        title={location.name}
        photos={photos}
        onClose={() => setPhotos([])}
      />
      <Segment attached>
        {location && !(location.latitude && location.longitude) && (
          <Icon
            name="marker"
            color="red"
            onMouseDown={() => setDragging(location)}
            size={"large"}
          />
        )}
        <Button.Group floated="right">
          {!(location.latitude || location.longitude) &&
            location.location_papers &&
            location.location_papers.length === 0 &&
            location.interpretations &&
            location.interpretations.length === 0 &&
            location.terrain_hints &&
            location.terrain_hints.length === 0 && (
              <Button
                icon="trash"
                negative
                onClick={handleDestroyLocationClick}
              />
            )}
          {location.latitude && location.longitude && allowEdit && (
            <Button
              icon={
                <Icon.Group>
                  <Icon name="marker" />
                  <Icon name="close" color="red" corner />
                </Icon.Group>
              }
              basic
              onClick={clearCoordinates}
            />
          )}
          <Button icon="close" basic onClick={handleClearLocationClick} />
        </Button.Group>
        {location && <Header content={location.name} as="h3" />}
      </Segment>

      {game && availablePhotos.length > 0 && (
        <Image
          as="a"
          className="location"
          onClick={() => setPhotos(availablePhotos)}
          src={`https://trakar.seslost.cz/${game.name}/fotky/${
            (availablePhotos[0] as any).photo_id
          }.png?style=medium`}
          fluid
        />
      )}

      {game &&
        location.location_papers?.map((location_paper) =>
          location_paper.papers?.paper_puzzles
            .filter((paper_puzzle) => paper_puzzle.puzzles)
            .map((paper_puzzle) => {
              const puzzle = paper_puzzle.puzzles;
              const paper = location_paper.papers;
              return (
                <Card
                  centered
                  key={`${location_paper.location_paper_id}---${paper_puzzle.paper_puzzle_id}`}
                  puzzle={puzzle}
                >
                  {puzzle.problem_checksum && (
                    <Image
                      as="a"
                      href={`https://statek.seslost.cz/${game.name}/sifry/${puzzle.slug}.pdf?checksum=${puzzle.problem_checksum}`}
                      src={`https://statek.seslost.cz/${game.name}/sifry/${puzzle.slug}.png?style=preview&checksum=${puzzle.problem_checksum}`}
                      wrapped
                      ui={false}
                    />
                  )}
                  <Card.Content>
                    <Card.Header>
                      {`${puzzle.position + puzzle.tag} — ${puzzle.name}`}
                    </Card.Header>
                  </Card.Content>
                  <Card.Content extra>{paper.code}</Card.Content>
                </Card>
              );
            })
        )}

      {game &&
        location?.interpretations?.map((interpretation: Interpretation) =>
          interpretation.interpretation_solutions.map(
            (interpretation_solution) =>
              interpretation_solution.solutions.puzzle_solutions
                .map((puzzle_solution) => puzzle_solution.puzzles)
                .map((puzzle) => (
                  <Card centered key={interpretation.interpretation_id}>
                    <Card.Content>
                      <Card.Header>
                        → {`${puzzle.position + puzzle.tag} — ${puzzle.name}`}
                      </Card.Header>
                      <Card.Description>
                        <Icon name="time" size="small" />
                        {interpretation.from.replace(/:00$/, "")} —{" "}
                        {interpretation.to.replace(/:00$/, "")}
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      {interpretation_solution.solutions.code}
                    </Card.Content>
                  </Card>
                ))
          )
        )}

      {game &&
        location &&
        location.terrain_hints &&
        location.terrain_hints
          .map(({ hints }) => hints)
          .map(({ hint_id, text }) => (
            <Card centered key={hint_id}>
              <Card.Content>
                <Card.Header>Nápověda</Card.Header>
                <Card.Description>{text}</Card.Description>
              </Card.Content>
            </Card>
          ))}
    </div>
  );
}
