import DataGridCollectionColumn from "../..//DataGridCollectionColumn";
import { Label } from "semantic-ui-react";
import { Puzzle, Tag } from "../../interface";
import { colorize } from "../../colors";

export const PuzzleTagColumn = (
  <DataGridCollectionColumn
    name="puzzle_tags"
    title="Tagy"
    type="collection"
    width={2}
    select={{
      puzzle_tags: [
        "puzzle_tag_id",
        {
          tags: ["tag_id", "name"]
        }
      ]
    }}
    compare={(a: Tag, b: Tag) => a.name.localeCompare(b.name)}
    renderItem={([id, tag]) => (
      <Label key={id} color={colorize(tag.tag_id)} size="mini">
        {tag.name}
      </Label>
    )}
    getItems={(puzzle: Puzzle) =>
      puzzle.puzzle_tags
        ? puzzle.puzzle_tags.map(t => {
            return [t.tags.tag_id, t.tags];
          })
        : []
    }
  />
);
