import React from "react";
import { Button, Card, Divider, Header, Icon, Image, Segment } from "semantic-ui-react";

import "./Location.css";
import { GameContext } from "./GameContext";

import { Connection } from "./interface";

export default function ConnectionDetail({
  connection,
  setConnection,
  onDestroy,
}: {
  connection: Connection;
  setConnection: (connection: Connection) => void;
  onDestroy: (connection: Connection) => void;
}) {
  const game = React.useContext(GameContext);

  const handleClearConnectionClick = () => setConnection(null);

  const handleDestroyLocationClick = () => {
    onDestroy(connection);
    setConnection(null);
  };

  return (
    <div>
      <Segment attached>
        <Button.Group floated="right">
          <Button icon="trash" negative onClick={handleDestroyLocationClick} />
        </Button.Group>
        <Button.Group floated="left">
          <Button icon="close" basic onClick={handleClearConnectionClick} />
        </Button.Group>
        <Divider horizontal basic clearing />


        {connection?.interpretations?.interpretation_solutions
          ?.map(({ solutions }) => solutions)
          ?.map(({ solution_id, code, solution, puzzle_solutions }) => (
            <Card centered
              key={`${connection.connection_id}---${solution_id}`}
            >
              <Card.Content>
                <Card.Header>
                  {puzzle_solutions
                    .map(({ puzzles }) => puzzles)
                    .map(({ name, tag, position }) =>
                      `${position + tag} — ${name}`
                    )
                    .join(", ")
                  }
                </Card.Header>
                {code}
                <Card.Description>
                  {solution}
                </Card.Description>
              </Card.Content>
            </Card>))
        }
        <Divider horizontal>
          <Icon name="triangle down" size="big" />
        </Divider >

        {connection?.interpretations?.description || (<em>bez upřesnítka</em>)}
        <Divider horizontal>
          <Icon name="triangle down" size="big" />
        </Divider >
        {game &&
          connection.location_papers?.papers?.paper_puzzles
            .filter((paper_puzzle) => paper_puzzle.puzzles)
            .map((paper_puzzle) => {
              const puzzle = paper_puzzle.puzzles;
              return (
                <Card
                  centered
                  key={`${connection.connection_id}---${paper_puzzle.paper_puzzle_id}`}
                  puzzle={puzzle}
                >
                  {puzzle.problem_checksum && (
                    <Image
                      as="a"
                      href={`https://statek.seslost.cz/${game.name}/sifry/${puzzle.slug}.pdf?checksum=${puzzle.problem_checksum}`}
                      src={`https://statek.seslost.cz/${game.name}/sifry/${puzzle.slug}.png?style=preview&checksum=${puzzle.problem_checksum}`}
                      wrapped
                      ui={false}
                    />
                  )}
                  <Card.Content>
                    <Card.Header>
                      {`${puzzle.position + puzzle.tag} — ${puzzle.name}`}
                    </Card.Header>
                  </Card.Content>
                  {puzzle.code && (
                    <Card.Content extra>{puzzle.code}</Card.Content>
                  )}
                </Card>
              );
            })}



      </Segment>
    </div>
  );
}
