import React from "react";
import { useSearchParams } from "react-router-dom";

import DataGridPanel from "../DataGridPanel";
import DataGridColumn from "../DataGridColumn";
import ModalPuzzle from "../components/ModalPuzzle";

import { LabelColumn } from "./puzzles/LabelColumn";
import { LocationPaperColumn } from "./puzzles/LocationPaperColumn";
import { NameColumn } from "./puzzles/NameColumn";
import { PaperPuzzleColumn } from "./puzzles/PaperPuzzleColumn";
import { PuzzleSolutionColumn } from "./puzzles/PuzzleSolutionColumn";
import { PuzzleStageColumn } from "./puzzles/PuzzleStageColumn";
import { PuzzleTagColumn } from "./puzzles/PuzzleTagColumn";
import { PuzzleUserColumn } from "./puzzles/PuzzleUserColumn";
import { SolutionTagColumn } from "./puzzles/SolutionTagColumn";
import { Tab, TabPane } from "semantic-ui-react";

export default function PuzzleDataGrid({ viewSelect }) {
  const [search, setSearch] = useSearchParams();
  const defaultPuzzle = search.has("puzzle")
    ? // ? { puzzle_id: search.get("puzzle"), name: null, problem_file_name: null }
    null
    : null;

  const [puzzle, setPuzzle] = React.useState(defaultPuzzle);

  React.useEffect(() => {
    if (puzzle) search.set("puzzle", puzzle.puzzle_id);
    else search.delete("puzzle");
    setSearch(search);
  }, [puzzle]);

  const [data, setData] = React.useState({
    didInvalidate: true,
    isFetching: false,
    items: null,
    lastUpdated: null,
  });
  const onPuzzleChange = () => {
    setData({ ...data, didInvalidate: true });
  };


  return (
    <>

      <ModalPuzzle
        puzzle={puzzle}
        setPuzzle={setPuzzle}
        onChange={onPuzzleChange}
      />
      <DataGridPanel
        table={"puzzles"}
        primaryKey={"puzzle_id"}
        data={data}
        setData={setData}
        sort="position"
        viewSelect={viewSelect}
        onRowClick={setPuzzle}
      >
        {LabelColumn}
        {PuzzleStageColumn}
        <DataGridColumn name="code" title="Kód" frozen={false} />
        {NameColumn}
        {PaperPuzzleColumn}
        {PuzzleUserColumn}
        {PuzzleTagColumn}
        {SolutionTagColumn}
        {LocationPaperColumn}
        {PuzzleSolutionColumn}
        <DataGridColumn name="motto" title="Motto" frozen={false} />
        <DataGridColumn name="description" title="Popis" frozen={false} />
      </DataGridPanel>
    </>
  );
}
