import { Popup, Image } from "semantic-ui-react";
import DataGridCollectionColumn from "../..//DataGridCollectionColumn";
import { Puzzle, Connection } from "../../interface";

export const PuzzleColumn = (
  <DataGridCollectionColumn
    name="puzzles"
    title="Šifra"
    type="collection"
    frozen={true}
    width={2}
    select={{
      interpretations: {
        interpretation_solutions: {
          solutions: {
            puzzle_solutions: {
              puzzles: ["puzzle_id", "position", "tag", "name"]
            }
          }
        }
      }
    }}
    compare={(a: Puzzle, b: Puzzle) => a.position - b.position}
    renderItem={([, puzzle]) => puzzle.name}
    getItems={(connection: Connection) =>
      connection.interpretations?.interpretation_solutions
        .map(({ solutions }) => solutions)
        .flatMap(({ puzzle_solutions }) => puzzle_solutions)
        .map(({ puzzles }) => [puzzles.puzzle_id, puzzles])
    }
    render={(connection : Connection, game) => {
      return (
        connection.interpretations?.interpretation_solutions
          .map(({ solutions }) => solutions)
          .flatMap(({ puzzle_solutions }) => puzzle_solutions)
          .map(({ puzzles }) => (
            <Popup
              key={[connection.connection_id, puzzles.puzzle_id].join("-")}
              trigger={<a className="puzzle-link">{puzzles.name}</a>}
            >
              <Popup.Header>{puzzles.name}</Popup.Header>
              <Popup.Content>
                <Image
                  src={`https://statek.seslost.cz/${game.name}/sifry/${
                    puzzles.slug
                  }.png?style=thumb`}
                />
              </Popup.Content>
            </Popup>
          ))
      );
    }}
  />
);
