import React from "react";
import { useSearchParams } from "react-router-dom";

import DataGridPanel from "../DataGridPanel";
import DataGridColumn from "../DataGridColumn";
import ModalConnection from "../components/ModalConnection";

import { StageColumn } from "./connections/StageColumn";
import { PuzzleColumn } from "./connections/PuzzleColumn";
import { SolutionColumn } from "./connections/SolutionColumn";
import { LocationColumn } from "./connections/LocationColumn";
import { initialState, invalidate, receive } from "../api";
import { Connection } from "../interface";

export default function ConnectionDataGrid({
  viewSelect,
}) {
  const [search, setSearch] = useSearchParams();
  const defaultConnection = search.has("connection")
    ? {
        connection_id: search.get("connection"),
      }
    : null;

  const [connection, setConnection] = React.useState(
    initialState(defaultConnection)
  );

  React.useEffect(() => {
    if (connection.data)
      search.set("connection", connection.data.connection_id);
    else search.delete("connection");
    setSearch(search);
  }, [connection]);

  const [data, setData] = React.useState({
    didInvalidate: true,
    isFetching: false,
    items: null,
    lastUpdated: null,
  });
  const onConnectionChange = () => {
    invalidate(setData);
  };

  return (
    <>
      <ModalConnection
        connection={connection}
        setConnection={setConnection}
        onChange={onConnectionChange}
      />
      <DataGridPanel
        table={"connections"}
        primaryKey={"connection_id"}
        data={data}
        setData={setData}
        sort="specification"
        group="stages"
        viewSelect={viewSelect}
        onRowClick={(c: Connection) =>
          setConnection({ ...connection, data: c, didInvalidate: true })
        }
      >
        {StageColumn}
        {PuzzleColumn}
        {SolutionColumn}
        <DataGridColumn
          name="specification"
          title="Upřesnítko"
          frozen={true}
          width={3}
        />
        {LocationColumn}
      </DataGridPanel>
    </>
  );
}
