import Board from "@asseinfo/react-kanban";
import React from "react";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Button, Checkbox, Icon, Menu } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";

import "@asseinfo/react-kanban/dist/styles.css";

import ColumnHeader from "./ColumnHeader";
import { EditContext } from "./EditContext";
import PuzzleCard from "./PuzzleCard";
import ModalPuzzle from "./components/ModalPuzzle";
import { Puzzle } from "./interface";

import "./PuzzleBoard.css";
import PuzzleBoardNavigation from "./PuzzleBoardNavigation";
import { AuthenticationContext } from "./AuthenticationContext";
import { invalidate } from "./api";

const ColumnAdder = ({ onAdd }) => {
  return (
    <div className=" add-column">
      <Button
        inverted
        basic
        fluid
        compact
        color="grey"
        animated="fade"
        onClick={(e) => onAdd(e)}
      >
        <Button.Content visible>
          <Icon name="plus" />
        </Button.Content>
        <Button.Content hidden>Přidat část hry</Button.Content>
      </Button>
    </div>
  );
};

export default function PuzzleBoard({
  puzzles,
  setPuzzles,
  unassignedPuzzles,
  setUnassignedPuzzles,
  onPuzzleChange,
  onAddAssociation,
  onChangeAssociation,
  onRemoveAssociation,
  onHeaderClick = null,
  onCreateCategory = null,
  onAddColumn = null,
  onUpdatePosition = null,
}) {
  const [search, setSearch] = useSearchParams();
  const defaultPuzzle = search.has("puzzle")
    ? { puzzle_id: search.get("puzzle"), name: null, problem_checksum: null }
    : null;

  const [auth] = React.useContext(AuthenticationContext);

  const columnsFromParams = search
    .getAll("columns")
    .flatMap((t) => t.split(","));

  const defaultPuzzles =
    columnsFromParams && columnsFromParams.length > 0
      ? columnsFromParams
        .filter((c) => puzzles.some(({ id }) => id === c))
        .map((c) => puzzles.find(({ id }) => id === c))
        .concat(puzzles.filter(({ frozen }) => frozen))
      : puzzles?.filter(({ frozen }) => frozen);

  const defaultSelectedPuzzles = defaultPuzzles.map(({ stage_id, title }) => {
    return { label: title, value: stage_id };
  });

  const [allowEdit, setAllowEdit] = React.useContext(EditContext);
  const [showPreviews, setShowPreviews] = React.useState(false);
  const [showTags, setShowTags] = React.useState([]);
  const [board, setBoard] = React.useState({ columns: [] });
  const [puzzle, setPuzzle] = React.useState(defaultPuzzle);

  const [selectedPuzzles, setSelectedPuzzles] = React.useState(
    defaultSelectedPuzzles,
  );

  React.useEffect(() => {
    if (puzzles.length === 0) return;
    setSelectedPuzzles((selectedPuzzles) =>
      selectedPuzzles.concat(
        puzzles
          .filter(
            ({ id }) => !selectedPuzzles.some(({ value }) => value === id),
          )
          .filter(
            ({ id, frozen }) =>
              frozen || columnsFromParams.some((c) => c === id),
          )
          .map(({ id, title }) => {
            return { label: title, value: id };
          }),
      ),
    );

    setSelectedPuzzles((selectedPuzzles) =>
      selectedPuzzles.concat(
        puzzles
          .filter(
            ({ stage_id }) =>
              !selectedPuzzles.some(({ value }) => value === stage_id),
          )
          .filter(
            ({ stage_id, frozen }) =>
              frozen || columnsFromParams.some((c) => c === stage_id),
          )
          .map(({ stage_id, title }) => {
            return { label: title, value: stage_id };
          }),
      ),
    );
  }, [puzzles]);

  const createBoard = ({ puzzles, unassignedPuzzles }) => {
    let columns = [];

    if (selectedPuzzles !== null && selectedPuzzles.length > 0) {
      columns = selectedPuzzles.map(({ value }) =>
        puzzles.find(({ id }) => id === value),
      );
    } else if (puzzles) {
      columns = puzzles.filter(
        (puzzle) => puzzle.visible || puzzle.puzzles.length > 0,
      );
    }

    const allUnassignedPuzzles: [Puzzle] = unassignedPuzzles.filter(
      (p) =>
        !columns.some(
          (c) => c?.puzzles.some((cp) => p.puzzle_id === cp.puzzle_id),
        ),
    );

    return {
      columns: (allowEdit || allUnassignedPuzzles.length > 0
        ? [
          {
            id: null,
            title: "nepřiřazené",
            cards: allUnassignedPuzzles
              .sort((a: Puzzle, b: Puzzle) => a.position - b.position)
              .sort((a: Puzzle, b: Puzzle) => a.tag?.localeCompare(b.tag))
              .map((p) => {
                return { id: p.puzzle_id, ...p };
              }),
          },
        ]
        : []
      ).concat(
        columns
          .filter((c) => c)
          .map((puzzle) => {
            return {
              id: puzzle.id,
              title: puzzle.title,
              position: puzzle.position,
              cards: (puzzle.puzzles || [])
                .sort((a: Puzzle, b: Puzzle) => a.tag?.localeCompare(b.tag))
                .sort((a: Puzzle, b: Puzzle) => a.position - b.position),
            };
          }),
      ),
    };
  };

  React.useEffect(() => {
    if (puzzle) search.set("puzzle", puzzle.puzzle_id);
    else search.delete("puzzle");
    setSearch(search);
  }, [puzzle]);

  React.useEffect(() => {
    if (puzzles.length === 0) return;
    if (selectedPuzzles === null) return;
    if (selectedPuzzles && selectedPuzzles.length > 0)
      search.set(
        "columns",
        selectedPuzzles.map(({ value }) => value),
      );
    else search.delete("columns");
    setSearch(search);
  }, [puzzles, selectedPuzzles]);

  // Configure cancan board
  React.useEffect(() => {
    setBoard(createBoard({ puzzles, unassignedPuzzles }));
  }, [puzzles, allowEdit, unassignedPuzzles, selectedPuzzles]);

  const onCardDragEnd = (
    puzzle: Puzzle,
    { fromColumnId, fromPosition },
    { toColumnId, toPosition },
  ) => {
    const id = uuidv4();
    if (fromColumnId === toColumnId) return;
    const updatedPuzzle = { id: id, ...puzzle };

    const updatedPuzzles = puzzles?.map((puzzle) => {
      let updatedPuzzles = puzzle.puzzles;

      if (puzzle.id === fromColumnId && puzzle.id === toColumnId) {
        if (fromPosition < toPosition)
          updatedPuzzles = [
            ...puzzle.puzzles.slice(0, fromPosition),
            ...puzzle.puzzles.slice(fromPosition + 1, toPosition + 1),
            updatedPuzzle,
            ...puzzle.puzzles.slice(toPosition + 1),
          ];
        else
          updatedPuzzles = [
            ...puzzle.puzzles.slice(0, toPosition),
            updatedPuzzle,
            ...puzzle.puzzles.slice(toPosition, fromPosition),
            ...puzzle.puzzles.slice(fromPosition + 1),
          ];
      } else if (puzzle.id === fromColumnId) {
        updatedPuzzles = [
          ...puzzle.puzzles.slice(0, fromPosition),
          ...puzzle.puzzles.slice(fromPosition + 1),
        ];
      } else if (puzzle.id === toColumnId) {
        updatedPuzzles = [
          ...puzzle.puzzles.slice(0, toPosition),
          updatedPuzzle,
          ...puzzle.puzzles.slice(toPosition),
        ];
      }
      return {
        ...puzzle,
        puzzles: updatedPuzzles,
      };
    });

    let updatedUnassignedPuzzles = unassignedPuzzles;

    if (null === fromColumnId && null === toColumnId) {
      if (fromPosition < toPosition)
        updatedUnassignedPuzzles = [
          ...unassignedPuzzles.slice(0, fromPosition),
          ...unassignedPuzzles.slice(fromPosition + 1, toPosition + 1),
          puzzle,
          ...unassignedPuzzles.slice(toPosition + 1),
        ];
      else
        updatedUnassignedPuzzles = [
          ...unassignedPuzzles.slice(0, toPosition),
          puzzle,
          ...unassignedPuzzles.slice(toPosition, fromPosition),
          ...unassignedPuzzles.slice(fromPosition + 1),
        ];
    } else if (null === fromColumnId) {
      updatedUnassignedPuzzles = [
        ...unassignedPuzzles.slice(0, fromPosition),
        ...unassignedPuzzles.slice(fromPosition + 1),
      ];
    } else if (null === toColumnId) {
      updatedUnassignedPuzzles = [
        ...unassignedPuzzles.slice(0, toPosition),
        puzzle,
        ...unassignedPuzzles.slice(toPosition),
      ];
    }

    setBoard(
      createBoard({
        unassignedPuzzles: updatedUnassignedPuzzles,
        puzzles: updatedPuzzles,
      }),
    );
    setPuzzles(updatedPuzzles);
    setUnassignedPuzzles(updatedUnassignedPuzzles);

    const fromPuzzle =
      puzzles.find(({ id }) => id === fromColumnId) || "nezařazeno";
    const toPuzzle =
      puzzles.find(({ id }) => id === toColumnId) || "nezařazeno";
    const puzzle_name = `${puzzle.position || ""}${puzzle.tag || ""} - ${puzzle.name
      }`;

    if (fromColumnId === null) {
      console.log(`Add "${toPuzzle.first_name}" to puzzle "${puzzle_name}"`);
      onAddAssociation({
        id: id,
        puzzle_id: puzzle.puzzle_id,
        association_id: toColumnId,
      });
    } else if (toColumnId === null) {
      console.log(
        `Remove "${fromPuzzle.first_name}" from puzzle "${puzzle_name}"`,
      );
      onRemoveAssociation({
        puzzle_id: puzzle.puzzle_id,
        association_id: fromColumnId,
      });
    } else {
      console.log(
        `Change from "${fromPuzzle.first_name}" to ${toPuzzle.first_name} for puzzle  "${puzzle_name}"`,
      );
      onChangeAssociation({
        puzzle_id: puzzle.puzzle_id,
        from_id: fromColumnId,
        to_id: toColumnId,
      });
    }
  };

  const onColumnDragEnd = ({ id }, { fromPosition }, { toPosition }) => {
    const orderedPuzzles = board.columns.slice(1).map(({ id, title }) => {
      return { value: id, label: title };
    });
    const reorderedPuzzles =
      fromPosition < toPosition
        ? orderedPuzzles
          .slice(0, fromPosition - 1)
          .concat(orderedPuzzles.slice(fromPosition, toPosition))
          .concat([orderedPuzzles[fromPosition - 1]])
          .concat(orderedPuzzles.slice(toPosition))
        : orderedPuzzles
          .slice(0, toPosition - 1)
          .concat([orderedPuzzles[fromPosition - 1]])
          .concat(orderedPuzzles.slice(toPosition - 1, fromPosition - 1))
          .concat(orderedPuzzles.slice(fromPosition));

    if (onUpdatePosition)
      onUpdatePosition(
        {
          id,
          fromPosition,
          toPosition,
        },
        [null, ...orderedPuzzles.map(({ value }) => value)],
      );

    setBoard({
      ...board,
      columns: [board.columns[0]].concat(
        reorderedPuzzles
          .filter(({ value }) => board.columns.some(({ id }) => value === id))
          .map(({ value }) => board.columns.find(({ id }) => value === id)),
      ),
    });
    setSelectedPuzzles(reorderedPuzzles);
  };

  const onCardClick = (_, { puzzle }) => {
    setPuzzle(puzzle);
  };

  const colorize = (solution, dragging) => {
    if (dragging) return "grey";
    if (!puzzle) return "grey";
    if (puzzle.problem_checksum) return "green";
    if (puzzle.name !== "placeholder") return "yellow";
    return "red";
  };

  const onColumnChange = (data, action) => {
    switch (action.action) {
      case "create-option":
        if (onCreateCategory) {
          const category = onCreateCategory(action.option);
          const updatedOptions = data.map((o) =>
            o.value === category.name
              ? { label: category.name, value: category.id }
              : o,
          );
          setSelectedPuzzles(updatedOptions);
          setPuzzles(
            puzzles.concat([
              {
                id: category.id,
                title: category.name,
                puzzles: [],
              },
            ]),
          );
        }
        break;
      case "clear":
        setSelectedPuzzles([]);
        break;
      default:
        setSelectedPuzzles(data);
    }
  };

  return (
    <>
      <ModalPuzzle
        puzzle={puzzle}
        setPuzzle={setPuzzle}
        onChange={onPuzzleChange}
      />
      <Menu attached>
        <PuzzleBoardNavigation />
        <Menu.Item fitted="vertically">
          {onCreateCategory ? (
            <CreatableSelect
              className="select"
              closeMenuOnSelect={false}
              onChange={onColumnChange}
              value={
                selectedPuzzles !== null
                  ? selectedPuzzles
                  : defaultPuzzles.map(({ id, title }) => {
                    return { label: title, value: id };
                  })
              }
              options={puzzles?.map(({ id, title }) => {
                return { value: id, label: title };
              })}
              isMulti
              isSearchable
            />
          ) : (
            <Select
              className="select"
              closeMenuOnSelect={false}
              onChange={onColumnChange}
              value={selectedPuzzles}
              options={puzzles?.map(({ id, title }) => {
                return { value: id, label: title };
              })}
              isMulti
              isSearchable
            />
          )}
        </Menu.Item>

        {auth?.token && (
          <Menu.Item position="right">
            <Checkbox
              toggle
              label="Povolit úpravy"
              onChange={(_e, data) => setAllowEdit(data.checked)}
              checked={allowEdit}
            />
          </Menu.Item>
        )}
        <Menu.Item position={auth?.token ? null : "right"}>
          <Checkbox
            toggle
            label="Zobrazit náhledy"
            onChange={(_e, data) => setShowPreviews(data.checked)}
            checked={showPreviews}
          />
        </Menu.Item>
        <Menu.Item>
          <Button
            icon="refresh"
            loading={puzzles.isFetching}
            onClick={() => {
              invalidate(setPuzzles);
            }}
          />
        </Menu.Item>
      </Menu>
      <Board
        className="ui cards"
        onCardDragEnd={onCardDragEnd}
        onColumnDragEnd={onColumnDragEnd}
        renderCard={(puzzle, { dragging }) => (
          <PuzzleCard
            key={puzzle.id}
            puzzle={puzzle}
            onClick={onCardClick}
            color={colorize(puzzle, dragging)}
            showPreview={showPreviews}
            showTags={showTags}
            setShowTags={setShowTags}
          />
        )}
        allowAddColumn={allowEdit && onAddColumn}
        renderColumnHeader={(column) => (
          <ColumnHeader
            {...column}
            onClick={allowEdit ? onHeaderClick : null}
          />
        )}
        renderColumnAdder={() => <ColumnAdder onAdd={onAddColumn} />}
      >
        {board}
      </Board>
    </>
  );
}
