import React from "react";
import { useSearchParams } from "react-router-dom";
import { Button, Card, Checkbox, Menu, Segment } from "semantic-ui-react";

import "@asseinfo/react-kanban/dist/styles.css";

import { EditContext } from "./EditContext";
import PuzzleCard from "./PuzzleCard";
import ModalPuzzle from "./components/ModalPuzzle";
import { Puzzle } from "./interface";

import "./PuzzleBoard.css";
import PuzzleBoardNavigation from "./PuzzleBoardNavigation";
import { AuthenticationContext } from "./AuthenticationContext";
import { invalidate } from "./api";
import { GameContext } from "./GameContext";

export default function PuzzleCardPanel({
  puzzles,
  setPuzzles,
  onPuzzleChange,
}): JSX.Element {
  const [search, setSearch] = useSearchParams();
  const defaultPuzzle = search.has("puzzle")
    ? { puzzle_id: search.get("puzzle"), name: null, problem_checksum: null }
    : null;

  const game = React.useContext(GameContext);
  const [allowEdit, setAllowEdit] = React.useContext(EditContext);
  const [showPreviews, setShowPreviews] = React.useState(true);
  const [showTags, setShowTags] = React.useState([]);
  const [puzzle, setPuzzle] = React.useState(defaultPuzzle);

  const [auth] = React.useContext(AuthenticationContext);

  React.useEffect(() => {
    if (puzzle) search.set("puzzle", puzzle.puzzle_id);
    else search.delete("puzzle");
    setSearch(search);
  }, [puzzle]);

  const onCardClick = (_, { puzzle }) => {
    setPuzzle(puzzle);
  };

  const colorize = (puzzle: Puzzle) => {
    if (!puzzle) return "grey";
    if (puzzle.problem_checksum) return "green";
    if (puzzle.name !== "placeholder") return "yellow";
    return "red";
  };

  return (
    <>
      <ModalPuzzle
        puzzle={puzzle}
        setPuzzle={setPuzzle}
        onChange={onPuzzleChange}
      />
      <Menu attached>
        <PuzzleBoardNavigation />
        {auth?.token && (
          <Menu.Item position="right">
            <Checkbox
              toggle
              label="Povolit úpravy"
              onChange={(_e, data) => setAllowEdit(data.checked)}
              checked={allowEdit}
            />
          </Menu.Item>
        )}

        {(auth?.token || game?.show_problems) && (
          <Menu.Item position={auth?.token ? null : "right"}>
            <Checkbox
              toggle
              label="Zobrazit náhledy"
              onChange={(_e, data) => setShowPreviews(data.checked)}
              checked={showPreviews}
            />
          </Menu.Item>
        )}
        <Menu.Item position={auth?.token && !game?.show_problems ? null : "right"}>
          <Button
            icon="refresh"
            loading={puzzles.isFetching}
            onClick={() => {
              invalidate(setPuzzles);
            }}
          />
        </Menu.Item>
      </Menu>
      <Segment basic attached className="puzzle-card-board">
        <Card.Group doubling centered stackable>

          {puzzles.data?.map((puzzle: Puzzle) => (
            <PuzzleCard
              key={puzzle.puzzle_id}
              puzzle={puzzle}
              onClick={onCardClick}
              color={colorize(puzzle)}
              showPreview={showPreviews}
              showTags={showTags}
              setShowTags={setShowTags}
            />
          ))}
        </Card.Group>
      </Segment>
    </>
  );
}
