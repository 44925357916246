import React, { Dispatch } from "react";
import { useErrorHandler } from "react-error-boundary";
import {
  List,
  Divider,
  Button,
  Image,
  Label,
  Modal,
  Container,
} from "semantic-ui-react";

import { api, handleResponse } from "../api";
import "./ModalSolution.css";
import { colorize } from "../colors";
import { AuthenticationContext } from "../AuthenticationContext";
import { GameContext } from "../GameContext";

import { Solution, Tagging } from "../interface";
import { EditContext } from "../EditContext";
import SolutionDecorator from "../models/SolutionDecorator";
import { q } from "../Query";

export default function ModalSolution({
  solution,
  open,
  setSolution,
  tags = [],
}: {
  solution: Solution;
  open: boolean;
  setSolution: Dispatch<Solution>;
  tags?: Tagging[] | null;
}) {
  const { code, instructions } = solution || {};
  const handleError = useErrorHandler();
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);
  const [allowEdit, setAllowEdit] = React.useContext(EditContext);

  React.useEffect(() => {
    if (!game) return;
    if (!solution) return;
    if (!open) return;

    api(auth.token, "puzzles")
      .from("solutions")
      .select(
        q([
          "solution_id", "code", "solution", "instructions",
          { "...solution_checksums": ["image_checksum"] },
          { "puzzle_solutions!inner": {"puzzles": ["position", "tag", "name", "slug", { "puzzle_checksums": "problem_checksum" }]} },
          {
            "interpretation_solutions": [
              "interpretation_id",
              {
                "interpretations": ["interpretation_id",
                  {
                    "connections": ["connection_id",
                      {
                        "location_papers": ["location_paper_id",
                          { "locations!location_papers_location_id_fkey": ["location_id", "name"] }
                        ]
                      }
                    ]
                  }]
              }
            ]
          }
        ])
      )
      .eq("solution_id", solution.solution_id)
      .single()
      .then(handleResponse(handleError, setSolution), handleError);
  }, [game, open]);

  const locations =
    solution && solution.interpretation_solutions
      ? solution.interpretation_solutions
        .map((is) =>
          is.interpretations.connections
            .filter((c) => c.location_papers.locations)
            .map((c) => c.location_papers.locations)
        )
        .flat()
      : [];
  const uniqLocations = Array.from(
    new Map(
      locations.filter((l) => !!l).map((l) => [l.location_id, l])
    ).values()
  ).map(({ name }) => name);

  const decoratedSolution = new SolutionDecorator(solution);

  return (
    <Modal
      open={open}
      onClose={() => setSolution(null)}
      size="large"
      className="solution"
    >
      <Modal.Header>
        Šifra{" "}
        {solution?.puzzle_solutions.map((ps) => ps.puzzles.name).join(", ")}
        <Button
          floated="right"
          toggle
          icon="edit"
          active={allowEdit}
          onClick={() => setAllowEdit(true)}
        />
      </Modal.Header>
      <Modal.Content scrolling image={decoratedSolution.hasImage()}>
        {game && solution && decoratedSolution.hasImage() && (
          <Image
            as="a"
            href={decoratedSolution.imageUrl(game)}
            className="puzzle"
            size="medium"
            floated="left"
            src={decoratedSolution.imagePreviewUrl(game)}
          />
        )}
        <Modal.Description>
          <List floated="left">
            {[
              ["Kód", code],
              ["Tajenka", solution && solution.solution],
              ["Další stanoviště", uniqLocations.join("; ")],
            ].map(
              ([key, value]) =>
                value && (
                  <List.Item>
                    <List.Icon name="triangle right" />
                    <List.Content>
                      <List.Header>{key}</List.Header>
                      <List.Description>{value}</List.Description>
                    </List.Content>
                  </List.Item>
                )
            )}
          </List>
          <Divider hidden clearing />
          <Container textAlign="left">{instructions}</Container>
        </Modal.Description>
      </Modal.Content>
      {tags && (
        <Modal.Actions>
          <Label.Group size="tiny">
            {tags.map((t) => (
              <Label key={t.tagging_id} color={colorize(t.tags.tag_id)}>
                {t.tags.name}
              </Label>
            ))}
          </Label.Group>
        </Modal.Actions>
      )}
    </Modal>
  );
}
