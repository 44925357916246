import React from "react";

import "./DashBoard.css";

import GameCard from "./GameCard";
import { q } from "./Query";
import { api, handleData, initialState } from "./api";
import { useErrorHandler } from "react-error-boundary";
import { Card, Dimmer, Divider, Loader, Segment } from "semantic-ui-react";
import { Game } from "./interface";
import GamesSearchBox from "./GamesSearchBox";

function DashBoard() {
  const [games, setGames] = React.useState(initialState());
  const [stats, setStats] = React.useState(initialState());

  const handleError = useErrorHandler();

  React.useEffect(() => {
    api(null, "stats")
      .from("game_statistics")
      .select(q(["game_id", "num_puzzles", "num_teams"]))
      .then(handleData(handleError, setStats), handleError);
  }, [handleError]);

  const now = new Date().getTime();

  React.useEffect(() => {
    document.getElementById('today').scrollIntoView()
  }, [games]);

  return (
    <div className="dashboard">
      <GamesSearchBox games={games} setGames={setGames} />


      <Segment basic attached >
        <Dimmer active={games.isFetching}>
          <Loader></Loader>
        </Dimmer>
        <Card.Group centered doubling>
          {games.data
            ?.filter((g: Game) => Date.parse(g.start) >= now)
            .map((game: Game) => {
              return (
                <GameCard
                  key={game.game_id}
                  game={{
                    ...game,
                    ...stats.data?.find(({ game_id }) => game_id === game.game_id),
                  }}
                />
              );
            })}
        </Card.Group>
        <Divider id="today" />
        <Card.Group centered doubling>
          {games.data
            ?.filter((g: Game) => Date.parse(g.start) < now)
            .map((game: Game) => {
              return (
                <GameCard
                  key={game.game_id}
                  game={{
                    ...game,
                    ...stats.data?.find(({ game_id }) => game_id === game.game_id),
                  }}
                />
              );
            })}
        </Card.Group>
      </Segment>
    </div>
  );
}

export default DashBoard;

// <pre>{JSON.stringify(games, null ,4)}</pre>
