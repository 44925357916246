import React from "react";
import { Button, Divider, Form, Header, Segment } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";

import "./Location.css";
import "./NodePanel.css";
import { GameContext } from "./GameContext";

import { EditContext } from "./EditContext";

export default function LocationPaperDetail({
  onCreate,
  onClose,
}: {
  onCreate: (data: any) => void;
  onClose: () => void;
}) {
  const game = React.useContext(GameContext);

  const { register, unregister, control, setValue, handleSubmit } = useForm();


  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  // React.useEffect(() => {
  //    unregister(["code", "final", "note"]);
  // }, [open]);

  const onSubmit = (data: any) => {
    console.log(data);
    if (!data) return;

    onCreate({
      paper: {
        code: data.paper_code,
      },
      puzzle: {
        code: data.puzzle_code,
        name: data.puzzle_name,
        position: data.puzzle_position,
        tag: data.puzzle_tag,
      },
      solution: {
        code: data.solution_code,
      },
      location: {
        name: data.location_name,
      },
    });

    setValue("paper_code", null);
    setValue("puzzle_position", (parseInt(data.puzzle_position) || 0) + 1);
    setValue("puzzle_name", null);
    setValue("puzzle_tag", null);
    setValue("puzzle_code", null);
    setValue("solution_code", null);
    setValue("location_name", null);
  };

  return (
    <Segment attached >
      <Button.Group floated="right">
        <Button icon="close" basic onClick={onClose} />
      </Button.Group>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Header content="Stanoviště" as="h4" />
        <Form.Group>
          <Form.Field>
            <label htmlFor="paper_code">Kód</label>
            <input {...register("paper_code")} defaultValue="placeholder" />
          </Form.Field>
        </Form.Group>
        <Header content="Šifra" as="h4" />
        <Form.Group>
          <Form.Field>
            <label htmlFor="puzzle_position">Číslo</label>
            <input
              {...register("puzzle_position")}
              defaultValue="1"
              type="number"
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="puzzle_tag">Tag</label>
            <input {...register("puzzle_tag")} />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label htmlFor="puzzle_name">Název</label>
          <input {...register("puzzle_name", { required: true })} />
        </Form.Field>
        <Form.Field>
          <label htmlFor="puzzle_code">Kód</label>
          <input {...register("puzzle_code")} />
        </Form.Field>
        <Header content="Řešení" as="h4" />
        <Form.Group>
          <Form.Field>
            <label htmlFor="solution_code">Kód</label>
            <input {...register("solution_code")} />
          </Form.Field>
        </Form.Group>
        <Header content="Poloha stanoviště" as="h4" />
        <Form.Field>
          <label htmlFor="location_name">Název</label>
          <input {...register("location_name")} />
        </Form.Field>

        <Button type="submit" primary fluid>
          Přidat
        </Button>
      </Form>

    </Segment>
  );
}
