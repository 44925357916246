import React from "react";
import { useErrorHandler } from "react-error-boundary";
import Select from "react-select";
import { Checkbox, Menu, Button, Segment, Modal, ModalHeader, ModalContent, ModalDescription, Header, ModalActions } from "semantic-ui-react";

import { api, handleResponse } from "./api";
import Query from "./Query";
import "./DataGrid.css";
import Table from "./Table";
import TableGroup from "./TableGroup";

import { AuthenticationContext } from "./AuthenticationContext";
import { GameContext } from "./GameContext";
import { EditContext } from "./EditContext";

export default function DataGrid({
  viewSelect,
  table,
  data,
  setData,
  groupColumn,
  primaryKey,
  setGroupColumn,
  sortColumn,
  setSortColumn,
  visibleColumns,
  setVisibleColumns,
  selectedCategories,
  setSelectedCategories,
  onRowClick,
  children,
}) {
  const handleError = useErrorHandler();
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);
  const [allowEdit, setAllowEdit] = React.useContext(EditContext);

  const [openSettings, setOpenSettings] = React.useState(false)

  const viewColumns = React.useMemo(() => {
    return Array.isArray(children)
      ? children.map(({ props }) => props)
      : [children.props];
  }, [children]);

  const requestData = () => {
    setData({ ...data, isFetching: true });
  };
  const receiveData = (data: any[]) => {
    setData({
      didInvalidate: false,
      items: data,
      isFetching: false,
      lastUpdated: new Date(),
    });
  };

  // Load data
  React.useEffect(() => {
    if (data.isFetching) return;
    if (!game) return;
    if (!data.didInvalidate) return;
    if (visibleColumns.length === 0) return;

    requestData();

    const query = viewColumns.reduce(
      (query: Query, col) => query.merge(col.select || [col.name]),
      new Query().merge(primaryKey),
    );

    if (query.isEmpty()) return;
    api(auth.token, "puzzles")
      .from(table)
      .select(query.toString())
      .eq("game_id", game.game_id)
      .then(handleResponse(handleError, receiveData), handleError);
  }, [game, table, data, auth.token, visibleColumns]);

  const columns = React.useMemo(() => {
    return viewColumns
      .filter(({ name }) => visibleColumns.some((vc) => name === vc))
      .filter(({ hidden }) => !hidden)
      .map((col) => {
        const join = col.join ? col.join : (o) => o;

        return {
          frozen: false,
          select: () => col.name,
          compare: (a, b) => {
            const va = a[col.name];
            const vb = b[col.name];
            if (va === vb) return 0;
            if (va === null) return 1;
            if (vb === null) return -1;
            return va.localeCompare(vb);
          },
          renderItem: col.renderItem || (([, o]) => o),
          render:
            col.type === "collection"
              ? (o) => join(col.getItems(o).map(col.renderItem))
              : (o) => o[col.name],
          ...col,
        };
      });
  }, [viewColumns, visibleColumns]);

  const sortedRows: any = React.useMemo(() => {
    if (!data.items) return [];
    if (!sortColumn) return data;

    const { columnKey, direction } = sortColumn;
    const column = columns.find(({ name }) => name === columnKey);
    if (!column) return data.items;

    let sortedRows: any = [...data.items];
    sortedRows = sortedRows.sort(column.compare);

    if (direction === "descending") sortedRows.reverse();
    return sortedRows;
  }, [data, sortColumn]);

  const rows: any = React.useMemo(() => {
    return sortedRows.map((row) => {
      return { id: row[primaryKey], ...row };
    });
  }, [sortedRows, primaryKey]);

  const handleSort = (sort: string) => {
    const column = columns.find(({ name }) => name === sort);
    if (column.type === "collection") return;
    const direction =
      sortColumn && sortColumn.direction === "ascending"
        ? "descending"
        : "ascending";
    setSortColumn({ columnKey: sort, direction: direction });
  };

  const onVisibleColumnsChange = (_, action) => {
    switch (action.action) {
      case "select-option":
        setVisibleColumns([...visibleColumns, action.option.value]);
        break;
      case "remove-value":
        setVisibleColumns(
          visibleColumns.filter((c) => c !== action.removedValue.value),
        );
        break;
      case "clear":
        setVisibleColumns([]);
        break;
      default:
        console.log("Unsupported action", action);
    }
  };

  const groupColumnName = groupColumn && groupColumn.value;
  const group = columns.find((c) => c.name === groupColumnName);

  const categories = !group
    ? null
    : group.type === "collection"
      ? Array.from(
        new Map(
          (data.items || [])
            .map((o: any) => group.getItems(o))
            .flat()
            .filter((arr) => arr.length === 2),
        ).entries(),
      ).sort(([, a], [, b]) => {
        return group.compare(a, b);
      })
      : Array.from(new Set(data.items.map((o: any) => o[group.name])).values())
        .sort()
        .map((o, i) => [i, o]);

  let visibleCategories = [];
  if (categories) {
    if (selectedCategories && selectedCategories.length > 0) {
      visibleCategories = selectedCategories
        .filter((sc) => categories.some(([i]) => i === sc.value))
        .map((sc) => categories.find(([i]) => i === sc.value));
    } else {
      visibleCategories = categories;
    }
  }

  const onGroupColumnChange = (option: { label: string; value: string }) => {
    setSelectedCategories(null);
    setGroupColumn(option);
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      minWidth: "300px",
      whiteSpace: "nowrap",
    }),
  };

  const onMyViewKeyChange = (viewKey: { label: string; value: string }) => {
    onMyViewKeyChange(viewKey);
    setSelectedCategories([]);
  };

  return (
    <>

      <Modal
        onClose={() => setOpenSettings(false)}
        onOpen={() => setOpenSettings(true)}
        open={openSettings}
      >
        <ModalHeader>Nastavení</ModalHeader>
        <ModalContent image>
          <ModalDescription>
            <Header>Zobrazené sloupce</Header>
            <Select
              isClearable={false}
              value={visibleColumns.map((c) => {
                return {
                  label: viewColumns.some(({ name }) => name === c)
                    ? viewColumns.find(({ name }) => name === c).title
                    : null,
                  value: c,
                };
              })}
              onChange={onVisibleColumnsChange}
              options={viewColumns.map((c) => {
                return { label: c.title, value: c.name };
              })}
              closeMenuOnSelect={false}
              isSearchable
              isMulti
            />
            <Header>Seskupení</Header>
            <Select
              value={groupColumn}
              onChange={onGroupColumnChange}
              options={columns.map((c) => {
                return { label: c.title, value: c.name };
              })}
              isSearchable
              isClearable
            />


            {group && (
              <>
                <Header>Zobrazené skupiny</Header>
                <Select
                  styles={customStyles}
                  value={selectedCategories}
                  onChange={setSelectedCategories}
                  options={categories.map(([i, c]) => {
                    return {
                      label: group.renderItem ? group.renderItem([i, c]) : c,
                      value: i,
                    };
                  })}
                  isSearchable
                  isMulti
                  closeMenuOnSelect={false}
                />
              </>

            )}



          </ModalDescription>
        </ModalContent>
        <ModalActions>
          <Button
            content="Zavřít"
            onClick={() => setOpenSettings(false)}
            primary
          />
        </ModalActions>
      </Modal>

      <Menu pointing secondary attached>
        {viewSelect}
        {auth?.token && (
          <Menu.Item position="right">
            <Checkbox
              toggle
              label="Povolit úpravy"
              onChange={(e, data) => setAllowEdit(data.checked)}
              checked={allowEdit}
            />
          </Menu.Item>
        )}
        <Menu.Item position={auth.token ? null : "right"}>
          <Button
            icon="settings"
            onClick={() => setOpenSettings(true)}
          />
        </Menu.Item>
        <Menu.Item>
          <Button
            icon="refresh"
            loading={data.isFetching}
            onClick={() => setData({ ...data, didInvalidate: true })}
          />
        </Menu.Item>
      </Menu>
      {group ? (
        <Segment attached>
          <TableGroup
            data={rows}
            groupBy={group}
            columns={columns}
            categories={visibleCategories}
            sortColumn={sortColumn}
            handleSort={handleSort}
            onRowClick={onRowClick}
          />
        </Segment>
      ) : (
        <Segment attached>
          <Table
            id={table}
            game={game}
            columns={columns}
            handleSort={handleSort}
            rows={rows}
            sortColumn={sortColumn}
            onRowClick={onRowClick}
          />
        </Segment>
      )}
    </>
  );
}
