import { List, Popup } from "semantic-ui-react";

import DataGridCollectionColumn from "../..//DataGridCollectionColumn";
import { Location, Puzzle } from "../../interface";

export const PuzzleSolutionColumn = (
  <DataGridCollectionColumn
    name="puzzle_solutions"
    title="Další stanoviště"
    type="collection"
    frozen={true}
    width={5}
    select={{
      puzzle_solutions: {
        solutions: [
          "solution_id",
          "code",
          "solution",
          {
            interpretation_solutions: {
              interpretations: {
                connections: [
                  "connection_id",
                  "specification",
                  {
                    location_papers: {
                      ["locations!location_papers_location_id_fkey"]: [
                        "location_id",
                        "name",
                      ],
                    },
                  },
                ],
              },
            },
          },
        ],
      },
    }}
    compare={(a: Location, b: Location) =>
      a.name && a.name.localeCompare(b.name)
    }
    renderItem={([, location]) => location.name}
    join={(arr) => arr.sort().join(", ")}
    getItems={(puzzle: Puzzle) =>
      puzzle.paper_puzzles
        ? puzzle.paper_puzzles
            .filter((pp) => pp.papers)
            .map((pp) =>
              pp.papers.location_papers
                .filter((lp) => lp.locations)
                .map((lp) => [lp.locations.location_id, lp.locations])
                .flat()
            )
        : []
    }
    render={(puzzle: Puzzle) =>
      puzzle.puzzle_solutions
        ? puzzle.puzzle_solutions
            .filter((ps) => ps.solutions)
            .filter((ps) => ps.solutions.interpretation_solutions)
            .map((ps) =>
              ps.solutions.interpretation_solutions.map((is) =>
                is.interpretations.connections
                  .filter((c) => c.location_papers.locations)
                  .map((c) => (
                    <Popup
                      wide="very"
                      key={c.connection_id}
                      trigger={
                        <a className="puzzle-link">
                          {c.location_papers.locations.name}
                        </a>
                      }
                    >
                      <Popup.Content>
                        <List floated="left">
                          {[
                            ["Kód", ps.solutions.code],
                            ["Řešení", ps.solutions.solution],
                            ["Upřesnítko", c.specification],
                          ].map(
                            ([key, value]) =>
                              value && (
                                <List.Item key={key}>
                                  <List.Icon name="triangle right" />
                                  <List.Content>
                                    <List.Header>{key}</List.Header>
                                    <List.Description>{value}</List.Description>
                                  </List.Content>
                                </List.Item>
                              )
                          )}
                        </List>
                      </Popup.Content>
                    </Popup>
                  ))
              )
            )
            .flat()
            .flat()
        : []
    }
  />
);
