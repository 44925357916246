import DataGridCollectionColumn from "../..//DataGridCollectionColumn";
import { Label } from "semantic-ui-react";
import { Solution, Tag } from "../../interface";
import { colorize } from "../../colors";

export const SolutionTagColumn = (
  <DataGridCollectionColumn
    name="solution_tags"
    title="Principy"
    type="collection"
    width={1}
    frozen={true}
    select={{
      solution_tags: [
        "solution_tag_id",
        {
          tags: ["tag_id", "name"]
        }
      ]
    }}
    compare={(a: Tag, b: Tag) => a.name.localeCompare(b.name)}
    renderItem={([id, tag]) => (
      <Label key={id} color={colorize(tag.tag_id)} size="mini">
        {tag.name}
      </Label>
    )}
    getItems={(solution: Solution) =>
      solution.solution_tags
        ? solution.solution_tags.map(t => {
            return [t.tags.tag_id, t.tags];
          })
        : []
    }
  />
);
