import React from "react";
import { useErrorHandler } from "react-error-boundary";
import CreatableSelect from "react-select/creatable";
import { v4 as uuidv4 } from "uuid";

import { api, handleResponse } from "../api";
import "./ModalSolution.css";
import { AuthenticationContext } from "../AuthenticationContext";
import { GameContext } from "../GameContext";

export default function TagInput({
  tags,
  setTags,
  tagType,
  primaryKey,
  foreignKey,
  table,
  id,
  onChange
}) {
  const [taggings, setTaggings] = React.useState([]);
  const handleError = useErrorHandler();
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);

  React.useEffect(() => {
    if (!game) return;
    if (!id) return;
    api(auth.token, "puzzles")
      .from(table)
      .select(`${foreignKey},tag_id,tags(tag_id,tag_id,name) `)
      .eq(foreignKey, id)
      .order("name", { foreignTable: "tags" })
      .then(handleResponse(handleError, setTaggings), handleError);
  }, [game, id]);

  const updateTaggings = (_, action) => {
    switch (action.action) {
      case "create-option":
        if (!auth.token) return;
        const tag_id = uuidv4();
        const tag = {
          tag_id: tag_id,
          name: action.option.value,
          type: tagType
        };
        api(auth.token, "puzzles")
          .from("tags")
          .insert(tag)
          .then(
            handleResponse(handleError, () => {
              const tagging = {
                game_id: game.game_id,
                [primaryKey]: uuidv4(),
                [foreignKey]: id,
                tag_id: tag_id
              };
              api(auth.token, "puzzles")
                .from(table)
                .insert(tagging)
                .then(
                  handleResponse(handleError, () =>
                    setTaggings([
                      ...taggings,
                      {
                        ...tagging,
                        tags: {
                          id: tag_id,
                          name: action.option.label
                        }
                      }
                    ])
                  ),
                  handleError
                );
            }),
            handleError
          );
        setTags(tags.concat([{ label: tag.name, value: tag.tag_id }]));
        break;

      case "select-option":
        const tagging = {
          game_id: game.game_id,
          [primaryKey]: uuidv4(),
          [foreignKey]: id,
          tag_id: action.option.value
        };
        api(auth.token, "puzzles")
          .from(table)
          .insert(tagging)
          .then(
            handleResponse(handleError, () =>
              setTaggings([
                ...taggings,
                {
                  ...tagging,
                  tags: {
                    id: action.option.value,
                    name: action.option.label
                  }
                }
              ])
            ),
            handleError
          );
        break;
      case "remove-value":
        api(auth.token, "puzzles")
          .from(table)
          .delete()
          .match({
            [foreignKey]: id,
            tag_id: action.removedValue.value
          })
          .then(
            handleResponse(handleError, () =>
              setTaggings(
                taggings.filter(pt => pt.tag_id !== action.removedValue.value)
              )
            ),
            handleError
          );
        break;
      case "clear":
        api(auth.token, "puzzles")
          .from(table)
          .delete()
          .match({
            [foreignKey]: id
          })
          .then(
            handleResponse(handleError, () => setTaggings([])),
            handleError
          );

        break;
      default:
        console.log("Unsupported action", action);
    }
    onChange();
  };

  const options = React.useMemo(() => {
    return tags
      .filter(({ tag_id }) => !taggings.some(pt => pt.tag_id === tag_id))
      .map(({ tag_id, name }) => {
        return { value: tag_id, label: name };
      });
  }, [tags, taggings]);

  return (
    <CreatableSelect
      className="select"
      closeMenuOnSelect={false}
      onChange={updateTaggings}
      value={taggings.map(t => {
        return { value: t.tag_id, label: t.tags.name };
      })}
      options={options}
      isMulti
      isSearchable
    />
  );
}
