import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { Button, Container, List, Image, Modal } from "semantic-ui-react";

import { q } from "../Query";
import { api, handleResponse } from "../api";
import { AuthenticationContext } from "../AuthenticationContext";
import { GameContext } from "../GameContext";
import { EditContext } from "../EditContext";

export default function ModalPaperCard({
  paper,
  open,
  setPaper,
}) {
  const { paper_id, code, final, note } = paper || {};
  const handleError = useErrorHandler();
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);
  const [allowEdit, setAllowEdit] = React.useContext(EditContext);

  React.useEffect(() => {
    if (!game) return;
    if (!paper) return;

    api(auth.token, "puzzles")
      .from("papers")
      .select(
        q([
          "paper_id",
          "code",
          "final",
          "note",
          {
            paper_puzzles: [
              "paper_puzzle_id",
              { puzzles: ["puzzle_id", "position", "tag", "name", "slug"] }
            ],
            location_papers: [
              "location_paper_id",
              {
                ["locations!location_papers_location_id_fkey"]: [
                  "location_id",
                  "name"
                ]
              }
            ]
          }
        ])
      )
      .eq("paper_id", paper.paper_id)
      .single()
      .then(handleResponse(handleError, setPaper), handleError);
  }, [game, open]);

  return (
    <Modal
      open={open}
      onClose={() => setPaper(null)}
      size="large"
      className="paper"
    >
      <Modal.Header>
        Zadání {code}
        <Button
          floated="right"
          icon="edit"
          active={allowEdit}
          onClick={() => setAllowEdit(true)}
        />
      </Modal.Header>
      <Modal.Content scrolling image>
        {game && paper && paper.problem_checksum && (
          <Image
            as="a"
            href={`https://statek.seslost.cz/${game.name}/zadani/${code}.pdf?checksum=${paper.problem_checksum}`}
            className="paper"
            size="medium"
            wrapped
            src={`https://statek.seslost.cz/${
              game.name
            }/sifry/${paper_id}.png?style=preview&checksum=${paper.problem_checksum}`}
          />
        )}
        <Modal.Description>
          {paper &&
            paper.paper_puzzles &&
            paper.paper_puzzles.map(pp => {
              const { code, position, tag, name } = pp.puzzles;
              return (
                <List key={`list-${pp.paper_puzzle_id}`}>
                  {
                    <List.Item>
                      <List.Icon name="triangle right" />
                      <List.Content>
                        <List.Header>Šifra</List.Header>
                        <List.Description>
                          {position}
                          {tag} – {name}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  }
                </List>
              );
            })}
          <List>
            <List.Item>
              <List.Icon name="marker" />
              <List.Content>
                <List.Header>Poloha</List.Header>
                <List.Description>
                  {paper &&
                    paper.location_papers &&
                    paper.location_papers
                      .filter(lp => lp.locations)
                      .map(lp => lp.locations.name)
                      .join(", ")}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
          <Container text>{note}</Container>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}
