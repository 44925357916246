import { SemanticCOLORS } from "semantic-ui-react";
import { parse } from "uuid";

export const colors: SemanticCOLORS[] = [
  "red",
  "green",
  "teal",
  "blue",
  "violet",
  "pink",
  "brown",
  "purple",
  "grey",
  "orange",
  "olive"
];

export const colorize = uuid => {
  let buffer = parse(uuid);
  const hash = buffer.reduce((a: number, b: number) => a + b, 0);
  return colors[hash % colors.length];
};
