import DataGridCollectionColumn from "../..//DataGridCollectionColumn";
import { Stage, Connection } from "../../interface";

export const StageColumn = (
  <DataGridCollectionColumn
    name="stages"
    title="Část hry"
    type="collection"
    frozen={true}
    width={2}
    select={{
      interpretations: {
        interpretation_solutions: {
          solutions: {
            puzzle_solutions: {
              puzzles: {
                puzzle_stages: {
                  stages: ["stage_id", "position", "name", "title"]
                }
              }
            }
          }
        }
      }
    }}
    compare={(a: Stage, b: Stage) => a.position - b.position}
    renderItem={([, stage]) => stage.title}
    getItems={(connection: Connection) =>
      connection.interpretations &&
      connection.interpretations.interpretation_solutions
        .map(({ solutions }) => solutions)
        .map(({ puzzle_solutions }) => puzzle_solutions)
        .flat()
        .map(({ puzzles }) => puzzles)
        .map(({ puzzle_stages }) => puzzle_stages)
        .flat()
        .map(({ stages }) => [stages.stage_id, stages])
    }
  />
);
