import DataGridCollectionColumn from "../..//DataGridCollectionColumn";
import { Location, Paper } from "../../interface";

export const LocationPaperColumn = (
  <DataGridCollectionColumn
    name="location_papers"
    title="Umístění"
    type="collection"
    frozen={true}
    width={5}
    select={{
      location_papers: [
        "location_paper_id",
        {
          "locations!location_papers_location_id_fkey": [
            "location_id",
            "name"
          ]
        }
      ]
    }}
    compare={(a: Location, b: Location) => a.name && a.name.localeCompare(b.name)}
    renderItem={([, location]) => location.name}
    join={arr => arr.sort().join(", ")}
    getItems={(paper: Paper) =>
      paper.location_papers
        ? paper.location_papers
            .filter(lp => lp.locations)
            .map(lp => [lp.locations.location_id, lp.locations])
        : []
    }
  />
);
