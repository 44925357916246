import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { Button, Image, Form, Modal } from "semantic-ui-react";
import { useForm } from "react-hook-form";

import { api, handleResponse } from "../api";
import "./ModalSolution.css";
import TagInput from "./TagInput";
import { AuthenticationContext } from "../AuthenticationContext";
import { GameContext } from "../GameContext";
import { EditContext } from "../EditContext";

export default function ModalSolutionForm({
  solution,
  open,
  setSolution,
  onChange,
}) {
  const { register, unregister, handleSubmit } = useForm();

  const { code, instructions } = solution || {};
  const handleError = useErrorHandler();
  const [allTags, setAllTags] = React.useState([]);
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);
  const [allowEdit, setAllowEdit] = React.useContext(EditContext);

  React.useEffect(() => {
    if (!game) return;
    if (!solution) return;

    api(auth.token, "puzzles")
      .from("solutions")
      .select(
        `
        solution_id,code,solution,instructions,
          puzzle_solutions!inner(
            puzzles!inner(position,tag,name,slug,
           ...puzzle_checksums(problem_checksum))),
          interpretation_solutions(interpretation_id,
              interpretations(interpretation_id,
                  connections(connection_id,
                      location_papers(location_paper_id,
                          locations!location_papers_location_id_fkey(location_id,name)))))
       `
      )
      .eq("solution_id", solution.solution_id)
      .single()
      .then(handleResponse(handleError, setSolution), handleError);
  }, [game, open]);

  React.useEffect(() => {
    if (!open) unregister(["code", "solution", "instructions"]);
  }, [open]);

  React.useEffect(() => {
    if (!game) return;
    api(auth.token, "puzzles")
      .from("tags")
      .select("tag_id,name")
      .order("name")
      .eq("type", "Solution")
      .then(handleResponse(handleError, setAllTags), handleError);
  }, [game]);

  const onSubmit = data => {
    if (!solution) return;

    api(auth.token, "puzzles")
      .from("solutions")
      .update(data)
      .match({ solution_id: solution.solution_id })
      .then(
        response => {
          if (response.error) {
            handleError(response.error.message);
          } else {
            setSolution(null);
            onChange();
          }
        },
        error => handleError(error)
      );
  };

  return (
    <Modal
      open={open}
      onClose={() => setSolution(null)}
      size="large"
      className="solution"
      as={Form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Modal.Header>
        Šifra{" "}
        {solution &&
          solution.puzzle_solutions.map(ps => ps.puzzles.name).join(", ")}
        <Button
          floated="right"
          icon="edit"
          active={allowEdit}
          disabled={!auth?.token}
          onClick={() => setAllowEdit(false)}
        />
      </Modal.Header>
      <Modal.Content image>
        {game &&
          solution &&
          solution.puzzle_solutions
            .filter(ps => ps.puzzles.problem_checksum)
            .map(ps => ps.puzzles.slug)
            .map((slug: string) => (
              <Image
                as="a"
                href={`https://statek.seslost.cz/${
                  game.name
                }/sifry/${slug}.pdf`}
                className="solution"
                size="medium"
                src={`https://statek.seslost.cz/${
                  game.name
                }/sifry/${slug}.png?style=preview`}
              />
            ))}
        <Modal.Description>
          <Form.Field>
            <label htmlFor="code">Kód</label>
            <input {...register("code")} defaultValue={code} />
          </Form.Field>
          <Form.Field>
            <label htmlFor="solution">Tajenka</label>
            <input
              {...register("solution")}
              defaultValue={solution && solution.solution}
            />
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Content>
        <Modal.Description>
          <Form.Field>
            <label htmlFor="instructions">Postup</label>
            <textarea
              {...register("instructions")}
              defaultValue={instructions}
            />
          </Form.Field>
          {solution && (
            <TagInput
              id={solution.solution_id}
              tags={allTags}
              setTags={setAllTags}
              primaryKey="solution_tag_id"
              foreignKey="solution_id"
              table="solution_tags"
              tagType="Solution"
              onChange={onChange}
            />
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button type="submit" primary>
          Uložit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
