import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { Button, TextArea, List, Form, Modal } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";

import { q } from "../Query";
import { api, handleResponse, clear, receive, handleData } from "../api";
import { AuthenticationContext } from "../AuthenticationContext";
import { GameContext } from "../GameContext";

import { Connection } from "../interface";
import { EditContext } from "../EditContext";

export default function ModalConnection({
  connection,
  setConnection,
  onChange,
}) {
  const { unregister, control, handleSubmit } = useForm();

  const { specification } = connection.data || {};
  const handleError = useErrorHandler();
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);
  const open = connection.data !== null;
  const [allowEdit, setAllowEdit] = React.useContext(EditContext);

  React.useEffect(() => {
    if (!game) return;
    if (!connection.didInvalidate || connection.isLoading) return;
    if (!connection.data) return;

    api(auth.token, "puzzles")
      .from("connections")
      .select(
        q([
          "connection_id",
          "tag",
          "specification",
          {
            interpretations: {
              interpretation_solutions: {
                solutions: [
                  ["solution_id", "code", "solution"],
                  {
                    puzzle_solutions: {
                      puzzles: ["puzzle_id", "position", "tag", "name"],
                    },
                  },
                ],
              },
            },
            location_papers: [
              "location_paper_id",
              {
                "locations!location_papers_location_id_fkey": [
                  "location_id",
                  "name",
                ],
              },
            ],
          },
        ])
      )
      .eq("connection_id", connection.data.connection_id)
      .single()
      .then(handleData(handleError, setConnection), handleError);
  }, [game, open, auth, connection]);

  React.useEffect(() => {
    if (!open) unregister(["tag", "specification"]);
  }, [open, unregister]);

  const onSubmit = (data: Connection) => {
    if (!connection.data) return;

    api(auth.token, "puzzles")
      .from("connections")
      .update(data)
      .match({ connection_id: connection.data.connection_id })
      .then(
        handleResponse(handleError, () => {
          onChange();
          clear(setConnection);
        }),
        handleError
      );
  };

  const handleClose = () => clear(setConnection);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      as={Form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Modal.Header>
        Upřesnítko
        <Button
          floated="right"
          icon="edit"
          active={allowEdit}
          disabled={!auth?.token}
          onClick={() => setAllowEdit(false)}
        />
      </Modal.Header>
      <Modal.Content scrolling>
        <List>
          <List.Item>
            <List.Icon name="puzzle" />
            <List.Content>
              <List.Header>Šifra</List.Header>
              <List.Description>
                {connection.data?.interpretations?.interpretation_solutions
                  .map(({ solutions }) => solutions)
                  .map(({ puzzle_solutions }) => puzzle_solutions)
                  .flat()
                  .map(({ puzzles }) => puzzles.name)
                  .join(", ")}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="check" />
            <List.Content>
              <List.Header>Řešení</List.Header>
              <List.Description>
                {connection.data?.interpretations?.interpretation_solutions
                  .map(({ solutions }) => solutions.solution)
                  .join(", ")}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="marker" />
            <List.Content>
              <List.Header>Poloha</List.Header>
              <List.Description>
                {connection.data?.location_papers?.locations.name}
              </List.Description>
            </List.Content>
          </List.Item>
        </List>{" "}
        <Modal.Description>
          {specification !== undefined && (
            <Form.Field>
              <label htmlFor="specification">Upřesnítko</label>
              <Controller
                name="specification"
                control={control}
                defaultValue={specification || ""}
                render={({ field }) => <TextArea {...field} />}
              />
            </Form.Field>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button type="submit" primary>
          Uložit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
