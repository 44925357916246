import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { Button, Card, Header, Image, Segment } from "semantic-ui-react";

import { api, handleResponse } from "./api";
import "./Location.css";
import { AuthenticationContext } from "./AuthenticationContext";
import { GameContext } from "./GameContext";

import { Location, LocationPaper, Paper, Puzzle } from "./interface";
import { EditContext } from "./EditContext";

export default function LocationPaperDetail({
  locationPaper,
  setLocationPaper,
  onPuzzleDestroy,
  onPaperDestroy,
}: {
  locationPaper: LocationPaper;
  setLocationPaper: (locationPaper: LocationPaper) => void;
  onPuzzleDestroy: (puzzle: Puzzle) => void;
  onPaperDestroy: (paper: Paper) => void;
}) {
  const [photos, setPhotos] = React.useState([]);
  const game = React.useContext(GameContext);

  const handleClearLocationPaperClick = () => setLocationPaper(null);
  const availablePhotos = locationPaper?.locations?.photos || [];

  const handleDestroyLocationPaperClick = () => {
    onPaperDestroy(locationPaper.papers);
    setLocationPaper(null);
  };

  return (
    <div>
      <Segment attached>
        <Button.Group floated="right">
          <Button
            icon="trash"
            negative
            onClick={handleDestroyLocationPaperClick}
          />
        </Button.Group>

        {locationPaper && (
          <Header
            content={locationPaper.locations?.name}
            as="h3"
            subheader={locationPaper.papers.code}
          />
        )}
      </Segment>

      {game && availablePhotos.length > 0 && (
        <Image
          as="a"
          className="location"
          onClick={() => setPhotos(availablePhotos)}
          src={`https://trakar.seslost.cz/${game.name}/fotky/${
            (availablePhotos[0] as any).photo_id
          }.png?style=medium`}
          fluid
        />
      )}

      {game &&
        locationPaper.papers?.paper_puzzles
          .filter((paper_puzzle) => paper_puzzle.puzzles)
          .map((paper_puzzle) => {
            const puzzle = paper_puzzle.puzzles;
            const paper = locationPaper.papers;
            return (
              <Card
                centered
                key={`${locationPaper.location_paper_id}---${paper_puzzle.paper_puzzle_id}`}
                puzzle={puzzle}
              >
                {puzzle.problem_checksum && (
                  <Image
                    as="a"
                    href={`https://statek.seslost.cz/${game.name}/sifry/${puzzle.slug}.pdf?checksum=${puzzle.problem_checksum}`}
                    src={`https://statek.seslost.cz/${game.name}/sifry/${puzzle.slug}.png?style=preview&checksum=${puzzle.problem_checksum}`}
                    wrapped
                    ui={false}
                  />
                )}
                <Card.Content>
                  <Card.Header>
                    <Button.Group floated="right">
                      <Button
                        icon="trash"
                        negative
                        size="mini"
                        onClick={() => onPuzzleDestroy(puzzle)}
                      />
                    </Button.Group>

                    {`${puzzle.position + puzzle.tag} — ${puzzle.name}`}
                  </Card.Header>
                </Card.Content>
                {puzzle.code && (
                  <Card.Content extra>{puzzle.code}</Card.Content>
                )}
              </Card>
            );
          })}
    </div>
  );
}
