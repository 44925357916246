export default function DataGridCollectionColumn({
  name,
  title,
  frozen = true,
  type = "string",
  width = null,
  select = null,
  compare = null,
  render = null,
  getItems = null,
  renderItem = null,
  join = null
}) {
  return null;
}
