import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { Button, TextArea, Form, Modal } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";

import { api, handleResponse } from "../api";
import { AuthenticationContext } from "../AuthenticationContext";
import { GameContext } from "../GameContext";

import { Paper } from "../interface";
import { EditContext } from "../EditContext";

export default function ModalPaper({
  paper,
  open,
  setPaper,
  onChange,
}) {
  const { register, unregister, control, handleSubmit } = useForm();

  const { code, note, final } = paper || {};
  const handleError = useErrorHandler();
  const [auth] = React.useContext(AuthenticationContext);
  const game = React.useContext(GameContext);
  const [allowEdit, setAllowEdit] = React.useContext(EditContext);

  React.useEffect(() => {
    if (!game) return;
    if (!paper) return;

    api(auth.token, "puzzles")
      .from("papers")
      .select(
        `
        paper_id,code,final,note
       `
      )
      .eq("paper_id", paper.paper_id)
      .single()
      .then(
        handleResponse(handleError, setPaper),
        handleError
      );
  }, [game, open, allowEdit]);

  React.useEffect(() => {
    if (!open) unregister(["code", "final", "note"]);
  }, [open]);

  const onSubmit = (data: Paper) => {
    if (!paper) return;

    api(auth.token, "puzzles")
      .from("papers")
      .update(data)
      .match({ paper_id: paper.paper_id })
      .then(
        response => {
          if (response.error) {
            handleError(response.error.message);
          } else {
            onChange();
            setPaper(null);
          }
        },
        error => handleError(error)
      );
  };

  return (
    <Modal
      open={open}
      onClose={() => setPaper(null)}
      as={Form}
      size="large"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Modal.Header>
        Zadání {code}
        <Button
          floated="right"
          icon="edit"
          active={allowEdit}
          onClick={() => setAllowEdit(false)}
        />
      </Modal.Header>
      <Modal.Content scrolling image>
        {/*game && paper && (
          <Image
            as="a"
            className="paper"
            size="medium"
            wrapped
            href={`https://statek.seslost.cz/${game.name}/zadani/${
              paper.paper_id
            }.pdf`}
            src={`https://statek.seslost.cz/${
              game.name
            }/sifry/${paper_id}.png?style=preview`}
          />
        )*/}
        <Modal.Description>
          <Form.Group>
            <Form.Field>
              <label htmlFor="code">Kód</label>
              <input {...register("code")} defaultValue={code} />
            </Form.Field>
            {final !== undefined && (
              <Form.Field>
                <label htmlFor="final">Cíl?</label>
                <Controller
                  name="final"
                  defaultValue={final}
                  control={control}
                  render={props => (
                    <Form.Checkbox
                      checked={props.field.value === true}
                      onChange={(_e, input) => {
                        props.field.onChange(input.checked);
                      }}
                    />
                  )}
                />
              </Form.Field>
            )}
          </Form.Group>
          {note && (
            <Form.Field>
              <label htmlFor="note">Poznámka {note}</label>
              <Controller
                name="note"
                control={control}
                defaultValue={note}
                render={({ field }) => <TextArea {...field} />}
              />
            </Form.Field>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button type="submit" primary>
          Uložit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
